var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "social-buttons text-center" }, [
    _c(
      "ul",
      [
        _vm.lvBranchId == 1 ? [_vm._m(0), _vm._v(" "), _vm._m(1)] : _vm._e(),
        _vm._v(" "),
        _vm.lvBranchId == 2 ? [_vm._m(2), _vm._v(" "), _vm._m(3)] : _vm._e(),
        _vm._v(" "),
        _vm._m(4)
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.facebook.com/livingroomGSY/",
            target: "_blank"
          }
        },
        [
          _c("span", { staticClass: "front" }, [
            _c("i", { staticClass: "fab fa-facebook-f" })
          ]),
          _c("span", { staticClass: "back" }, [
            _c("i", { staticClass: "fab fa-facebook-f" })
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.instagram.com/livingroomgsy/",
            target: "_blank"
          }
        },
        [
          _c("span", { staticClass: "front" }, [
            _c("i", { staticClass: "fab fa-instagram" })
          ]),
          _c("span", { staticClass: "back" }, [
            _c("i", { staticClass: "fab fa-instagram" })
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.facebook.com/livingroomJSY/",
            target: "_blank"
          }
        },
        [
          _c("span", { staticClass: "front" }, [
            _c("i", { staticClass: "fab fa-facebook-f" })
          ]),
          _c("span", { staticClass: "back" }, [
            _c("i", { staticClass: "fab fa-facebook-f" })
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.instagram.com/livingroomjsy/",
            target: "_blank"
          }
        },
        [
          _c("span", { staticClass: "front" }, [
            _c("i", { staticClass: "fab fa-instagram" })
          ]),
          _c("span", { staticClass: "back" }, [
            _c("i", { staticClass: "fab fa-instagram" })
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.linkedin.com/company/livingroom-estate-agents-tm/",
            target: "_blank"
          }
        },
        [
          _c("span", { staticClass: "front" }, [
            _c("i", { staticClass: "fab fa-linkedin-in" })
          ]),
          _c("span", { staticClass: "back" }, [
            _c("i", { staticClass: "fab fa-linkedin-in" })
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }