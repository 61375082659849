<template>
    <div :id="sitekey"></div>
</template>
<script>
    export default {
        props: {
            sitekey: String
        },
        data: function () {
            return {
                widgetId: 0
            }
        },
        methods: {
            loaded() {
                this.widgetId = window.grecaptcha.render(this.sitekey, {
                    sitekey: this.sitekey,
                    callback: (response) => {
                        this.$emit('input', response)
                    },
                })
            },
            reset() {
                window.grecaptcha.reset(this.widgetId)
            },
        },
        mounted() {
            window.recaptchaLoaded = this.loaded
            var script = document.createElement('script')
            script.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit'
            document.head.appendChild(script)
        }
    }
</script>