import { render, staticRenderFns } from "./book-form.vue?vue&type=template&id=8d06df6a&"
import script from "./book-form.vue?vue&type=script&lang=js&"
export * from "./book-form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Repos\\livingroom-mvc\\Livingroom\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8d06df6a')) {
      api.createRecord('8d06df6a', component.options)
    } else {
      api.reload('8d06df6a', component.options)
    }
    module.hot.accept("./book-form.vue?vue&type=template&id=8d06df6a&", function () {
      api.rerender('8d06df6a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "local/js/vue/book-form.vue"
export default component.exports