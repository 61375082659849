<template>
    <div>
        <component v-for="(block, idx) in blocks"
                   :key="idx"
                   :is="getBlockComponent(block)"
                   :class="getBlockClass(block)"
                   v-bind="getBlockProps(block)"></component>
    </div>
</template>
<script>
    import Section from '../cms/section';
    import FullWidthCopy from '../cms/full_width_copy';
    import FullWidthVideo from '../cms/full_width_video';
    import FullWidthImage from '../cms/full_width_image';
    import ImageCopy from '../cms/image_copy';
    import QuoteAuthor from '../cms/quote_and_author';
    import TwoColCopy from '../cms/two_column_copy';
    import PageGrid from '../cms/page_grid';
    import ImageTextGrid from '../cms/image_text_grid';
    import NewsGrid from '../cms/news_grid';
    import PeopleGrid from '../cms/people_grid';
    import StatsBlock from '../cms/stats_block';
    import CtaBlock from '../cms/cta_block';
    import TestimonialSlider from '../cms/testimonial_slider'; 
    import ImageGalleryBlock from '../cms/image_gallery_block';
    import HeroImageCopy from '../cms/hero_image_copy';
    import AccordionItem from '../cms/accordion_item'; 
    import SpacerBlock from '../cms/spacer_block'; 

    export default {
        props: {
            blocks: {
                type: Array,
                required: true
            },
            isNews: {
                type: Boolean,
                required: false
            }
        },
        computed: {},
        methods: {
            getBlockComponent(block) {
                if (block._modelApiKey == "section") {
                    return Section
                }
                else if (block._modelApiKey == "full_width_copy") {
                    return FullWidthCopy
                }
                else if (block._modelApiKey == "full_width_video") {
                    return FullWidthVideo
                }
                else if (block._modelApiKey == "full_width_image") {
                    return FullWidthImage
                }
                else if (block._modelApiKey == "image_copy") {
                    return ImageCopy
                }
                else if (block._modelApiKey == "quote_and_author") {
                    return QuoteAuthor
                }
                else if (block._modelApiKey == "two_column_copy") {
                    return TwoColCopy
                }
                else if (block._modelApiKey == "page_grid") {
                    return PageGrid
                }
                else if (block._modelApiKey == "image_text_grid") {
                    return ImageTextGrid
                }
                else if (block._modelApiKey == "news_grid") {
                    return NewsGrid
                }
                else if (block._modelApiKey == "people_grid") {
                    return PeopleGrid
                }
                else if (block._modelApiKey == "stats_block") {
                    return StatsBlock
                }
                else if (block._modelApiKey == "cta_block") {
                    return CtaBlock
                }
                else if (block._modelApiKey == "testimonial_slider") {
                    return TestimonialSlider
                }
                else if (block._modelApiKey == "image_gallery_block") {
                    return ImageGalleryBlock
                }
                else if (block._modelApiKey == "hero_image_copy") {
                    return HeroImageCopy
                }
                else if (block._modelApiKey == "accordion_item") {
                    return AccordionItem
                }
                else if (block._modelApiKey == "spacer_block") {
                    return SpacerBlock
                } 

                return null
            },
            getBlockProps(block) {
                return {
                    content: block,
                    isNews: this.isNews
                }
            },
            getBlockClass(block) {
                var css = []

                if (block.margin) {
                    css.push(block.margin.classkey)
                }
                if (block.padding) {
                    css.push(block.padding.classkey)
                }
                if (block.background) {
                    css.push(block.background.classkey)
                }

                return css
            }
        },
        mounted: function () {}
    }
</script>