<template>
    <div :class="css">
        <div class="image-cta-fw" v-if="hasText">
            <a :href="content.cta.linkurl" :target="hrefTarget" v-if="content.cta && content.cta.linkurl"></a>
            <div class="image-cta-fw-c">
                <h2>{{content.overlaytext}}</h2>
                <p>{{content.overlaycopy}}</p>
            </div>
            <div class="image-cta-fw-cta">
                <span v-if="content.cta && content.cta.linktext" class="btn big white">{{content.cta.linktext}}</span>
            </div>
            <div class="image-cta-fw-bg" :style="'background-image:url(' + content.image.url + '?mode=fit&w=1300)'" v-scroll-monitor></div>
        </div>
        <div class="image-fw" v-bind:class="{ animate: content.animate }" v-else>
            <div class="ifni">
                <div class="ifni-in" v-scroll-monitor>
                    <img :src="content.image.url + '?fit=max&w=1300'" />
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            },
            isNews: {
                type: Boolean,
                required: false
            }
        },
        data() {
            return {}
        },
        computed: {
            css() {
                if (this.content.container) {
                    return [this.content.container.classkey]
                }
                else {
                    if (this.isNews) {
                        return ['ms']
                    }
                    return ['mn']
                }
            },
            hasText() {
                return(this.content.overlaytext && this.content.overlaytext.length > 0)
            },
            hrefTarget() {
                if (this.content.cta && this.content.cta.linkurl && this.content.cta.newwindow) {
                    return '_blank'
                }
                return ''
            }
        },
        methods: { }
    }
</script>


