var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.css }, [
    _c(
      "div",
      { staticClass: "image-fw", class: { animate: _vm.content.animate } },
      [
        _c(
          "div",
          {
            directives: [
              { name: "scroll-monitor", rawName: "v-scroll-monitor" }
            ],
            staticClass: "ifwi video",
            style:
              "background-image:url(" +
              _vm.content.image.url +
              "?mode=fit&w=1300)"
          },
          [
            !_vm.playing
              ? _c(
                  "a",
                  {
                    staticClass: "play",
                    attrs: { href: "#play" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.play.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Play")]
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _vm.embedtype == 1
          ? _c("iframe", {
              class: { off: !_vm.playing, on: _vm.playing },
              attrs: {
                src: _vm.embedurl,
                width: "560",
                height: "315",
                frameborder: "0",
                allow: "autoplay",
                allowfullscreen: ""
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.embedtype == 2
          ? _c("iframe", {
              class: { off: !_vm.playing, on: _vm.playing },
              attrs: {
                src: _vm.embedurl,
                width: "640",
                height: "360",
                frameborder: "0",
                webkitallowfullscreen: "",
                mozallowfullscreen: "",
                allowfullscreen: ""
              }
            })
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }