import Vue from '../config'
import nformrInsta from '../vue/nformr-insta.vue'

if (document.getElementById('foundation-vm')) {
    new Vue({
        el: '#foundation-vm',
        components: {
            nformrInsta
        },
        data: {
            content: pageJson
        },
        computed: {
            styles() {
                return {
                    backgroundImage: 'url(' + this.content.pageimage.url + '?fit=crop&h=700)'
                }
            }
        },
        methods: {}
    })
}