<template>
    <div class="prompt-wrap" v-bind:class="{active:active}">
        <div class="prompt" ref="promptEl">
            <div class="prompt-copy" v-html="data.message"></div>
            <div class="prompt-control" v-if="data.links && data.links.length">
                <div v-for="item in data.links" v-html="item"></div>
            </div>
            <i class="caret"></i>
        </div>
        <div class="prompt-back"></div>
    </div>
</template>
<script>
    var $ = require('jquery')

    //SPA: test open when transitioning between property search and detail views
    //& check tear down of listeners sufficient

    export default {
        props: [],
        data() {
            return {                
                active: false,
                busy:false,
                data: {
                    message: '',
                    links: []
                }
            }
        },
        computed: {},
        methods: {
            close(){
                var self = this
                this.busy = true
                this.active = false
                
                window.removeEventListener('scroll', this.requestTick)
                window.removeEventListener('resize', this.requestTick)

                setTimeout(function(){
                    self.$refs.promptEl.style.top = '-9999px'
                    self.$refs.promptEl.style.left = '0px'
                    self.busy = false
                }, 300)
            },
            activate(promptObj) {                
                if(!this.busy){
                    this.$refs.promptEl.style.top = '-9999px'
                    this.$refs.promptEl.style.left = '0px'
                    this.data.message = promptObj.message
                    this.data.links = promptObj.links
                               
                    this.$nextTick(() => {
                        this.position(promptObj.rect) 
                    })
                }
            },
            position(r) {                
                var pr = this.$refs.promptEl.getBoundingClientRect(),
                    caret = this.$refs.promptEl.getElementsByClassName('caret')[0],
                    diff = 0,
                    pos = {
                        t: (r.top + r.height) + 12,
                        l: (r.left + Math.round(r.width / 2)) - Math.round(pr.width / 2)
                    }
                
                if(pos.l < 0) {
                    diff = pos.l -10 -12
                    pos.l = 10
                }
                else if(pos.l + pr.width > document.documentElement.clientWidth){
                    var newL = document.documentElement.clientWidth - pr.width - 10
                    diff = pos.l - newL -12
                    pos.l = newL
                }

                if(diff == 0){
                    caret.style.marginLeft = '-12px'
                }
                else {
                    caret.style.marginLeft = diff + 'px'
                }

                this.$refs.promptEl.style.top = pos.t + 'px'
                this.$refs.promptEl.style.left = pos.l + 'px'
                this.active = true

                //watch for resize and scroll
                window.addEventListener('scroll', this.requestTick, false)
                window.addEventListener('resize', this.requestTick, false)
            },
            requestTick() {
                var self = this
                if (self.ticking) {
                    window.cancelAnimationFrame(self.ticking)
                }

                self.ticking = window.requestAnimationFrame(function () {
                    //console.log('window activity')
                    self.close()
                })
            }
        },
        created() {
            this.ticking = false
        }, 
        mounted() {
            var self = this
            this.offClicker = $(this.$refs.promptEl).parent().find('.prompt-back')
            this.offClicker.on('mousedown touchstart', function(e){
                self.close()
            })
        }
    }
</script>