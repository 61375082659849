import Vue from '../config'
import { EventBus } from '../app/event-bus'

import { getScrollPosition, setScrollPosition } from './helpers'

if (document.getElementById('developments-vm')) {

    new Vue({
        el: '#developments-vm',
        data: {
            ready: false,
            busy: false,            
            islandDevelopments: iPageModel,
            skeletonColumns: [1, 2, 3, 4, 5, 6, 7, 8, 9],         
            states: {
                LOADING: 1,
                LOADED: 2,
                EMPTY: 4
            },
            state: 1,
            branchId: 0,
            findId: 0,
        },
        computed: {
            island() {
                if (this.findId == 0) {
                    return {
                        lvBranchId: 0,
                        lvBranchName: "",
                        items: []
                    }
                }
                else {
                    return this.islandDevelopments.find(d => d.lvBranchId == this.findId)
                }
            },           
            skeletons() {
                if (this.state == 2 || this.state == 4) {
                    return []
                }
                else {
                    return this.skeletonColumns
                }
            }
        },
        methods: {            
            setBranch(bid) {
                this.state = this.states.LOADING
                this.findId = 0
                this.branchId = bid
                this.setLvBranch(bid)
                EventBus.$emit('uibranchchange', bid)
                var self = this
                setTimeout(function () {
                    self.findId = self.branchId
                    self.state = self.states.LOADED
                }, 250)
            }
        },
        mounted() {
            this.setBranch((this.lvBranchId == 0 || this.lvBranchId == 3) ? 1 : this.lvBranchId)
        }
    })
}