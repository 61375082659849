import Vue from '../config'
import { getScrollPosition, setScrollPosition } from './helpers'

if(document.getElementById('account-settings-vm')) {
    new Vue({
        el: '#account-settings-vm',
        data: {
            loading: false,
            states: {
                INFO:0,
                PASSWORD: 1,
                CLOSE: 2
            },
            state:0,
            errors: [],
            success: [],
            request: iPageModel,
            deleteConfirm: '',
            auth: {
                password: '',
                confirm: ''
            }
        }, 
        methods: {
            submit(){
                let self = this
                self.clearMessages()

                this.$validator.validateAll().then(function (success) {
                    if (success) {
                        self.loading = true
                        self.$http.post('/account/settings', self.request).then(({ data }) => {
                            if (data.success) {
                                self.success = ['Your personal information has been updated.']
                                setScrollPosition(Vue, { x:0, y:85 })
                            }
                            else {
                                self.errors = data.errors
                            }
                            self.loading = false
                        }, (response) => {
                            self.errors = ['Unable to complete your request at this time, please try again shortly.']
                            self.loading = false
                        })
                    }
                })
            },
            passwordSubmit(){
                let self = this
                self.clearMessages()                        
                self.loading = true
                self.$http.post('/account/change-password', self.auth).then(({ data }) => {
                    if (data.success) {
                        self.success = ['Your password has been updated.']
                        setScrollPosition(Vue, { x:0, y:85 })
                    }
                    else {
                        self.errors = data.errors
                    }
                    self.loading = false
                }, (response) => {
                    self.errors = ['Unable to complete your request at this time, please try again shortly.']
                    self.loading = false
                })
            },
            deleteAccount() {
                let self = this
                if (this.deleteConfirm.toLowerCase() == 'delete') {
                    if (window.confirm('Please confirm a final time that you wish to delete this account.\n\nThis action cannot be undone.')) {
                        self.$http.post('/account/delete-account', { confirm: self.deleteConfirm }).then(({ data }) => {
                            window.location.href = '/'
                        }, (response) => {
                            self.errors = ['Unable to complete your request at this time, please try again shortly.']
                            self.loading = false
                        })
                    }
                }
                else {
                    self.errors = ['Please type the word "DELETE" in the field provided to completely close this account.']
                }                
            },
            changeState(newState){
                if(newState != this.state){
                    this.clearMessages()
                    this.state = newState
                    this.deleteConfirm = ''
                }
            },
            clearMessages(){
                this.errors = []
                this.success = []
            }
        }
    })
}