<template>
    <div class="swc-panels" v-bind:class="{success:step == 5}">
        <form class="lvrf windowed" v-on:submit.prevent="submit">
            <transition name="swcp" v-on:leave="afterAnim">
                <div class="swcp" v-if="step == 1">
                    <p>Please wait a moment while we check your account.</p>
                    <div class="await"><i></i></div>
                </div>
            </transition>
            <transition name="swcp" v-on:leave="afterAnim">
                <div class="swcp" v-if="step == 2">
                    <p>Please log in to your Livingroom account to continue. If you do not have an account, <a v-bind:href="'/auth/register?ReturnUrl=/property-redirect/' + request.propertyId">register here</a>.</p>
                    <div class="notify danger" v-if="errors && errors.length > 0">
                        <ul>
                            <li v-for="error in errors">{{ error }}</li>
                        </ul>
                        <i class="fas fa-times-circle" v-on:click="clearErrors"></i>
                    </div>
                    <div class="field">
                        <label>Email Address <span class="inlerr" v-show="verrors.has('bkscp.emailAddress')">Invalid Email</span></label>
                        <div class="control">
                            <input type="text" name="emailAddress" maxlength="250" v-model="auth.email" data-vv-scope="tmmscp"
                                   v-bind:class="{'error': verrors.has('bkscp.emailAddress')}" v-validate="'required|email'" />
                        </div>
                    </div>
                    <div class="field">
                        <label>Password <span class="inlerr" v-show="verrors.has('bkscp.password')">Required</span></label>
                        <div class="control">
                            <input type="password" name="password" maxlength="50" v-model="auth.password" data-vv-scope="tmmscp"
                                   v-bind:class="{'error': verrors.has('bkscp.password')}" v-validate="'required'" />
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="swcp" v-on:leave="afterAnim">
                <div class="swcp" v-if="step == 3">
                    <div class="field">
                        <label>Your Name <span class="inlerr" v-show="verrors.has('bkscp.personName')">Required</span></label>
                        <div class="control">
                            <input type="text" name="personName" maxlength="150" v-model="request.name" data-vv-scope="bkscp"
                                   v-bind:class="{'error': verrors.has('bkscp.personName')}" v-validate="'required'" />
                        </div>
                    </div>
                    <div class="field">
                        <label>Email Address <span class="inlerr" v-show="verrors.has('bkscp.emailAddress')">Invalid Email</span></label>
                        <div class="control">
                            <input type="text" name="emailAddress" maxlength="250" v-model="request.email" data-vv-scope="bkscp"
                                   v-bind:class="{'error': verrors.has('bkscp.emailAddress')}" v-validate="'required|email'" />
                        </div>
                    </div>
                    <div class="field">
                        <label>Telephone</label>
                        <div class="control">
                            <input type="text" name="telephone" v-model="request.telephone" maxlength="50" />
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="swcp" v-on:leave="afterAnim">
                <div class="swcp" v-if="step == 4">
                    <div class="notify danger" v-if="errors && errors.length > 0">
                        <ul>
                            <li v-for="error in errors">{{ error }}</li>
                        </ul>
                        <i class="fas fa-times-circle" v-on:click="clearErrors"></i>
                    </div>
                    <div class="field">
                        <label>Date &amp; Time <span class="inlerr" v-show="verrors.has('bkscp.message')">Required</span></label>
                        <span class="help">Please suggest at least one convenient date &amp; time.</span>
                        <div class="control">
                            <textarea name="message" rows="5" v-model="request.message" data-vv-scope="bkscp"
                                   v-bind:class="{'error': verrors.has('bkscp.message')}" v-validate="'required'" />
                        </div>
                    </div>                    
                </div>
            </transition>
            <transition name="swcp" v-on:leave="afterAnim">
                <div class="swcp" v-if="step == 5">
                    <div class="step-success">
                        <h4>Thank you for your interest.</h4>
                        <p>A member of the <a href="/about/team" target="_blank">Livingroom Team</a> will contact you shortly to arrange a viewing.</p>
                    </div>
                </div>
            </transition>
        </form>
    </div>
</template>
<script>
    //future = check account and skip first step if already logged in
    export default {
        props: ['active', 'pid'],
        data() {
            return {
                loading:false,
                errors:[],
                step: 1,
                formalName: '',
                request: {
                    name: '',
                    email: '',
                    telephone: '',
                    message: '',
                    propertyId: 0
                },
                auth: {
                    email: '',
                    password: ''
                }
            }
        },
        methods: {
            getaccount(){
                let self = this
                this.loading = true

                self.$http.post('/auth/currentuser/', '').then(({ data }) => {
                    if (data.IsAuthenticated) {
                        self.formalName = data.FormalName
                        self.request.name = data.FirstName + ' ' + data.LastName
                        self.request.email = data.Email
                        self.setstep(3)
                    }
                    else {
                        self.setstep(2)
                    }
                    self.loading = false
                }, (response) => {
                    self.loading = false
                })
            },
            submit(){
                let self = this
                
                if(!self.loading){
                    self.clearErrors()
                                        
                    if(self.step < 2){ self.getaccount() }
                    else {                        
                        this.$validator.validateAll('bkscp').then(function (success) {
                            if (success) {                        
                                self.$emit('sending', true)
                                self.loading = true                                

                                if(self.step == 2){
                                    self.$http.post('/auth/login', self.auth).then(({ data }) => {
                                        if (data.IsAuthenticated) {
                                            self.formalName = data.FormalName
                                            self.request.name = data.FirstName + ' ' + data.LastName
                                            self.request.email = data.Email
                                            self.setstep(3)
                                        }
                                        else {
                                            self.errors = data.Errors
                                        }
                                        self.$emit('sending', false)
                                        self.loading = false
                                    }, (response) => {
                                        self.errors = ['Unable to log you in at this time, please try again shortly.']
                                        self.$emit('sending', false)
                                        self.loading = false
                                    })
                                }
                                else if(self.step == 3){
                                    //set 3 only needs validation
                                    self.setstep(4)
                                    self.$emit('sending', false)
                                    self.loading = false
                                }
                                else {
                                    self.$http.post('/property/book/', self.request).then(({ data }) => {
                                        if (data.success) {   
                                            self.setstep(5)
                                            setTimeout(self.complete, 4000)
                                        }
                                        else {
                                            self.errors = data.errors
                                        }
                                        self.$emit('sending', false)
                                        self.loading = false
                                    }, (response) => {
                                        self.errors = ['A server error occurred sending your request.']
                                        self.$emit('sending', false)
                                        self.loading = false
                                    })
                                }

                            }
                        })

                    }

                }
            },
            setstep(num){
                this.step = num;
            },
            afterAnim(el, done){
                this.$emit('changed', this.step)
                done()
            },
            complete(){
                this.$emit('complete')
            },
            clearErrors(){
                this.errors = []
            }
        },
        mounted(){
            this.request.propertyId = this.pid
            this.$emit('changed', this.step)
            this.getaccount()
        }
    }
</script>