var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "statblock", class: _vm.css },
    _vm._l(_vm.content.statistics, function(item) {
      return _c("div", { staticClass: "statblock_item" }, [
        _c("strong", [_vm._v(_vm._s(item.title))]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(item.statval))])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }