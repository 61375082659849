var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "gmap" }, on: { "after-enter": _vm.mapOpened } },
    [
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active,
              expression: "active"
            }
          ],
          staticClass: "property property-map z2"
        },
        [_c("div", { ref: "propMap", staticClass: "gmap" })]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }