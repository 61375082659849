<template>
    <div class="carousel-wrap" v-bind:class="computedClass">
        <section ref="imgCarousel" class="carousel">
            <div class="carousel-cell" ref="pSlide"><div class="carousel-cell-inner"><img src="/public/img/spacer.gif" /></div></div>
        </section>
    </div>
</template>
<script>
    var $ = require('jquery')
    var Flickity = require('flickity')

    var cState = {
        flkty: null
    }
    //SPA: onunload destroy cstate.flkty
    export default {
        props: ['src', 'soldvisible', 'leased', 'open', 'jersey'],
        data() {
            return {
                processing: false,
                animating: false,
                final: false,
                queue: [],
                embeds: [],
                currentIndex: 0,
                totalIndex: 0,
                activeVideoIndex: -1
            }
        },
        computed: {
            complete() {
                return (this.totalIndex > 0 && this.totalIndex == this.currentIndex)
            },
            computedClass() {
                return {
                    complete: this.complete,
                    animating: this.animating,
                    final: this.final
                }
            }
        },
        methods: {
            resize() {
                cState.flkty.resize()
            },
            add(pImg, index) {
                this.totalIndex++

                if (this.currentIndex == index) {
                    this.append(pImg, index)
                    if (index == 0) { cState.flkty.remove(this.$refs.pSlide) }
                    this.currentIndex++
                }
                else {
                    //queue
                    pImg.index = index
                    this.queue.push(pImg)
                    this.runQueue()
                }
            },
            show() {
                var self = this
                this.animating = true

                setTimeout(function () {
                    self.final = true
                    self.animating = false
                }, 3200)
            },
            runQueue() {
                if (!this.processing && !this.complete) {
                    this.processing = true

                    var idx = this.queue.findIndex(x => x.index == this.currentIndex)
                    if (idx > -1) {
                        this.append(this.queue[idx], this.queue[idx].index)
                        this.currentIndex++
                        this.queue.splice(idx, 1)
                    }

                    if (!this.complete) { setTimeout(this.runQueue, 100) }
                    this.processing = false
                }
            },
            append(img, index) {

                var overlay = ''
                if (index == 0 && this.soldvisible && !this.jersey) {
                    overlay = `<span class="ovl">Another ${this.open ? 'Open' : 'Local'} Market Property <strong>Sold by Livingroom</strong></span>`
                } else if (index == 0 && this.soldvisible && this.jersey) {
                    overlay = `<span class="ovl">Another Property <strong>Sold by Livingroom</strong></span>`
                } else if (index == 0 && this.leased) {
                    overlay = `<span class="ovl">Leased <strong>by Livingroom</strong></span>`
                }
                if (img.embed) {
                    this.embeds.push({
                        id: img.id,
                        html: img.embed,
                        container: null,
                        active: false
                    })
                    cState.flkty.append($('<div class="carousel-cell"><div class="carousel-cell-inner video" data-embed="' + img.id + '"><a href="' + img.embedUrl + '" class="moblink" target="_blank"></a><span class="play">Play</span><img src="' + img.src + '" />' + overlay + '</div></div>'))
                }
                else {
                    cState.flkty.append($(`<div class="carousel-cell"><div class="carousel-cell-inner"><img src="${img.src}" />${overlay}</div></div>`))
                }
            },
            hiding() {
                this.closeVideo()
            },
            closeVideo() {
                var self = this

                if (self.activeVideoIndex > -1) {
                    var vidEl = self.embeds[self.activeVideoIndex].container.querySelector('div.ccivw')
                    vidEl.parentNode.removeChild(vidEl)
                    self.embeds[self.activeVideoIndex].active = false
                    self.embeds[self.activeVideoIndex].container = null
                    self.activeVideoIndex = -1
                }
            },
            wire() {
                if (cState.flkty) {
                    var self = this

                    cState.flkty.on('staticClick', function (event, pointer, cellElement, cellIndex) {

                        if (event.target.tagName.toLowerCase() != 'a') {

                            var clickedVideoCell = cellElement.querySelector('div.video')
                            var clickedImg = cellElement.querySelector('img')

                            if (clickedVideoCell) {
                                var dataId = clickedVideoCell.dataset.embed,
                                    eidx = self.embeds.findIndex(x => { return x.id == dataId })

                                if (eidx > -1 && self.embeds[eidx] && !self.embeds[eidx].active) {
                                    self.activeVideoIndex = eidx
                                    self.embeds[eidx].active = true
                                    self.embeds[eidx].container = clickedVideoCell
                                    clickedVideoCell.insertAdjacentHTML('beforeend', '<div class="ccivw"><div>' + self.embeds[eidx].html + '</div></div>')
                                }
                            }
                            else {
                                if (clickedImg && clickedImg.src) {
                                    var rect = clickedImg.getBoundingClientRect(),
                                        sT = window.pageYOffset || document.documentElement.scrollTop

                                    self.closeVideo()

                                    self.$emit('click', clickedImg.src, {
                                        x: rect.left,
                                        y: rect.top + sT,
                                        w: rect.width
                                    })
                                }
                            }

                        }

                    })

                    cState.flkty.on('change', function (index) {
                        self.closeVideo()
                    })
                }
            }
        },
        mounted() {
            cState.flkty = new Flickity(this.$refs.imgCarousel, {
                percentPosition: false,
                cellAlign: 'left',
                cellSelector: '.carousel-cell',
                contain: true,
                pageDots: false,
                arrowShape: 'M2.8,50l0.7,0.6l0,0L18,65.1l2.2-2.2L8.8,51.6h88.4v-3.2H8.8l11.4-11.3L18,34.9L3.5,49.4l0,0L2.8,50z'
            })

            //locks vertical scroll during horizontal carousel scroll
            window.addEventListener('touchmove', function () { })

            this.$nextTick(() => {
                this.wire()
            })
        }
    }
</script>