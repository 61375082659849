import Vue from '../config'
import nformrInsta from '../vue/nformr-insta.vue'

if (document.getElementById('cms-page-vm')) {
    new Vue({
        el: '#cms-page-vm',
        components: {
            nformrInsta
        },
        data: {
            content: pageJson
        },
        methods: {},
        mounted: function () {
        }
    })
}