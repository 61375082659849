var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "carousel-wrap", class: _vm.computedClass }, [
    _c("section", { ref: "imgCarousel", staticClass: "carousel" }, [
      _c("div", { ref: "pSlide", staticClass: "carousel-cell" }, [_vm._m(0)])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "carousel-cell-inner" }, [
      _c("img", { attrs: { src: "/public/img/spacer.gif" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }