var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "vidRef", staticClass: "image-fw", class: { animate: _vm.animate } },
    [
      _c(
        "div",
        {
          directives: [{ name: "scroll-monitor", rawName: "v-scroll-monitor" }],
          staticClass: "ifwi",
          class: { video: _vm.video },
          style: "background-image:url(" + _vm.image + ")"
        },
        [
          _vm.embed
            ? _c(
                "a",
                {
                  staticClass: "play hidden-xs",
                  attrs: { href: "#play" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.play.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Play")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.video
            ? _c(
                "a",
                {
                  staticClass: "visible-xs play",
                  attrs: { href: _vm.video, target: "_blank" }
                },
                [_vm._v("Play")]
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }