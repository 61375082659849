var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid insta-grid", attrs: { id: "instagrid" } },
    _vm._l(_vm.feed, function(insta, i) {
      return _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "insta-grid-item" }, [
          _c("a", { attrs: { href: insta.url, target: "_blank" } }, [
            _c("i", { staticClass: "far fa-heart" }),
            _c("i", { staticClass: "far fa-comment" })
          ]),
          _vm._v(" "),
          _c("span", { style: "background-image:url(" + insta.imageUrl + ");" })
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }