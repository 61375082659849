<template>
    <div class="ctablock ms">
        <h2>{{content.headline}}</h2>
        <div class="ctablock_copy text-light" v-html="content.copy"></div>
        <div class="ctablock_btn">
            <a :href="content.buttonurl" class="btn big gold hoverwhite">{{ content.buttontext }}</a>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            }
        },       
        methods: {}
    }
</script>