<template>
    <div :class="css">
        <div class="pods" :class="{ centered: content.centeritems }" v-if="contenttype == 1">
            <div class="pods-item" v-for="item in content.grid.griditems">
                <a :href="item.link" target="_blank">
                    <div><i></i><img v-if="item.image" :src="item.image.url + '?fit=crop&h=320&w=320'" /></div>
                    <span class="title">{{ item.title | slashbreak }}</span>
                    <span class="meta">{{ item.summary | truncate(150, '...') }}</span>
                </a>
            </div>
            <div class="pods-item dummy"></div>
            <div class="pods-item dummy"></div>
            <div class="pods-item dummy"></div>
        </div>
        <div class="pubs" :class="{ centered: content.centeritems }" v-if="issuuDocs.length && contenttype == 2">
            <div class="pubs-item" v-for="item in issuuDocs">
                <a v-bind:href="'https://issuu.com/livingroomestateagents/docs/' + item.document.name" target="_blank">
                    <img v-bind:src="'http://image.issuu.com/' + item.document.documentId + '/jpg/page_1_thumb_large.jpg'" />
                </a>
                <span class="title">{{item.document.shortTitle | slashbreak }}</span>
                <span class="meta">{{item.document.shortDesc}}</span>
            </div>
            <div class="pubs-item dummy"></div>
            <div class="pubs-item dummy"></div>
            <div class="pubs-item dummy"></div>
        </div>
        <div class="magvid" :class="{ centered: content.centeritems }" v-if="contenttype == 3 || contenttype == 5">
            <div class="magvid-item" v-for="item in content.grid.griditems" v-scroll-monitor>
                <a :href="item.link" target="_blank">
                    <div class="image" :class="{video:contenttype == 5}">
                        <span v-if="item.image" :style="'background-image:url(' + item.image.url + '?mode=fit&w=500)'"></span>
                        <i v-if="contenttype == 5" class="far fa-play-circle"></i>
                    </div>
                    <h4>{{ item.title | slashbreak }}</h4>
                    <p>{{ item.summary }}</p>
                </a>
            </div>
            <div class="magvid-item dummy"></div>
            <div class="magvid-item dummy"></div>
            <div class="magvid-item dummy"></div>
        </div>
        <div class="icgrid" :class="{ centered: content.centeritems }" v-if="contenttype == 4">
            <div class="icgrid-item" v-for="item in content.grid.griditems" v-scroll-monitor>
                <a :href="item.link" target="_blank">
                    <div class="image"><span v-if="item.image" :style="'background-image:url(' + item.image.url + '?mode=fit&w=500)'"></span></div>
                    <h3>{{ item.title | slashbreak }}</h3>
                    <p>{{ item.summary }}</p>
                </a>
            </div>
            <div class="icgrid-item dummy"></div>
            <div class="icgrid-item dummy"></div>
            <div class="icgrid-item dummy"></div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            }            
        },  

        data: function () {
            return {
                loading: false,
                pub: {
                    totalCount: 0,
                    startIndex: 0,
                    pageSize: 24,
                    more: false,
                    docs: []
                },
                contenttype: 0
            }
        },
        computed: {
            css() {
                if (this.content.container) {
                    return [this.content.container.classkey]
                }
                return ['ms']
            },
            issuuDocs: function () {
                if (this.pub.docs && this.pub.docs.length && this.pub.docs.length > 15) {
                    return this.pub.docs.slice(0, 10)
                }

                return []
            }
        },
        methods: {
            loadIssuu() {
                let self = this

                self.$http.post('/publication-list/', null).then(({ data }) => {
                    if (data && data.success) {
                        self.pub.totalCount = data.totalCount
                        self.pub.startIndex = data.startIndex
                        self.pub.more = data.more
                        self.pub.docs = data._content
                    }
                }, (response) => { })
            }
        },
        filters: {
            truncate: function (text, length, suffix) {
                if (text.length > length) {
                    return text.substring(0, length) + suffix;
                } else {
                    return text;
                }
            },
            slashbreak: function (text) {
                if (text.length > 0 && text.indexOf('\\') > -1) {
                    return text.replace('\\', '\n')
                } else {
                    return text
                }
            }
        },
        mounted: function () {
            if (this.content.grid.gridname == 'Podcasts') { this.contenttype = 1 }
            else if (this.content.grid.gridname.startsWith('Issuu')) {
                this.contenttype = 2
                this.loadIssuu()
            }
            else if (this.content.grid.gridname == 'Publications') { this.contenttype = 3 }
            else if (this.content.grid.gridname == 'Videos') { this.contenttype = 5 }
            else { this.contenttype = 4 }
        }
    }
</script>