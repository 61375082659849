<template>
    <div :class="css" :style="style" v-html="content.text"></div>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            },
            isNews: {
                type: Boolean,
                required: false
            }
        },
        computed: {
            css() {
                var a = []
                if (this.content.container) {
                    a.push(this.content.container.classkey)
                }
                else {
                    a.push('mn')
                }
                return a
            },
            style() {
                if (this.content.maxwidth) {
                    return { maxWidth: this.content.maxwidth + 'px' }
                }
                return null
            }
        },
        methods: {}
    }
</script>