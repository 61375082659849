var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "igblock", class: _vm.css },
    [
      _vm._l(_vm.content.images, function(item) {
        return _c("div", { staticClass: "igblock_item" }, [
          _c(
            "div",
            {
              on: {
                click: function($event) {
                  return _vm.zoom(item)
                }
              }
            },
            [
              _c("v-lazy-image", {
                attrs: { src: item.url + "?fit=crop&w=500&h=500" }
              })
            ],
            1
          )
        ])
      }),
      _vm._v(" "),
      _vm.zoomurl && _vm.zoomurl.length
        ? _c("div", { staticClass: "igblock_zoom", on: { click: _vm.close } }, [
            _c("div", [_c("v-lazy-image", { attrs: { src: _vm.zoomurl } })], 1),
            _vm._v(" "),
            _c("span", { staticClass: "close" }, [_vm._v("×")])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }