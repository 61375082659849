<template>
    <div class="pd_g" ref="galleryWrap">
        <div class="pd_g_gallery" v-if="state == states.GALLERY">
            <div class="pd_g_gallery-title" v-scroll-monitor><div class="pgtt">Image Gallery </div></div>
            <div class="pdggi" v-for="(img, index) in previewImages" v-on:click="imageClick((index + 1), $event)">
                <div><v-lazy-image :src="img.path + '?mode=crop&width=500&height=500'" /></div>
            </div>
        </div>
        <div class="pd_g_album" v-if="state == states.THUMBS">
            <div v-for="(img, index) in images" v-on:click="imageClick(index, $event)">
                <div class="p" v-if="img.isPortrait"><v-lazy-image :src="img.path + '?mode=max&height=200'" /></div>
                <div v-else><v-lazy-image :src="img.path + '?mode=crop&width=300&height=200'" /></div>
            </div>
        </div>

        <div class="small-gap text-center">
            <a class="btn gold" href="#gallery" v-on:click.prevent="changeView">{{buttonText}}</a>
        </div>
    </div>
</template>
<script>
    import PhotoSwipe from 'photoswipe'
    import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

    export default {
        props: ['idata', 'floorplan'],
        data() {
            return {                
                states: {
                    GALLERY: 0,
                    THUMBS: 1
                },
                state: 0,
                count: {
                    initial:    0,
                    current:    0,
                    total:      0
                },             
                floorIndex: -1,
                images: []                
            }
        },
        computed: {  
            previewImages() {
                if (this.count.total > 1) {
                    return this.images.slice(1, 10)
                }
                return []
            },
            buttonText() {
                if (this.state == this.states.GALLERY) return "View All Images"
                else return "View Preview Images"
            }
        },
        methods: {
            changeView() {        
                let self = this

                this.$scrollTo(this.$refs.galleryWrap, 500, {
                    offset: -100,
                    onDone: function (el) {
                        self.state = (self.state == self.states.THUMBS) ? self.states.GALLERY : self.states.THUMBS
                    }
                })
            },
            imageClick(idx, e) {
                let rect = e.target.getBoundingClientRect(),
                    imageRect = {
                        x: rect.left,
                        y: rect.top + (window.pageYOffset || document.documentElement.scrollTop),
                        w: rect.width
                    }                    

                this.openGalleryInternal(idx, imageRect)                
            },
            openGallery() {
                let self = this
                this.$scrollTo(this.$refs.galleryWrap, 500, {
                    offset: -100,
                    onDone: function (el) {
                        self.openGalleryInternal(0, null)
                    }
                })
            },
            openFloorplan() {
                this.openGalleryInternal(this.floorIndex, null)
            },
            openGalleryInternal(idx, rect) {
                let options = {
                    index: idx,

                    zoomEl: false,
                    fullscreenEl: false,
                    shareEl: false,
                    history: false,

                    showHideOpacity: true,
                    showAnimationDuration: 1000
                }

                if (rect) {
                    options.getThumbBoundsFn = function (index) {
                        return rect
                    }
                }

                var gallery = new PhotoSwipe(this.pswpElement, PhotoSwipeUI_Default, this.imgState.slides, options)
                gallery.init()
            }
        },
        created() {
            this.imgState = {
                slides: [],
                baseHeight: 1200
            }
        },
        mounted() {
            if(this.idata && this.idata.length)
            {
                this.count.total = this.idata.length
                this.count.initial = (this.idata.length > 2) ? 3 : this.idata.length
                this.images = this.idata
            }

            let tracked = 0
            this.pswpElement = document.getElementById('pswp-modal')

            this.idata.forEach((x, index) => {
                this.imgState.slides.push({
                    src: x.src,
                    msrc: x.src,
                    w: Math.floor(x.width * (this.imgState.baseHeight / x.height)),
                    h: this.imgState.baseHeight,
                    sortKey: index
                })
                tracked = index
            })

            if (this.floorplan) {
                let ratio = (this.floorplan.width > this.floorplan.height) ? (1800 / this.floorplan.width) : (1800 / this.floorplan.height)
                tracked += 1
                this.floorIndex = tracked
                this.imgState.slides.push({
                    src: this.floorplan.path + '?mode=max&width=1800&height=1800',
                    msrc: this.floorplan.path + '?mode=max&width=1800&height=1800',
                    w: Math.round(this.floorplan.width * ratio),
                    h: Math.round(this.floorplan.height * ratio),
                    sortKey: tracked
                })
            }
        }
    }
</script>