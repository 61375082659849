var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "swc-panels" }, [
    _c(
      "form",
      {
        staticClass: "lvrf windowed",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "transition",
          { attrs: { name: "swcp" }, on: { leave: _vm.afterAnim } },
          [
            _vm.step == 1
              ? _c("div", { staticClass: "swcp" }, [
                  _vm.loading
                    ? _c("p", [_vm._v("Loading sharing center...")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loading
                    ? _c("div", { staticClass: "await" }, [_c("i")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "addthis_inline_share_toolbox" })
                ])
              : _vm._e()
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }