var scrollMonitor = require("scrollmonitor")

/*
NB. For maximum browser compatibility install a classList polyfill

Usage:
import VueScrollMonitor from './scroll-directive'
Vue.use(VueScrollMonitor)

<div v-scroll-monitor>Monitor Me</div>
<div v-scroll-monitor="{ offset: { top: 100, bottom:50 } }">Monitor Me with Offset</div>
*/

function generateOptions(bindingValue, bindingModifiers){
    let options = Object.assign({}, {
        offset: 0,
        classes: {
            enter: false,
            in: false,
            above: false,
            below: false,
            out: false
        },
        e:false
    }, bindingValue)

    if (bindingModifiers) {
        //potential to use binding modifiers to modify settings, for example, 
        //v-scroll-monitor.emit to trigger a component-level event for watched items       
        if(bindingModifiers.emit){
            options.e = true
        }
    }

    return options
}


var VueScrollMonitor = {
    inserted: function (el, binding, vnode) {
        const options = generateOptions(binding.value, binding.modifiers)

        var watch = (!options.offset || options.offset == 0) ? scrollMonitor.create(el) : scrollMonitor.create(el, options.offset);  
        watch['vopt'] = options
        el['scrollWatch'] = watch

        function setClassState(stateName, stateProp, classObj, el){
            if(stateProp && !classObj[stateName]){
                el.classList.add(stateName)
                classObj[stateName] = true
            }
            else if(!stateProp && classObj[stateName]){
                el.classList.remove(stateName)
                classObj[stateName] = false
            }
        }

        function setElementState(w) {
            if(w.vopt.classes) {
                setClassState('enter', w.isInViewport, w.vopt.classes, w.watchItem)
                setClassState('in', w.isFullyInViewport, w.vopt.classes, w.watchItem)
                setClassState('out', !w.isInViewport, w.vopt.classes, w.watchItem)
                setClassState('above', w.isAboveViewport, w.vopt.classes, w.watchItem)
                setClassState('below', w.isBelowViewport, w.vopt.classes, w.watchItem)                
            }
            if(w.vopt.e){
                vnode.context.$emit('scrolled', w)
            }
        }

        watch.stateChange(function() {
            setElementState(this)
        })

        //set the initial state
        setElementState(watch)
    },
    unbind: function(el){
        if (el['scrollWatch']) {
            el['scrollWatch'].destroy()          
            el['scrollWatch'] = undefined
        }
    }
}

const install = function(Vue, options) {
    Vue.directive("scroll-monitor", VueScrollMonitor);
}

VueScrollMonitor.install = install
export default VueScrollMonitor
