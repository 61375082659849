var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.css }, [
    _vm.hasText
      ? _c("div", { staticClass: "image-cta-fw" }, [
          _vm.content.cta && _vm.content.cta.linkurl
            ? _c("a", {
                attrs: { href: _vm.content.cta.linkurl, target: _vm.hrefTarget }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "image-cta-fw-c" }, [
            _c("h2", [_vm._v(_vm._s(_vm.content.overlaytext))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.content.overlaycopy))])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "image-cta-fw-cta" }, [
            _vm.content.cta && _vm.content.cta.linktext
              ? _c("span", { staticClass: "btn big white" }, [
                  _vm._v(_vm._s(_vm.content.cta.linktext))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", {
            directives: [
              { name: "scroll-monitor", rawName: "v-scroll-monitor" }
            ],
            staticClass: "image-cta-fw-bg",
            style:
              "background-image:url(" +
              _vm.content.image.url +
              "?mode=fit&w=1300)"
          })
        ])
      : _c(
          "div",
          { staticClass: "image-fw", class: { animate: _vm.content.animate } },
          [
            _c("div", { staticClass: "ifni" }, [
              _c(
                "div",
                {
                  directives: [
                    { name: "scroll-monitor", rawName: "v-scroll-monitor" }
                  ],
                  staticClass: "ifni-in"
                },
                [
                  _c("img", {
                    attrs: { src: _vm.content.image.url + "?fit=max&w=1300" }
                  })
                ]
              )
            ])
          ]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }