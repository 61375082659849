var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vnis" }, [
    _c(
      "button",
      {
        class: _vm.buttonClass,
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.decreaseNumber.apply(null, arguments)
          }
        }
      },
      [_vm._v("-")]
    ),
    _vm._v(" "),
    _c("input", {
      class: _vm.inputClass,
      attrs: { type: "number", min: _vm.min, max: _vm.max, debounce: "500" },
      domProps: { value: _vm.numericValue },
      on: {
        keypress: _vm.validateInput,
        input: function($event) {
          _vm.numericValue = _vm.cleanInput($event.target.value)
        }
      }
    }),
    _vm._v(" "),
    _c(
      "button",
      {
        class: _vm.buttonClass,
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.increaseNumber.apply(null, arguments)
          }
        }
      },
      [_vm._v("+")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }