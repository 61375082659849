import Vue from '../config'
import nformrInsta from '../vue/nformr-insta.vue'

if (document.getElementById('private-collections-vm')) {
    new Vue({
        el: '#private-collections-vm',
        components: {
            nformrInsta
        },
        data: {
            properties: iPageModel
        },
        methods: {
        },
        mounted: function () {
        },
    })
}