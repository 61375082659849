import Vue from '../config'
import { EventBus } from '../app/event-bus'
import nformrInsta from '../vue/nformr-insta.vue'
var Flickity = require('flickity')
require('flickity-imagesloaded')

if (document.getElementById('home-content-vm')) {
    var cachedSearchData = null

    var fhState = {
        flktyGG: null,
        flktyJE: null,
        requestId: null,
        timeId: null
    }

    new Vue({
        el: '#home-content-vm',
        components: {
            nformrInsta
        },
        data: {
            ready: false,
            busy: false,
            sold: iPageModel.sold,
            featuredGG: iPageModel.featuredGG,
            featuredJE: iPageModel.featuredJE,
            introVideo: iPageModel.introVideo,   
            introImage: iPageModel.introImage, 
            ticking: 0,
            paused: false,
            listStates: {
                LOADING: 1,
                LOADED: 2,
                EMPTY: 4
            },
            listState: 1,
            properties: [],
            viewCache: {
                used: [],
                global: 0,
                changing: false
            },
            playing: false,
            embedtype: 0,
            embedurl: '',
            loadedurl: '',
            lvBranch: 0
        },
        computed: {
            introStyles() {
                var s = {
                    backgroundImage: 'url(' + this.introImage + '?fit=crop&w=800)'
                }
                return s
            }
        },
        methods: {         
            setBranch(bid) {
                if (bid != this.lvBranch) {
                    this.lvBranch = bid
                    this.setLvBranch(bid)
                    EventBus.$emit('uibranchchange', bid)
                    this.loadLatest()

                    this.$nextTick(() => {
                        if (bid == 2) { fhState.flktyJE.resize() }
                        else { fhState.flktyGG.resize() }
                    })
                }
            },
            loadLatest() {
                if (!this.busy) {
                    this.busy = true
                    this.ready = true

                    let self = this                    
                    this.listState = this.listStates.LOADING

                    self.$http.post('/new/search', self.getSearchModel()).then(({ data }) => {
                        if (data && data.length) {
                            cachedSearchData = data
                            self.showResults()
                        }
                        else {
                            self.busy = false
                            self.ready = true
                            self.viewCache.used = []
                            self.listState = self.listStates.EMPTY
                        }
                    }, (response) => {
                        self.busy = false
                        self.ready = true
                    })
                }
            },
            getSearchModel() {
                return {
                    imageTagId: iPageModel.new.imageTagId,
                    isRent: iPageModel.new.isRent,
                    market: 0,
                    epoch: iPageModel.new.epoch,
                    lvBranchId: (this.lvBranch > 0 && this.lvBranch < 3) ? this.lvBranch : 0
                }
            },
            showResults(source) {
                if (this.listState == this.listStates.LOADING && cachedSearchData) {
                    this.properties = cachedSearchData.slice(0, Math.min(8, cachedSearchData.length))
                    this.viewCache.used = []
                    this.listState = this.listStates.LOADED

                    this.$nextTick(() => {
                        cachedSearchData = null
                        this.ready = true
                        this.busy = false
                    })
                }
                else {
                    //not ready yet - do nothing
                }
            },
            loadVideo() {
                if (this.introVideo && this.introVideo.trim() != '') {
                    var self = this
                    self.$http.post('/utility/embedurl/', { value: this.introVideo }).then(({ data }) => {
                        if (data) {
                            self.embedtype = data.id
                            self.loadedurl = data.value
                        }
                    }, (response) => { })
                }
            },
            play() {
                var self = this
                self.playing = true

                this.$nextTick(() => {
                    self.embedurl = self.loadedurl
                })
            },
            carousels() {
                fhState.flktyGG = new Flickity(this.$refs.sliderGG, {
                    percentPosition: false,
                    cellSelector: '.doublec_slide',
                    wrapAround: true,
                    pageDots: true,
                    prevNextButtons: false,
                    imagesLoaded: true
                })
                fhState.flktyJE = new Flickity(this.$refs.sliderJE, {
                    percentPosition: false,
                    cellSelector: '.doublec_slide',
                    wrapAround: true,
                    pageDots: true,
                    prevNextButtons: false,
                    imagesLoaded: true
                })
            },
            mouseEnter() {
                this.paused = true
            },
            mouseLeave() {
                this.paused = false
            },
            scrollGuernsey() {
                if (!this.paused) {
                    fhState.flktyGG.next()
                }
                fhState.timeId = setTimeout(this.scrollJersey, 4500)
            },
            scrollJersey() {
                if (!this.paused) {
                    fhState.flktyJE.previous()
                }
                fhState.timeId = setTimeout(this.scrollGuernsey, 4500)
            }
        },
        mounted: function () {
            var self = this
            this.lvBranch = this.lvBranchId

            this.carousels()

            this.$nextTick(() => {
                //execute inital load
                self.loadLatest()
                self.loadVideo()
            })

            $(this.$refs.sliderGG)
                .on('mouseenter touchstart', self.mouseEnter)
                .on('mouseleave touchend', self.mouseLeave)
            $(this.$refs.sliderJE)
                .on('mouseenter touchstart', self.mouseEnter)
                .on('mouseleave touchend', self.mouseLeave)

            fhState.timeId = setTimeout(this.scrollGuernsey, 8000)

            document.addEventListener("visibilitychange", () => {
                if (!document.hidden) {
                    fhState.timeId = setTimeout(this.scrollGuernsey, 4000)
                } else {
                    clearTimeout(fhState.timeId)
                }
            })
        }
    })
} 