
const stickyContext = function(){
    var self = this,
        initialised,
        items = [],
        ticking,

    init = function() {
        if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
            start()
        } else {
            document.addEventListener('DOMContentLoaded', start);
        }
    },

    start = function(){
        if(!initialised){
            initialised = true
            window.addEventListener('scroll', function (event) {
                requestTick(true)
            }, false)
            window.addEventListener('resize', function (event) {
                requestTick(false)
            }, false)

            self.position()
        }        
    },

    update = function(){
        for(var i = 0; i < items.length; i++) {
            var pr = items[i].el.parentNode.getBoundingClientRect()

            if(pr.top > 0) {             
                if(items[i].state > 0) {
                    items[i].el.classList.remove('tacked')
                    items[i].el.classList.remove('bottomed')
                    items[i].state = 0
                }
            }
            else if (items[i].b >= (pr.height + pr.top)) {
                if(items[i].state != 2){
                    items[i].el.classList.remove('tacked')
                    items[i].el.classList.add('bottomed')
                    items[i].state = 2
                }
            }
            else {
                if(items[i].state != 1){
                    items[i].el.classList.remove('bottomed')
                    items[i].el.classList.add('tacked')
                    items[i].state = 1
                }
            }
        }
    },

    setvars = function(){
        for(var i = 0; i < items.length; i++) {
            var er = items[i].el.getBoundingClientRect(),
                style = getComputedStyle(items[i].el),
                mt = parseInt(style.marginTop)

            items[i].t = (mt > 0) ? mt : 0
            items[i].b = items[i].el.offsetHeight + items[i].t
        }
    },
    
    requestTick = function (isScroll) {
        if (ticking) {
            window.cancelAnimationFrame(ticking)
        }

        ticking = window.requestAnimationFrame(function () {
            if(items.length){
                if(!isScroll) setvars()
                update()
            }
        })
    }

    this.position = function(){
        setvars()
        update()
    }

    this.add = function(el){
        items.push({
            el: el,
            t:0,
            b:0,
            state:0
        })

        if(initialised){
            //reposition all
            this.position()
        }
    }

    this.remove = function(el){
        var x = items.findIndex(x => { return x.el == el })

        if(x > -1) {
            items.splice(x, 1)
        }
    }

    init()
}

const context = new stickyContext()

var VueSticky = {
    bind: function (el, binding) {
        //SPA: testing with dyn property loading
        context.add(el)
    },
    unbind :function(el){
        context.remove(el)
    }
}

const install = function(Vue, options) {
    Vue.directive("sticky", VueSticky);
}

VueSticky.install = install
export default VueSticky
