<template>
    <div class="social-buttons text-center">
        <ul>
            <template v-if="lvBranchId == 1">
                <li><a href="https://www.facebook.com/livingroomGSY/" target="_blank"><span class="front"><i class="fab fa-facebook-f"></i></span><span class="back"><i class="fab fa-facebook-f"></i></span></a></li>
                <li><a href="https://www.instagram.com/livingroomgsy/" target="_blank"><span class="front"><i class="fab fa-instagram"></i></span><span class="back"><i class="fab fa-instagram"></i></span></a></li>
            </template>
            <template v-if="lvBranchId == 2">
                <li><a href="https://www.facebook.com/livingroomJSY/" target="_blank"><span class="front"><i class="fab fa-facebook-f"></i></span><span class="back"><i class="fab fa-facebook-f"></i></span></a></li>
                <li><a href="https://www.instagram.com/livingroomjsy/" target="_blank"><span class="front"><i class="fab fa-instagram"></i></span><span class="back"><i class="fab fa-instagram"></i></span></a></li>
            </template>
            <li><a href="https://www.linkedin.com/company/livingroom-estate-agents-tm/" target="_blank"><span class="front"><i class="fab fa-linkedin-in"></i></span><span class="back"><i class="fab fa-linkedin-in"></i></span></a></li>
        </ul>
    </div>
</template>
<script>
    export default {
        props: [],
        data: function () {
            return {
            }
        },
        computed: {},
        methods: {},
        mounted: function () { }
    }
</script>