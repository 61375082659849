var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "galleryWrap", staticClass: "pd_g" }, [
    _vm.state == _vm.states.GALLERY
      ? _c(
          "div",
          { staticClass: "pd_g_gallery" },
          [
            _c(
              "div",
              {
                directives: [
                  { name: "scroll-monitor", rawName: "v-scroll-monitor" }
                ],
                staticClass: "pd_g_gallery-title"
              },
              [_c("div", { staticClass: "pgtt" }, [_vm._v("Image Gallery ")])]
            ),
            _vm._v(" "),
            _vm._l(_vm.previewImages, function(img, index) {
              return _c(
                "div",
                {
                  staticClass: "pdggi",
                  on: {
                    click: function($event) {
                      _vm.imageClick(index + 1, $event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-lazy-image", {
                        attrs: {
                          src: img.path + "?mode=crop&width=500&height=500"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.state == _vm.states.THUMBS
      ? _c(
          "div",
          { staticClass: "pd_g_album" },
          _vm._l(_vm.images, function(img, index) {
            return _c(
              "div",
              {
                on: {
                  click: function($event) {
                    return _vm.imageClick(index, $event)
                  }
                }
              },
              [
                img.isPortrait
                  ? _c(
                      "div",
                      { staticClass: "p" },
                      [
                        _c("v-lazy-image", {
                          attrs: { src: img.path + "?mode=max&height=200" }
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("v-lazy-image", {
                          attrs: {
                            src: img.path + "?mode=crop&width=300&height=200"
                          }
                        })
                      ],
                      1
                    )
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "small-gap text-center" }, [
      _c(
        "a",
        {
          staticClass: "btn gold",
          attrs: { href: "#gallery" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.changeView.apply(null, arguments)
            }
          }
        },
        [_vm._v(_vm._s(_vm.buttonText))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }