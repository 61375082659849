var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prompt-wrap", class: { active: _vm.active } },
    [
      _c("div", { ref: "promptEl", staticClass: "prompt" }, [
        _c("div", {
          staticClass: "prompt-copy",
          domProps: { innerHTML: _vm._s(_vm.data.message) }
        }),
        _vm._v(" "),
        _vm.data.links && _vm.data.links.length
          ? _c(
              "div",
              { staticClass: "prompt-control" },
              _vm._l(_vm.data.links, function(item) {
                return _c("div", { domProps: { innerHTML: _vm._s(item) } })
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c("i", { staticClass: "caret" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "prompt-back" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }