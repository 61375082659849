<template>
    <div class="grid properties-grid" v-bind:class="{ investor: isInvestor }">
        <div class="column" v-for="item in skeletons">
            <div class="column-inner pc-item pc-skeleton">
                <div class="pc-item-image"></div>
                <div class="pc-item-data">
                    <span class="sk1"></span>
                    <span class="sk2"></span>
                    <span class="sk3"></span>
                </div>
            </div>
        </div>
        <property-grid-cell v-for="(p, index) in propertyData"
                v-bind:key="p.id"
                v-bind:index="index"
                v-bind:item="p"></property-grid-cell>
        <transition name="inop">
            <div class="column column-full" v-if="state == 4">
                <h3>No matching properties</h3>
                <p>Please adjust your filters, or <a href="https://livingroom.nk.local/account/notifications">sign-up</a> to be notified when new properties are listed.</p>
            </div>
        </transition>
    </div>
</template>
<script>
    export default {
        props: ['properties', 'state', 'isInvestor', 'skcount'],
        data() {
            return {
                skeletonColumns: this.skcount == 8 ? [1, 2, 3, 4, 5, 6, 7, 8] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]                
            }
        },
        computed: {
            propertyData() { 
                if (this.state == 2) {
                    return this.properties
                }
                else {
                    return []
                }
            },
            skeletons() {
                if (this.state == 2 || this.state == 4) {
                    return []
                }
                else {
                    return this.skeletonColumns
                }
            }
        },
        methods: {
            go() {}
        },
        created() {            
        }
    }
</script>