var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      directives: [
        {
          name: "scroll-monitor",
          rawName: "v-scroll-monitor.emit",
          modifiers: { emit: true }
        }
      ],
      ref: "aSbtn",
      staticClass: "sbutton",
      class: { over: _vm.hover },
      attrs: { href: "#" },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.clicked.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "svg",
        { attrs: { viewBox: "0 0 200 50", preserveAspectRatio: "none" } },
        [
          _c("path", {
            staticClass: "afill",
            attrs: { d: "M200,50H0V0h200V50z" }
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "astroke",
            attrs: { d: "M200,50H0V0h200V50z" }
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "astroke a1",
            attrs: { d: "M200,50H0V0h200V50z" }
          })
        ]
      ),
      _vm._v(" "),
      _c("span", [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }