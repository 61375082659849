var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ddl-vue", class: { open: _vm.isOpen } }, [
    _c(
      "span",
      {
        staticClass: "ddl-vue-selected",
        class: { hasicon: _vm.icon },
        on: {
          click: function($event) {
            return _vm.toggle()
          }
        }
      },
      [
        _vm.icon ? _c("i", { class: _vm.icon }) : _vm._e(),
        _vm._v(" " + _vm._s(_vm.selectedText))
      ]
    ),
    _vm._v(" "),
    _c(
      "ul",
      _vm._l(_vm.items, function(item) {
        return _c(
          "li",
          {
            on: {
              click: function($event) {
                return _vm.select(item.id)
              }
            }
          },
          [_vm._v(_vm._s(item.name))]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }