<template>
    <div class="image-fw" ref="vidRef" v-bind:class="{animate:animate}">
        <div class="ifwi" v-bind:class="{video:video}" v-bind:style="'background-image:url(' + image + ')'" v-scroll-monitor>
            <a v-if="embed" v-on:click.prevent="play" href="#play" class="play hidden-xs">Play</a>
            <a v-if="video" v-bind:href="video" target="_blank" class="visible-xs play">Play</a>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['animate', 'image', 'video'],
        data() {
            return {
                embed: null
            }
        },
        methods: {
            play(){
                this.$refs.vidRef.insertAdjacentHTML('beforeend', this.embed) 
            },
            load(){
                var self = this

                self.$http.post('/utility/embed/', { value: self.video }).then(({ data }) => {
                    if(data && data.value){
                        self.embed = data.value
                    }
                }, (response) => {})
            }
        },
        mounted(){
            this.load()
        }
    }
</script>