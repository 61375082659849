var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ctablock ms" }, [
    _c("h2", [_vm._v(_vm._s(_vm.content.headline))]),
    _vm._v(" "),
    _c("div", {
      staticClass: "ctablock_copy text-light",
      domProps: { innerHTML: _vm._s(_vm.content.copy) }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "ctablock_btn" }, [
      _c(
        "a",
        {
          staticClass: "btn big gold hoverwhite",
          attrs: { href: _vm.content.buttonurl }
        },
        [_vm._v(_vm._s(_vm.content.buttontext))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }