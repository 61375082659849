var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.css }, [
    _c(
      "div",
      { staticClass: "people" },
      [
        _vm._l(_vm.locationBios, function(item) {
          return _c("div", { staticClass: "people-item" }, [
            _c("a", { attrs: { href: _vm.bioUrl(item) } }, [
              _c("img", {
                attrs: { src: item.profileimage.url + "?fit=crop&w=550&h=520" }
              })
            ]),
            _vm._v(" "),
            _c("h3", [
              _c("a", { attrs: { href: _vm.bioUrl(item) } }, [
                _vm._v(_vm._s(item.fullname))
              ])
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(item.jobtitle))])
          ])
        }),
        _vm._v(" "),
        _c("div", { staticClass: "people-item dummy" }),
        _vm._v(" "),
        _c("div", { staticClass: "people-item dummy" }),
        _vm._v(" "),
        _c("div", { staticClass: "people-item dummy" })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }