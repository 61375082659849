<template>
  <div class="vnis" >
    <button v-on:click.prevent="decreaseNumber" v-bind:class="buttonClass">-</button>
    <input type="number"
        v-bind:value="numericValue"
        v-on:keypress="validateInput"
        v-on:input="numericValue = cleanInput($event.target.value)"
        v-bind:class="inputClass"
        v-bind:min="min"
        v-bind:max="max"
        debounce="500" />
    <button v-on:click.prevent="increaseNumber" v-bind:class="buttonClass">+</button>
  </div>
</template>
<script>
export default {
    props: {
        value: {
          default: 0
        },
        min: {
            default: 0,
            type: Number
        },
        max: {
            default: 10,
            type: Number
        },
        step: {
          default: 1,
          type: Number
        },
        inputClass: {
          default: 'vnis__input',
          type: String
        },
        buttonClass: {
          default: 'vnis__button',
          type: String
        },
        integerOnly: {
          default: false,
          type: Boolean
        }
    },
    
    data: function () {
        return {
            numericValue: this.value,
        };
    },

    methods: {
        increaseNumber() { this.numericValue += this.step; },

        decreaseNumber() { this.numericValue -= this.step; },

        isInteger(evt) {
            evt = (evt) ? evt : window.event;
            let key = evt.keyCode || evt.which;
            key = String.fromCharCode( key );
            const regex = /[0-9]/;
            
            if( !regex.test(key) ) {
                evt.returnValue = false;
                if(evt.preventDefault) evt.preventDefault();
            }
        },

        isNumber(evt) {
           evt = (evt) ? evt : window.event;
           var charCode = (evt.which) ? evt.which : evt.keyCode;

           if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
               evt.preventDefault();
           }
           else {
               return true;
           }
       },

       validateInput(evt) {
          if ( this.integerOnly === true) {
              this.isInteger(evt);
          }
          else {
              this.isNumber(evt);
          }
       },

       cleanInput(evtVal){           
           return Number(evtVal)
       }
    },

    watch: {
        numericValue(val, oldVal){
            if( val <= this.min ) { this.numericValue = parseInt(this.min); }

            if( val >= this.max ) { this.numericValue = parseInt(this.max); }

            if( val <= this.max && val >= this.min ) {
                this.$emit('input', val, oldVal );
            }
        },
        value(val) {
            this.numericValue = val
        }
    }
};
</script>