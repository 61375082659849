import * as storage from './storage'
var parse = require('fast-json-parse')

function historyCache() {
    let historyKey = 'lvrScrHistory'

    const def = {
        find,
        add,
        getObject
    }; 
    
    function getObject() {
        let sc = storage.local.getItem(historyKey)

        if(sc && sc.length){
            let so = parse(sc)
            if(so.err) {
                storage.local.clear()
            }
            else {
                return so.value
            }
        } 

        return null
    }

    function find(name) {
        var tc = getObject()

        if(tc)
        {
            var i = tc.findIndex(x => x.t == name)
            if(i > -1){ 
                return tc[i].v
            }
        }
            
        return null
    }    
    function add(name, value) {
        let tc = getObject()
        
        if(!tc) {
            tc = [{ t: name, v: value }]
        }
        else {
            let i = tc.findIndex(x => x.t == name)
            if(i > -1){ tc[i].v = value }
            else {
                tc.push({ t: name, v: value })
            }

            if(tc.length > 20){
                tc.shift()
            }
        }

        storage.local.setItem(historyKey, JSON.stringify(tc))
    }
    
    return def;
};

export const hcache = historyCache()