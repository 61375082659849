<template>
    <transition name="gmap" v-on:after-enter="mapOpened">
        <section v-show="active" class="property property-map z2" v-cloak>
            <div ref="propMap" class="gmap"></div>
        </section>
    </transition>
</template>
<script>
    export default {
        props: ['active','lat','lng'],
        data() {
            return {
                loading: false
            }
        },
        computed: {},
        methods: {
            mapOpened(){
                if(this.mapApp.map){
                    this.mapApp.map.setCenter({lat: this.mapApp.lat, lng: this.mapApp.lng})
                }
                else {
                    var latlang = new google.maps.LatLng(this.mapApp.lat, this.mapApp.lng)  

                    this.mapApp.map = new google.maps.Map(this.mapApp.el, {
                        zoom: 15,
                        center: latlang
                    })
                    this.mapApp.marker = new google.maps.Marker({ 
                        position: latlang,
                        animation: google.maps.Animation.DROP,
                        map: this.mapApp.map
                    })
                }

            }
        },
        mounted() {
            this.mapApp = {
                lat: this.lat,
                lng: this.lng,
                el: this.$refs.propMap,
                map:null,
                marker:null
            }
        }
    }
</script>