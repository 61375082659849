import Vue from '../config'
var _ = require('lodash')
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import { getFilters, getFilterValue } from './helpers'

if(document.getElementById('development-vm')) {
    new Vue({
        el: '#development-vm',
        data: {            
            loading: true,
            ready: false,
            images: iPageModel.images,
            floorplan: iPageModel.floorplan,       
            properties: iPageModel.properties,
            count: {
                initial:    0,
                current:    0,
                total:      0
            },
            planAdded: false,
            plan:null,
            viewMore: false,
            mapOpen: false,
            filters: getFilters(iMenuModel.jurisdiction, null, null, iMenuModel.saleRange, iMenuModel.rentRange),   

            markers: {
                '200K': '200K',
                '400K': '400K',
                '600K': '600K',
                '800K': '800K',
                '1M': '1M',
                '3M': '3M'
            },
            slider: {
                dotStyle: { "backgroundColor": "#FBF8F1" }
            },
            loPrice: 0,
            hiPrice: 999999999
        },
        computed: {
            priceName(){
                return this.nicePrice(this.filters.price.value[0].split('|')[0], this.filters.price.value[1].split('|')[0])
            },
            throttledImages(){
                if(this.count.current < this.count.initial || !this.ready) {
                    return this.images.slice(0, this.count.initial)
                }
                else {
                    return this.images
                }
            },
            filteredProperties(){
                if(this.properties && this.properties.length > 5){
                    var self = this

                    return _.filter(
                        this.properties, function(o) { 
                            return o.price <= self.hiPrice && o.price >= self.loPrice
                        })
                }

                return this.properties
            }
        },
        methods: {
            priceChange: _.debounce(function () {
                this.loPrice = this.priceValue(this.filters.price.value[0].split('|')[0])
                this.hiPrice = this.priceValue(this.filters.price.value[1].split('|')[0])                
            }, 200),
            showPlan(){
                var rect = this.$refs.planThumb.getBoundingClientRect(),
                        sT = window.pageYOffset || document.documentElement.scrollTop
                            
                this.launchPlan({
                    x: rect.left,
                    y: rect.top + sT, 
                    w: rect.width
                })
            },
            openGallery(){
                if(this.imgState.slides.length){
                    let imageSource = this.imgState.slides[0].src
                    this.imageClick(imageSource,null)
                }
            },
            imageClick(imageSource, imageRect){
                let srcIndex = this.imgState.slides.findIndex(e => {
                    return e.src === imageSource
                })
                
                if(srcIndex !== -1){
                    var options = {
                        index: srcIndex,

                        zoomEl: false,
                        fullscreenEl: false,
                        shareEl: false,
                        history:false,

                        showHideOpacity:true,
                        showAnimationDuration: 1000                
                    }

                    if(imageRect){                        
                        options.getThumbBoundsFn = function(index){
                            return imageRect
                        } 
                    }
                
                    var gallery = new PhotoSwipe(this.pswpElement, PhotoSwipeUI_Default, this.imgState.slides, options)
                    gallery.init()
                }
            },
            imageDone(id, index, loadedSrc){
                this.images[index].loaded = true        
                this.$refs.flickity.add(this.images[index], index)

                this.imgState.slides.push({
                    src: loadedSrc,
                    msrc: loadedSrc,
                    w: Math.floor(this.images[index].width * (this.imgState.baseHeight / this.images[index].height)),
                    h: this.imgState.baseHeight
                })

                this.imageTrack()
            },            
            imageError(id, index){
                this.images[index].broken = true
                this.imageTrack()
            },
            imageTrack(){
                this.count.current++
                if(this.count.current == this.count.initial){
                    var self = this
                    //initial load done - ready to show remainder
                    this.$nextTick(() => {
                        self.$refs.flickity.resize()
                        self.ready = true 

                        setTimeout(function(){ 
                            self.loadAnimationComplete()
                        }, 300)
                    })
                }
                
                if((this.count.current >= this.count.total -1) && !this.plan){
                    this.plan = this.floorplan
                }
            },
            floorplanDone(id, index, loadedSrc){
                var ratio = (this.plan.width > this.plan.height) ?  (1800 / this.plan.width) : (1800 / this.plan.height)
                this.plan.loaded = true        
                this.plan.src = loadedSrc
                this.imgState.slides.push({
                    src: loadedSrc,
                    msrc: loadedSrc,
                    w: Math.round(this.plan.width * ratio),
                    h: Math.round(this.plan.height * ratio)
                })
            },
            launchPlan(imageRect){
                if(this.plan.loaded) {
                    this.imageClick(this.plan.src, imageRect)
                }
            },            
            loadAnimationLeave(el, done){
                this.$refs.flickity.show()
                //done()
            },
            loadAnimationComplete(){
                this.loading = false
            },
            nicePrice(low, high){
                var nLow = low, nHigh = high

                if(low == 'Min') { nLow = '£0' }
                else { nLow = '£' + low }

                if(high == 'Max') { nHigh = 'Max' }
                else { nHigh = '£' + high }

                return (nLow + ' - ' + nHigh)
            },
            priceValue(priceText){
                if(priceText == 'Min') { return 0; }
                if(priceText == 'Max') { return 999999999 }

                var unit = priceText.slice(-1),
                    amount = parseFloat(priceText.slice(0, priceText.length - 1))

                if(unit.toLowerCase() == 'm') { return amount * 1000000 }
                else {
                    return amount * 1000
                }
            }
        },
        created() {
            this.imgState = {
                slides: [],
                baseHeight: 1100
            }
        },
        mounted() {
            if(this.images.length)
            {
                this.count.total = this.images.length
                this.count.initial = (this.images.length > 2) ? 3 : this.images.length
            }

            this.pswpElement = document.getElementById('pswp-modal')
            this.mapOpen = true
        }
    })

}