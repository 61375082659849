<template>
    <div :class="css">
        <div class="list__news_control" v-if="content.showcategories">
            <div class="list__news_control_loc">
                <a href="#Guernsey" :class="{ active: request.location == 'gg' }" @click.prevent="toggleLocation('gg')">Guernsey</a>
                <a href="#Jersey" :class="{ active: request.location == 'je' }" @click.prevent="toggleLocation('je')">Jersey</a>
                <a href="#All" :class="{ active: request.location == '' }" @click.prevent="toggleLocation('')">All</a>
            </div>
            <div class="list__news_control_cat" v-if="this.categories.length">
                <multiselect v-model="selectedCategory" track-by="name" label="name" placeholder="Category Filters"
                             @input="categoryChange" :options="categories" :searchable="false"></multiselect>
            </div>
        </div>
        <div class="list__news" v-infinite-scroll="checkMore" infinite-scroll-disabled="scrollDisabled" infinite-scroll-distance="10">
            <div class="wait-throbber" v-bind:class="{active:busy, scrolled:scrolled}"><i></i><i></i><i></i><i></i></div>
            <div class="list__news_item" v-for="item in articles">
                <a :href="'/news/' + item.slug">
                    <v-lazy-image v-bind:src="item.thumbnail.url + '?fit=crop&w=600&h=450'"
                                  src-placeholder='data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 450"%3E%3C/svg%3E' />
                    <div class="category">{{ item.category.name }} <span v-if="item.site.tld != 'com'">• {{item.site.name}}</span></div>
                    <h4>{{item.title}}</h4>
                    <p class="desc">{{ item.subTitle | truncate(100, '...') }}</p>
                    <p class="meta">{{ item.dateFormat }}</p>
                </a>
            </div>
            <div class="list__news_item dummy"></div>
            <div class="list__news_item dummy"></div>
        </div>
        <div class="list__news_none" v-if="articles.length == 0 && !busy"><p>No articles were found that match your search.</p></div>
    </div>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data: function () {
            return {
                articles: [],
                categories: [],
                selectedCategory: null,
                busy: true,
                scrolled: false,
                request: {
                    categoryId: 0,
                    location: '',
                    pageSize: 8,
                    page: 1
                },
                lastCount: 0
            }
        },
        computed: {
            css() {
                var a = []
                if (this.content.container) {
                    a.push(this.content.container.classkey)
                }
                else {
                    a.push('ms')
                }
                return a
            },
            scrollDisabled() {
                return this.busy || !this.content.showpaging
            }
        },
        methods: {
            loadNews() { 
                let self = this
                self.busy = true

                self.$http.post('/news', self.request).then(({ data }) => {
                    if (data && data.articles && data.articles.length) {
                        self.lastCount = data.articles.length
                        data.articles.forEach(v => {
                            self.articles.push(v)
                        })
                    }
                    else {
                        self.lastCount = 0
                    }
                    this.$nextTick(() => {
                        self.busy = false
                    })
                }, (response) => {
                    self.lastCount = 0
                    self.busy = false
                })
            },
            loadCategories() {
                let self = this

                self.$http.post('/news/categories').then(({ data }) => {
                    if (data) {
                        self.categories = data
                    }
                }, (response) => {})
            },
            toggleLocation(tld) {
                if (this.request.location == '' && tld == '') {
                    return
                }
                else if (this.request.location == tld) {
                    this.request.location = ''
                }
                else {
                    this.request.location = tld
                }
                this.resetList()
                this.loadNews()
            },
            resetList() {
                this.scrolled = false
                this.request.page = 1
                this.articles = []
            },
            categoryChange(c) {
                if (this.selectedCategory) { this.request.categoryId = parseInt(this.selectedCategory.id) }
                else { this.request.categoryId = 0 }
                this.resetList()
                this.loadNews()
            },
            checkMore() {
                if (this.lastCount == this.request.pageSize && !this.busy) {
                    this.scrolled = true
                    this.lastCount = 0
                    this.request.page++
                    this.loadNews()
                }
            }
        },
        filters: {
            truncate: function (text, length, suffix) {
                if (text.length > length) {
                    return text.substring(0, length) + suffix;
                } else {
                    return text;
                }
            }            
        },
        mounted: function () {
            this.request.pageSize = this.content.pagesize
            if (this.content.showcategories) { this.loadCategories() }
            this.loadNews()
        }
    }
</script>