import Vue from 'vue'
var dev = false //reversed for production!
Vue.config.silent = !dev 
Vue.config.devtools = dev

import VeeValidate from 'vee-validate'
Vue.use(VeeValidate, { errorBagName: 'verrors' })

import VueResource from 'vue-resource'
Vue.use(VueResource)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

import VLazyImage from "v-lazy-image/v2/v-lazy-image.es.js";
Vue.use(VLazyImage)

import Paginate from 'vuejs-paginate'
import vueSlider from 'vue-slider-component'
//import vSelect from "vue-select"
import Multiselect from 'vue-multiselect'

import * as GmapVue from 'gmap-vue'
Vue.use(GmapVue, {
    load: {
        key: 'AIzaSyA9lF6azGUXRaayUAwWScY4p2nyzmahqwI', //'AIzaSyB7KAUrR7pDEC3W8Mr1C3_90y8uxs-69dk',
        libraries: 'geometry'
        // If you want to set the version, you can do so:
        // v: '3.26',
    },
    installComponents: true
})

import Cookies from 'js-cookie'
import { EventBus } from './app/event-bus'
const cookieName = 'lvBranchId'

Vue.mixin({
    data() {
        return {
            lvBranchId: 0
        }
    },
    methods: {
        setLvBranch(id) {
            var checkedId = (id < 0 || id > 2) ? 0 : id;
            this.lvBranchId = checkedId
            Cookies.set(cookieName, checkedId, { expires: 365 })
            EventBus.$emit('branchupdate', checkedId)
        }
    },
    mounted() {
        var b = Cookies.get(cookieName)
        if (b != null && b != '') this.lvBranchId = parseInt(b)
    }
})


Vue.component('block-builder', require('./cms/block_builder.vue').default)
Vue.component('filter-vue', require('./vue/filter.vue').default)
Vue.component('ddl-vue', require('./vue/ddl.vue').default)
Vue.component('numbers-vue', require('./vue/number-input.vue').default)
Vue.component('toggle-button', require('./vue/toggle-button.vue').default)
Vue.component('img-progress', require('./vue/img-progress.vue').default)
Vue.component('flkty-carousel', require('./vue/flkty-carousel.vue').default)
Vue.component('svg-cover', require('./vue/svg-cover.vue').default)
Vue.component('svg-button', require('./vue/svg-button.vue').default)
Vue.component('property-images', require('./vue/property-images.vue').default)
Vue.component('property-gallery', require('./vue/property-gallery.vue').default)
Vue.component('property-map', require('./vue/property-map.vue').default)
Vue.component('property-scroller', require('./vue/property-scroller.vue').default)
Vue.component('property-star', require('./vue/property-star.vue').default)
Vue.component('simple-prompt', require('./vue/simple-prompt.vue').default)
Vue.component('property-grid', require('./vue/property-grid.vue').default)
Vue.component('property-grid-cell', require('./vue/property-grid-cell.vue').default)
Vue.component('success-card', require('./vue/success-card.vue').default)
Vue.component('image-fw', require('./vue/image-fw.vue').default)
Vue.component('side-window', require('./vue/side-window.vue').default)
Vue.component('book-form', require('./vue/book-form.vue').default)
Vue.component('tell-more', require('./vue/tell-more.vue').default)
Vue.component('add-this', require('./vue/add-this.vue').default)
Vue.component('social-icons', require('./cms/social_icons.vue').default)
Vue.component('nformr-insta', require('./vue/nformr-insta.vue').default)

Vue.component('vue-slider', vueSlider)
Vue.component('paginate', Paginate)
//Vue.component("v-select", vSelect)
Vue.component('multiselect', Multiselect)
Vue.component('v-lazy-image', VLazyImage)

import VueScrollMonitor from './app/scroll-directive'
Vue.use(VueScrollMonitor)
import VueSticky from './app/sticky-directive'
Vue.use(VueSticky)
import VueClickOut from './app/click-out-directive'
Vue.use(VueClickOut)



export default Vue