var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      class: _vm.className,
      attrs: { role: "checkbox", "aria-checked": _vm.ariaChecked }
    },
    [
      _c("input", {
        staticClass: "v-switch-input",
        attrs: { type: "checkbox" },
        on: {
          change: function($event) {
            $event.stopPropagation()
            return _vm.toggle.apply(null, arguments)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "v-switch-core", style: _vm.coreStyle }, [
        _c("div", { staticClass: "v-switch-button", style: _vm.buttonStyle }, [
          _vm.icon ? _c("i", { class: _vm.icon }) : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _vm.labels
        ? [
            _vm.toggled
              ? _c("span", {
                  staticClass: "v-switch-label v-left",
                  style: _vm.labelStyle,
                  domProps: { innerHTML: _vm._s(_vm.labelChecked) }
                })
              : _c("span", {
                  staticClass: "v-switch-label v-right",
                  style: _vm.labelStyle,
                  domProps: { innerHTML: _vm._s(_vm.labelUnchecked) }
                })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }