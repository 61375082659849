var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column" }, [
    _c("div", { staticClass: "column-inner pc-item" }, [
      _c(
        "div",
        { staticClass: "pc-item-image" },
        [
          _c("v-lazy-image", {
            staticClass: "df",
            attrs: { src: _vm.item.displayImage }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pc-item-data" }, [
      _c("div", { staticClass: "pid-n" }, [
        _vm._v(_vm._s(_vm.item.displayName) + ", "),
        _c("span", { staticClass: "pid-l" }, [
          _vm._v(_vm._s(_vm.item.lvBranchName))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pid-p success" }, [
        _vm.item.isSoldByUs
          ? _c("span", { staticClass: "status sold" }, [_vm._v("Sold")])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.isLeasedByUs
          ? _c("span", { staticClass: "status let" }, [_vm._v("Let")])
          : _vm._e(),
        _vm._v(" "),
        _c("span", [_vm._v("by Livingroom")])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }