import Vue from '../config'
var _ = require('lodash')
import { EventBus } from '../app/event-bus'

import { getScrollPosition, setScrollPosition } from './helpers'

if (document.getElementById('new-market-vm')) {
    var cachedSearchData = null

    new Vue({
        el: '#new-market-vm',
        data: {
            ready: false,
            busy: false,
            filters: {
                current: null
            },
            listStates: {
                LOADING: 1,
                LOADED: 2,
                EMPTY: 4
            },
            listState: 1,
            properties: [],
            selectedView: iPageModel.imageTagId,
            views: iPageModel.tags,
            viewCache: {
                used: [],
                global: 0,
                changing: false
            },
            isRent: iPageModel.isRent,
            isOpen: iPageModel.isOpen,
            lvBranch: 0
        },
        computed: {
            showWait() {
                return this.busy && !this.properties.length
            },
            noResults() {
                return this.properties.length == 0 && this.ready
            },
            marketType() {
                if (this.lvBranch == 1) {
                    if (this.isOpen) { return 2 }
                    return 1
                }
                return 0
            }
        },
        methods: {
            changeView(viewId) {
                if (!this.busy && (viewId != this.selectedView)) {
                    if (!this.busy && (viewId != this.selectedView)) {
                        let self = this
                        let idx = this.viewCache.used.findIndex(i => {
                            return i == viewId
                        })
                        self.selectedView = viewId

                        if (idx < 0) {
                            self.viewCache.changing = true
                            self.viewCache.global = 0

                            //search-images
                            self.$http.post('/new/search-images', this.getSearchModel()).then(({ data }) => {
                                if (data && data.length) {
                                    self.viewCache.used.push(viewId)
                                    for (var i = 0; i < data.length; i++) {
                                        self.properties[i].imageView = data[i]
                                    }
                                }
                                self.viewCache.changing = false
                            }, (response) => {
                                self.viewCache.changing = false
                            })
                        }
                        else {
                            self.viewCache.global = viewId
                        }
                    }
                }
            },
            changeMarket(open) {
                if (this.isOpen != open) {
                    this.isOpen = open
                    this.loadLatest()
                }
            },
            changeBranch(bid) {
                if (this.lvBranch != bid) {
                    this.lvBranch = bid
                    this.isOpen = false
                    this.setLvBranch(bid)
                    EventBus.$emit('uibranchchange', bid)
                    this.loadLatest()
                }
            },
            loadLatest() {
                if (!this.busy) {
                    this.busy = true
                    this.ready = true

                    let self = this
                    let posY = getScrollPosition().y

                    if (posY > 180) {
                        setScrollPosition(Vue, { x: 0, y: 180 })
                    }

                    this.listState = this.listStates.LOADING

                    self.$http.post('/new/search', this.getSearchModel()).then(({ data }) => {
                        if (data && data.length) {
                            cachedSearchData = data
                            self.showResults()
                        }
                        else {
                            self.busy = false
                            self.ready = true
                            self.viewCache.used = []
                            self.listState = self.listStates.EMPTY
                        }
                    }, (response) => {
                        self.busy = false
                        self.ready = true
                    })
                }
            },
            showResults(source) {
                if (this.listState == this.listStates.LOADING && cachedSearchData) {
                    var self = this
                    this.properties = cachedSearchData
                    this.viewCache.used = []
                    self.listState = self.listStates.LOADED

                    this.$nextTick(() => {
                        cachedSearchData = null
                        this.ready = true
                        this.busy = false
                    })
                }
                else {
                    //not ready yet - do nothing
                }
            },
            getSearchModel() {
                return {
                    imageTagId: this.selectedView,
                    isRent: this.isRent,
                    market: this.marketType,
                    lvBranchId: this.lvBranch,
                    epoch: iPageModel.epoch,
                }
            },

            toggleFilter(fuid) {
                this.filters.current = fuid
            },
            closeFilters() {
                this.filters.current = null
            }
        },
        mounted() {
            this.lvBranch = this.lvBranchId
            this.$nextTick(() => {
                //execute inital load
                this.loadLatest()
            })
        }
    })
}