<template>
    <div class="qblock" :class="css">
        <div class="qblock-copy text-center">
            <h3 class="tag-title" v-scroll-monitor></h3>
            <p class="qblock-title">{{content.quote}}</p>
            <p class="qblock-author" v-if="content.author && content.author.length">{{content.author}}</p>
        </div>
    </div>    
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            },
            isNews: {
                type: Boolean,
                required: false
            }
        },        
        computed: {
            css() {
                if (this.content.container) {
                    return [this.content.container.classkey]
                }
                else {
                    //if (this.isNews) {
                    //    return ['ms']
                    //}
                    return ['mn']
                }
            },
        },
        methods: {            
        }
    }
</script>


