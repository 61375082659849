var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.cssClass }, [
    _c("img", {
      ref: "lImg",
      attrs: { src: _vm.src },
      on: { click: _vm.imageClick }
    }),
    _c("i", { staticClass: "r" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }