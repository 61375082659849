<template>
    <div class="swc-panels">
        <form class="lvrf windowed" v-on:submit.prevent="submit">
            <transition name="swcp" v-on:leave="afterAnim">
                <div class="swcp" v-if="step == 1">
                    <p v-if="loading">Loading sharing center...</p>
                    <div class="await" v-if="loading"><i></i></div>
                    <div class="addthis_inline_share_toolbox"></div>
                </div>
            </transition>
        </form>
    </div>
</template>
<script>
    export default {
        props: ['active'],
        data() {
            return {
                loading:false,
                errors:[],
                step: 1
            }
        },
        methods: {
            submit(){
                let self = this
            },            
            afterAnim(el, done){
                this.$emit('changed', this.step)
                done()
            },
            complete(){
                this.$emit('complete')
            }
        },
        mounted(){
            this.$emit('changed', this.step)

            if(!window.addthis){
                let self = this
                this.loading = true

                var s = document.createElement('script')
                s.onload = function(){
                    self.loading = false
                }
                s.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5bae08ee6d9e22a8'
                document.getElementsByTagName('head')[0].appendChild(s)
            }
            else {                
                //SPA: will need to change URL for each prop 
                //see https://stackoverflow.com/questions/35761062/add-addthis-to-react-component
                //and https://www.addthis.com/academy/using-dashboard-configuration-tools-dynamically/
                addthis.layers.refresh()
            }
        }
    }
</script>