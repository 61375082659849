<template>
    <div class="panel" :class="css">
        <div class="panel_head" :class="{open:open}">
            <div @click="open = !open">
                <h3>{{content.title}}</h3>
                <p v-if="content.intro && content.intro.length">{{content.intro}}</p>
                <span class="text-upper more">Read More</span>
            </div>
        </div>
        <transition name="inacc">
            <div class="panel_body" v-if="open">
                <div v-html="content.text"></div>
            </div>
        </transition>
    </div>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                open:false
            }
        },
        computed: {
            css() {
                var a = []
                if (this.content.container) {
                    a.push(this.content.container.classkey)
                }
                else {
                    a.push('mn')
                }

                if (this.content.background) {
                    a.push(this.content.background.classkey)
                }
                return a
            }
        },
        methods: {
        }
    }
</script>