var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.blocks, function(block, idx) {
      return _c(
        _vm.getBlockComponent(block),
        _vm._b(
          { key: idx, tag: "component", class: _vm.getBlockClass(block) },
          "component",
          _vm.getBlockProps(block),
          false
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }