var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "grid properties-grid",
      class: { investor: _vm.isInvestor }
    },
    [
      _vm._l(_vm.skeletons, function(item) {
        return _c("div", { staticClass: "column" }, [_vm._m(0, true)])
      }),
      _vm._v(" "),
      _vm._l(_vm.propertyData, function(p, index) {
        return _c("property-grid-cell", {
          key: p.id,
          attrs: { index: index, item: p }
        })
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "inop" } }, [
        _vm.state == 4
          ? _c("div", { staticClass: "column column-full" }, [
              _c("h3", [_vm._v("No matching properties")]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Please adjust your filters, or "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://livingroom.nk.local/account/notifications"
                    }
                  },
                  [_vm._v("sign-up")]
                ),
                _vm._v(" to be notified when new properties are listed.")
              ])
            ])
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-inner pc-item pc-skeleton" }, [
      _c("div", { staticClass: "pc-item-image" }),
      _vm._v(" "),
      _c("div", { staticClass: "pc-item-data" }, [
        _c("span", { staticClass: "sk1" }),
        _vm._v(" "),
        _c("span", { staticClass: "sk2" }),
        _vm._v(" "),
        _c("span", { staticClass: "sk3" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }