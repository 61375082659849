<template>
    <div v-bind:class="cssClass"><img ref="lImg" v-bind:src="src" v-on:click="imageClick" /><i class="r"></i></div>
</template>
<script>
    export default {
        props: ['src', 'id', 'index'],
        data() {
            return {
                states: {
                    LOADING:0,
                    COMPLETE:1,
                    BROKEN:2
                },
                state: 0,
                dispatched: false,
                proxy: null
            }
        },
        computed: {    
            cssClass(){
                return {
                    'load': this.state == this.states.LOADING,
                    'broken': this.state == this.states.BROKEN,
                    'complete': this.state == this.states.COMPLETE
                }
            }
        },
        methods: {
            check(){
                if(this.isLoaded()){
                    this.state = (this.$refs.lImg.naturalWidth !== 0) ?  this.state = this.states.COMPLETE : this.state = this.states.BROKEN
                    this.dispatch()
                }
                else {
                    this.$refs.lImg.addEventListener('load', this.onLoad )
                    this.$refs.lImg.addEventListener('error', this.onLoadError )

                    this.proxy = new Image();
                    this.proxy.addEventListener( 'load', this.onLoad );
                    this.proxy.addEventListener( 'error', this.onLoadError );
                    this.proxy.src = this.$refs.lImg.src
                }
            },
            onLoad(e){
                if(!this.dispatched){                    
                    this.state = this.states.COMPLETE
                    this.dispatch()
                    this.unbind()
                }
            },
            onLoadError(e){
                if(!this.dispatched){                    
                    this.state = this.states.BROKEN
                    this.dispatch()
                    this.unbind()
                }
            },

            isLoaded(){
                return this.$refs.lImg.complete && this.$refs.lImg.naturalWidth
            },

            dispatch(){
                if(!this.state == this.states.LOADING){    
                    this.dispatched = true
                    this.$emit(this.state == this.states.COMPLETE ? 'done' : 'error', this.id, this.index, this.$refs.lImg.src)
                }
            },
            unbind(){
                this.$refs.lImg.removeEventListener('load', this.onLoad )
                this.$refs.lImg.removeEventListener('error', this.onLoadError )
                this.proxy.removeEventListener( 'load', this.onLoad );
                this.proxy.removeEventListener( 'error', this.onLoadError );
            },

            imageClick(){
                var clickSrc = this.$refs.lImg.src

                if(clickSrc) {
                    var rect = this.$refs.lImg.getBoundingClientRect(),
                        sT = window.pageYOffset || document.documentElement.scrollTop
                            
                    this.$emit('iclick', clickSrc, {
                        x: rect.left,
                        y: rect.top + sT, 
                        w: rect.width
                    })
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.state = this.states.LOADING
                this.check()
            })
        }
    }
</script>