var $ = require('jquery')

var general = (function () {
    var self = this,
        hh,
        hslides

    var build = function () {
        var stacks = $('.stack')

        if (stacks.length) {
            stacks.find('.stack-header').on('click', function (e) {
                $(this).parent().toggleClass('active')
            })
        }

        hh = $('#homehero')
        if (hh.length) {
            hslides = hh.find('.hhi')
            setTimeout(slideIn, 3000)
        }
    },

        slideIn = function () {
            var vis = hslides.not('.out'),
                idx = hslides.index(vis),
                next = (idx < (hslides.length - 1)) ? hslides.eq(idx + 1) : hslides.eq(0)

            vis.removeClass('in')
            next.removeClass('out').addClass('in')

            setTimeout(function () {
                slideOut(vis)
            }, 4000)
        },

        slideOut = function (jel) {
            jel.addClass('out')
            setTimeout(slideIn, 4000)
        }

    $(function () {
        build()
        $('.hero-block video').each(function () {
            var $elem = $(this)
            if (window.innerWidth > 768) {
                $elem.attr('preload', 'auto')
                this.autoplay = true
                this.load()
                this.play()
            }
        })
    })
})()

module.exports = general