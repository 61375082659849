import Vue from '../config'

if (document.getElementById('property-detail-vm')) {
    new Vue({
        el: '#property-detail-vm',
        data: {
            images: iPageModel.gallery,
            floor: iPageModel.floorplan,
            cprice: iPageModel.calculatorPrice,
            tour: iPageModel.tour,
            extra: false,
            tools: {
                map: false,
                tour: false,
                book: {
                    active: false,
                    step: 0,
                    busy: false
                },
                tell: {
                    active: false,
                    step: 0,
                    busy: false
                },
                share: false
            },
            related: iPageModel.related,
            mapOptions: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: true,
                zoomControlOptions: { style: 1, position: 4 },  // I can't use the enums since this instantiates before lib is loaded so I've just used the actual values here
                styles: [
                    {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    }
                ]
            },
            bio: null,
        },
        computed: {
            tourTitle() {
                return this.tour.embedUrl.toLowerCase().indexOf('matterport') > 0 ? 'Virtual Tour' : 'Lookbook'
            },
            bioImage() {
                if (this.bio) {
                    return this.bio.profileImage.url + '?fit=crop&w=1000&h=1000'
                }
                return null
            },

        },
        methods: {
            showGallery() {
                this.$refs.propertyGallery.openGallery()
            },
            showPlan(getRect) {
                this.$refs.propertyGallery.openFloorplan()
            },
            launchTour(e) {
                if (!this.tools.tour) {
                    this.closeTools()
                    var t = (e.target.classList.contains('ground') || e.target.classList.contains('start')) ? e.target.parentNode : e.target
                    if (!e.target.classList.contains('moblink')) {
                        this.tools.tour = true
                        //SPA: empty on unload?  
                        t.insertAdjacentHTML('beforeend', this.tour.embed)
                    }
                }
            },
            showPrompt(promptObj) {
                this.$refs.pagePrompt.activate(promptObj)
            },
            showMap() {
                this.$scrollTo(document.querySelector('#map'), 500, {
                    offset: -110
                })
            },
            loadBio(id) {
                let self = this
                self.$http.post('/utility/bio/', { id: id }).then(({ data }) => {
                    if (data) {
                        self.bio = data
                    }
                }, (response) => { })
            },
            //bookings and enquiries form
            openBooking() {
                if (!this.tools.book.active) {
                    this.closeTools()
                    this.tools.book.busy = false
                    this.tools.book.active = true
                }
            },
            closeExtra() {
                this.extra = false
                this.$scrollTo(this.$refs.propertyInfo, 500, {
                    offset: -110
                })
            },
            updateBooking(step) {
                this.tools.book.step = step
            },
            sendingBooking(isactive) {
                this.tools.book.busy = isactive
            },
            submitBooking() {
                this.$refs.bookFrm.submit()
            },
            //pc tell me more form
            openTell() {
                if (!this.tools.tell.active) {
                    this.closeTools()
                    this.tools.tell.active = true
                }
            },
            submitTell() {
                this.$refs.tellFrm.submit()
            },
            sendingTell(isactive) {
                this.tools.tell.busy = isactive
            },
            updateTell(step) {
                this.tools.tell.step = step
            },
            //sharing tool
            openShare() {
                if (!this.tools.share) {
                    this.closeTools()
                    this.tools.share = true
                }
            },
            closeTools() {
                this.tools.book.active = false
                this.tools.tell.active = false
                this.tools.share = false
            }
        },
        created() { },
        mounted() {
            if (iPageModel.bioId) {
                this.loadBio(iPageModel.bioId)
            }
            //const stickyEl = document.querySelector(".property-inpage")
            //if (stickyEl) {
            //    var respRootMargin = ''

            //    function rootMarginBreakPoint(mq) {
            //        if (mq.matches) {
            //            //tablet+
            //            respRootMargin = '-60px 0px 0px 0px'
            //        }
            //        else {
            //            //mobile
            //            respRootMargin = '-50px 0px 0px 0px'
            //        }
            //    }

            //    let mqMob = window.matchMedia("(min-width: 768px)")
            //    mqMob.addListener(rootMarginBreakPoint)
            //    rootMarginBreakPoint(mqMob)

            //    const stickyObs = new IntersectionObserver(
            //        ([e]) => {
            //            if (e.intersectionRatio < 1 && e.boundingClientRect.top < 100) {
            //                e.target.classList.add('pinned')
            //            } else {
            //                e.target.classList.remove('pinned')
            //            }
            //        },
            //        {
            //            rootMargin: respRootMargin,
            //            threshold: [1]
            //        }
            //    )
            //    stickyObs.observe(stickyEl)
            //}            
        }
    })

}