<template>
    <div class="svg-cover-loader">
        <div><img src="/public/img/spacer.gif" /></div>
        <svg ref="aSvg" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 400 280" preserveAspectRatio="xMidYMid slice">
            <defs>
                <mask id="ringMask">
                    <polygon fill="white" points="0 280,400 280,400 0,0 0"></polygon>
                    <!--<path class="sa1" style="opacity:1;" d="M200.1,94.5c-24.9,0-45.1,20.2-45.1,45.1c0,24.9,20.2,45.1,45.1,45.1c24.9,0,45.1-20.2,45.1-45.1
	                        C245.2,114.7,225,94.5,200.1,94.5 M200.1,179.2c-21.8,0-39.5-17.7-39.5-39.5c0-21.8,17.7-39.5,39.5-39.5c21.8,0,39.5,17.7,39.5,39.5
	                        C239.6,161.5,222,179.2,200.1,179.2" />-->
                    <path class="sa2" style="opacity:1;" fill="black" d="M199.6,105.9c-18.7,0-33.9,15.2-33.9,33.9c0,18.7,15.2,33.9,33.9,33.9c18.7,0,33.9-15.2,33.9-33.9
	                        C233.4,121,218.3,105.9,199.6,105.9 M200,167.9c-15.6,0-28.2-12.6-28.2-28.2c0-15.6,12.6-28.2,28.2-28.2c15.6,0,28.2,12.6,28.2,28.2
	                        C228.2,155.3,215.5,167.9,200,167.9" />
                    <path class="sa3" style="opacity:0.5;" fill="black" d="M199.7,117.5c-12.5,0-22.6,10.1-22.6,22.6s10.1,22.6,22.6,22.6c12.5,0,22.6-10.1,22.6-22.6
	                        S212.1,117.5,199.7,117.5 M199.8,156.2c-9.3,0-16.9-7.6-16.9-16.9c0-9.3,7.6-16.9,16.9-16.9c9.3,0,16.9,7.6,16.9,16.9
	                        C216.7,148.6,209.1,156.2,199.8,156.2" />
                    <path class="sa4" style="opacity:0;" fill="black" d="M200,128.5c-6.2,0-11.3,5.1-11.3,11.3c0,6.2,5.1,11.3,11.3,11.3c6.2,0,11.3-5.1,11.3-11.3
	                        C211.3,133.6,206.2,128.5,200,128.5" />
                </mask>
            </defs>
            <polygon mask="url(#ringMask)" class="under" points="0 280,400 280,400 0,0 0"></polygon>
            <polygon v-if="preloading" class="under" points="0 280,400 280,400 0,0 0"></polygon>
        </svg>
        <span class="ripple" v-if="preloading"><em></em></span>
    </div>
</template>
<script>
    import anime from 'animejs'

    export default {
        props: ['animationNumber'],
        data() {
            return {
                preloading:true,
                complete: false,
                acount: 1
            }
        },
        computed: {},
        methods: {
            run(){
                var //a1 = this.$refs.aSvg.getElementsByClassName('sa1')[0],
                    a2 = this.$refs.aSvg.getElementsByClassName('sa2')[0],
                    a3 = this.$refs.aSvg.getElementsByClassName('sa3')[0],
                    a4 = this.$refs.aSvg.getElementsByClassName('sa4')[0]

                if(this.preloading){
                    this.preloading = false                    

                    var self = this,
                        tl = anime.timeline()

                    tl.add({
                        targets: a2,
                        scale: 8,
                        duration:800,
                        easing: 'easeOutExpo',
                        offset:0
                    }).add({
                        targets: a3,
                        scale: 13,
                        duration:1200,
                        easing: 'easeOutExpo',
                        offset:100
                    }).add({
                        targets: a4,
                        scale: 20,
                        opacity:1,
                        offset:0,
                        duration:1600,
                        easing: 'easeOutExpo',
                        offset:200,
                        complete: function(anim) {
                            self.$emit('complete')
                        }
                    })

                    //tl.add({
                    //    targets: a1,
                    //    scale: 6,
                    //    duration:1000,
                    //    easing: 'easeOutExpo',
                    //    offset:0
                    //}).add({
                    //    targets: a2,
                    //    scale: 8,
                    //    duration:1400,
                    //    easing: 'easeOutExpo',
                    //    offset:100
                    //}).add({
                    //    targets: a3,
                    //    scale: 13,
                    //    duration:1800,
                    //    easing: 'easeOutExpo',
                    //    offset:200
                    //}).add({
                    //    targets: a4,
                    //    scale: 20,
                    //    opacity:1,
                    //    offset:0,
                    //    duration:2200,
                    //    easing: 'easeOutExpo',
                    //    offset:300,
                    //    complete: function(anim) {
                    //        self.$emit('complete')
                    //    }
                    //})
                }
            }
            //testclicker(){
            //    if(this.acount <= 4 && this.preloading) {
            //        var aElement = this.$refs.aSvg.getElementsByClassName('sa' + this.acount)[0]

            //        console.log('+ sa' + this.acount)

            //        var scaleXY = 6
            //        if(this.acount == 2) scaleXY = 7
            //        if(this.acount == 3) scaleXY = 12
            //        if(this.acount == 4) scaleXY = 20

            //        anime({
            //            targets: aElement,
            //            scale: scaleXY
            //        })
            //        if(this.acount == 4) { this.preloading=false }
            //        else {this.acount++}
            //    } 
            //    else {
            //        var aElement = this.$refs.aSvg.getElementsByClassName('sa' + this.acount)[0]

            //        console.log('- sa' + this.acount)

            //        anime({
            //            targets: aElement,
            //            scale: 1
            //        })
            //        if(this.acount == 1) { this.preloading=true }
            //        else {this.acount--}
            //    }
            //}            
        }
    }
</script>