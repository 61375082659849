var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column", class: { removed: _vm.removed } }, [
    _c("div", { staticClass: "column-inner pc-item" }, [
      _c(
        "div",
        {
          staticClass: "pc-item-image",
          class: { over: _vm.isOver || _vm.isFavourite },
          on: {
            mouseenter: function($event) {
              _vm.isOver = true
            },
            mouseleave: _vm.leave
          }
        },
        [
          _c("a", {
            staticClass: "piia",
            attrs: { href: _vm.item.niceUrl, target: "_blank" }
          }),
          _vm._v(" "),
          _c("v-lazy-image", {
            staticClass: "df",
            attrs: { src: _vm.item.displayImage }
          }),
          _vm._v(" "),
          !_vm.preventFavourite
            ? _c(
                "div",
                { staticClass: "fv" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "svg",
                      class: { on: _vm.isFavourite },
                      on: { click: _vm.toggle }
                    },
                    [
                      _c(
                        "svg",
                        {
                          ref: "aSvg",
                          attrs: {
                            version: "1.1",
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 288 288",
                            width: "100%",
                            height: "100%"
                          }
                        },
                        [
                          _c("polygon", {
                            staticClass: "b",
                            attrs: {
                              points:
                                "144,215.8 65.5,264.9 88,175.1 17,115.6 109.4,109.2 144,23.3 178.6,109.2 271,115.6 200,175.1 222.5,264.9 "
                            }
                          }),
                          _vm._v(" "),
                          _c("polygon", {
                            staticClass: "f",
                            attrs: {
                              points:
                                "144,192.8 96.1,222.8 109.8,168 66.5,131.7 122.9,127.8 144,75.4 165.1,127.8 221.5,131.7 178.2,168 191.9,222.8 "
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "fvg" } }, [
                    _vm.isGuest
                      ? _c("span", [
                          _c("a", { attrs: { href: "/auth/login" } }, [
                            _vm._v("Log In")
                          ]),
                          _vm._v(" or "),
                          _c("a", { attrs: { href: "/auth/register" } }, [
                            _vm._v("Register")
                          ])
                        ])
                      : _vm._e()
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pc-item-data" }, [
        _c("a", { attrs: { href: _vm.item.niceUrl, target: "_blank" } }, [
          _c("div", { staticClass: "pid-n" }, [
            _vm._v(_vm._s(_vm.item.displayName) + ", "),
            _c("span", { staticClass: "pid-l" }, [
              _vm._v(_vm._s(_vm.item.lvBranchName))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pid-m" }, [
            _c("span", { staticClass: "pid-br" }, [
              _vm._v(_vm._s(_vm.item.locationName) + ", ")
            ]),
            _vm._v(" "),
            _vm.item.lvBranchId == 1
              ? _c("span", { staticClass: "pid-br" }, [
                  _vm._v(
                    _vm._s(_vm.item.isOpen ? "Open Market" : "Local Market") +
                      ", "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.item.bedrooms
              ? _c("span", { staticClass: "pid-bd" }, [
                  _vm._v(
                    _vm._s(_vm.item.bedrooms) +
                      " " +
                      _vm._s(_vm.item.bedrooms == 1 ? "bed" : "beds")
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.item.annualReturn
              ? _c("span", { staticClass: "pid-ar" }, [
                  _vm._v("Est. " + _vm._s(_vm.item.annualReturn) + "% return")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pid-p" }, [
            !_vm.item.isSoldVisible && !_vm.item.isLeasedVisible
              ? _c("span", [_vm._v(_vm._s(_vm.item.displayPrice))])
              : _vm._e(),
            _vm._v(" "),
            _vm.item.isSoleAgent
              ? _c("span", { staticClass: "status sole" }, [
                  _vm._v("Sole Agent")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.item.hasStatus
              ? _c(
                  "span",
                  { staticClass: "status", class: [_vm.statusClass] },
                  [
                    _vm._v(
                      _vm._s(_vm.item.coallescedStatus) +
                        _vm._s(
                          _vm.item.isSoldByUs || _vm.item.isLeasedByUs
                            ? " By Livingroom"
                            : ""
                        )
                    )
                  ]
                )
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }