//Install polyfills
require('es6-promise').polyfill()
require('es6-object-assign').polyfill()
require('scroll-restoration-polyfill')
require('element-dataset')
require('./poly/classList').shim()
require('array.prototype.find').shim()
require('array.prototype.findindex').shim()

//UI
//import './ui/navigation'
import './ui/footer'
import './ui/general'

//App
import './app/header'
import './app/property-search'
import './app/property-detail'
import './app/development'
import './app/developments'
import './app/new-market'
import './app/favourites'
import './app/private-collections'
import './app/register'
import './app/login'
import './app/forgot'
import './app/reset'
import './app/contact'
import './app/generic-content'
import './app/cms-page'
import './app/foundation'
import './app/home-content' 
import './app/account-settings'
import './app/account-notifications'
import './app/account-tvm'
import './app/success-stories'
import './app/bio-page'

import '../css/styles.less'