var _ = require('lodash')

export function getFilters(jurisdiction, locations, features, salerange, rentrange){
    return {
        active: {
            current: null,
            last:null,
            more: false
        },
        price: {
            type: 'price',
            title: 'Price',
            text: 'Drag to filter by price range',
            value: ['Min', 'Max'],
            data: salerange
        },
        rentprice: {
            type: 'rentprice',
            title: 'Price',
            text: 'Drag to filter by price per calendar month',
            value: ['Min', 'Max'],
            data: rentrange
        },
        location: {
            type: 'location',
            title: 'Location',
            text: 'Select locations to search within',
            value: [],
            data: locations
        },
        market: {
            type: 'market',
            title: 'Market',
            text: 'Filter by Local and Open markets',
            value: {
                local: false,
                open: false
            }
        },
        rooms: {
            type: 'rooms',
            title: 'Rooms',
            text: 'Set minimum bedrooms & bathrooms',
            value: [0,0]
        },
        features: {
            type: 'features',
            title: 'Features',
            text: 'Select property features',
            value: {
                types: [],
                curation: 0
            },
            data: features
        }
    }
}

export function getFilterValue(ftype, fval){
    if (ftype == 'price' || ftype == 'rentprice') {
        return {
            min: fval[0],
            max: fval[1]
            //min: fval[0].split('|')[0],
            //max: fval[1].split('|')[0]
        }
    }
    else if(ftype == 'location'){
        return _.clone(fval)
    }
    else if(ftype == 'market'){
        return _.clone(fval)
    }
    else if(ftype == 'rooms'){
        return {
            beds: Number(fval[0]),
            baths: Number(fval[1])
        }
    }
    else if (ftype == 'features') {
        console.log('getFilterValue(features)', fval)
        return _.clone(fval)
    }

    return null;
}

export function findFilterValue(val, range){
    return range.find(x => {
        return x.indexOf(val) == 0
    })
}

export function serializeBasicRequest(r, jurisdiction) {    
    var qs = '?ts=' + new Date().getTime().toString() + 
             '&min=' + r.price.min + 
             '&max=' + r.price.max 

    if(jurisdiction != 'je') {
        qs += '&ml=' + r.market.local     //market local
        qs += '&mo=' + r.market.open     //market open
    }
    return qs
}

export function serializeRequest(r, branch, jurisdiction, showmap, zoom, lat, long, sort){
    var qs = '?ts=' + new Date().getTime().toString()

    if(r.rental) {
        qs += '&min=' + r.rentprice.min + '&max=' + r.rentprice.max 
    }
    else {        
        qs += '&min=' + r.price.min + '&max=' + r.price.max 
    }

    qs += '&bi=' + branch
    qs += '&l=' + _.join(r.location)     //locations

    if(jurisdiction != 'je') {
        qs += '&ml=' + r.market.local     //market local
        qs += '&mo=' + r.market.open     //market open
    }

    qs += '&b=' + r.rooms.beds      //beds
    qs += '&ba=' + r.rooms.baths    //baths


    qs += '&eo=' + r.includeOffer      //includeOffer
    qs += '&es=' + r.includeSold    //includeSold
    qs += '&el=' + r.includeLeased    //includeLeased


    if (showmap) {
        let mql = window.matchMedia('(min-width:992px) and (max-width:1100px)')

        if (mql.matches) {
            qs += '&k='
        }
        else {
            qs += '&k=' + encodeURI(r.keyword)
        }

        qs += '&map=true'
        qs += '&mapl=' + lat
        qs += '&maplg=' + long
        qs += '&mapz=' + zoom
    }
    else {
        qs += '&k=' + encodeURI(r.keyword)
        qs += '&map=false'
    }

    qs += '&f=' + _.join(r.features.types)     //features
    qs += '&cr=' + r.features.curation    //curation

    qs += '&o=' + sort    //sort


    return qs
}

export function getScrollPosition () {
    return {
        x: window.pageXOffset,
        y: window.pageYOffset
    }
}

export function setScrollPosition (Vue, position = {x: 0, y: 0}, delay) {
    if (delay) {
        setTimeout(() => {
            Vue.nextTick(() => {
                window.scrollTo(position.x, position.y)
            })
        }, delay);
    }
    else {
        Vue.nextTick(() => {
            window.scrollTo(position.x, position.y)
        })
    }
}


export function cleanHistoryList (historyList) {
    historyList.splice(0, parseInt(historyList.length/2))
}
