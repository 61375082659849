var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "qblock", class: _vm.css }, [
    _c("div", { staticClass: "qblock-copy text-center" }, [
      _c("h3", {
        directives: [{ name: "scroll-monitor", rawName: "v-scroll-monitor" }],
        staticClass: "tag-title"
      }),
      _vm._v(" "),
      _c("p", { staticClass: "qblock-title" }, [
        _vm._v(_vm._s(_vm.content.quote))
      ]),
      _vm._v(" "),
      _vm.content.author && _vm.content.author.length
        ? _c("p", { staticClass: "qblock-author" }, [
            _vm._v(_vm._s(_vm.content.author))
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }