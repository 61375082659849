var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tsblock" }, [
    _c("h2", { staticClass: "text-center" }, [_vm._v("Testimonials")]),
    _vm._v(" "),
    _c(
      "div",
      { ref: "tSlider", staticClass: "tsblock_carousel" },
      _vm._l(_vm.content.testimonials, function(item) {
        return _c("div", { staticClass: "tsblock_carousel_item" }, [
          _c("p", [_vm._v(_vm._s(item.quote))]),
          _vm._v(" "),
          _c("strong", { staticClass: "text-upper" }, [
            _vm._v(_vm._s(item.author))
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }