<template>
    <div class="statblock" :class="css">
        <div class="statblock_item" v-for="item in content.statistics">
            <strong>{{ item.title }}</strong>
            <span>{{ item.statval }}</span>
        </div>        
    </div>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        computed: {
            css() {
                if (this.content.container) {
                    return [this.content.container.classkey]
                }
                return ['mw']
            }
        },
        methods: {}
    }
</script> 