import Vue from '../config'

if(document.getElementById('favourites-vm')) {
    new Vue({
        el: '#favourites-vm',
        data: {
            properties: iPageModel,
            propertyCounter: []
        },
        computed: {            
            crumb() {
                if (this.propertyCounter && this.propertyCounter.length) {
                    if (this.propertyCounter.length == 1) return '1 favourite property'
                    else return this.propertyCounter.length + ' favourite properties'
                }

                return '0 favourite properties'
            }
        },
        methods: {
            favouriteToggled(id, state) {
                var index = this.propertyCounter.findIndex(x => x == id)
                if (index > -1) {
                    this.propertyCounter.splice(index, 1)
                }
            }
        },
        created() {
            iPageModel.forEach(e => {
                this.propertyCounter.push(e.id)
            })
        }
    })
}