import Vue from '../config'
import { EventBus } from '../app/event-bus'
var _ = require('lodash')

if (document.getElementById('header-vm')) {
    var htmlNode = document.getElementsByTagName('html')[0],
        priceArrays = {
            buymin: [],
            buymax: [],
            rentmin: [],
            rentmax: []
        }

    var headerVue = new Vue({
        el: '#header-vm',
        data: {
            branches: {
                guernsey: false,
                jersey: false
            },
            market: { local: false, open: false },
            drawer: false,
            activeMenu: 0,
            mapSearch: true,
            navigating: false,
            scrolled: false,
            wscrolled: false
        },
        computed: {
            allBranches: {
                get: function () {
                    return (!this.$data.branches.guernsey && !this.$data.branches.jersey)
                },
                set: function (val) {
                    this.$data.branches.guernsey = !val
                    this.$data.branches.jersey = !val
                }
            },
            branchName() {
                if (this.branches.guernsey && !this.branches.jersey) return 'Guernsey'
                if (this.branches.jersey && !this.branches.guernsey) return 'Jersey'
                return ''
            }
        },
        methods: {
            setMarket(local, open) {
                this.market.local = local
                this.market.open = open
            },
            setMenu(number) {
                if (this.activeMenu == number) {
                    if (this.drawer) { this.activeMenu = 0 }
                    else { this.closeMenu() }
                }
                else {
                    this.activeMenu = number
                    this.navigate()
                } 

            },
            setBurger() {
                if (this.drawer) {
                    this.activeMenu = 0
                    this.closeMenu()
                }
                else {
                    this.navigate()
                    this.drawer = true
                }
            },           
            search(query, isrent) {                
                window.location.href = (isrent ? '/rent' : '/buy') + this.getRequestUrl(query)
            },
            searchRent(bid, query) {
                this.branches.guernsey = (bid == 1 || bid == 3)
                this.branches.jersey = (bid == 2 || bid == 3)
                this.search(query, true)
            },
            getRequestUrl(query) {
                var qs = '?ts=' + new Date().getTime().toString() + '&k='
                if (typeof query != 'string') { query = '' }

                //if only guernsey, check market selection
                if (this.branches.guernsey && !this.branches.jersey) {
                    if (this.market.open) {
                        qs += '&mo=true'//market open
                    }
                    if (this.market.local) {
                        qs += '&ml=true'//market open
                    }
                }

                if (this.mapSearch) {
                    qs += '&map=true'
                }

                if (this.branches.guernsey && !this.branches.jersey) { qs += '&bi=1' }
                else if (!this.branches.guernsey && this.branches.jersey) { qs += '&bi=2' }
                else if (this.branches.guernsey && this.branches.jersey) { qs += '&bi=3' }
                else { qs += '&bi=0' }

                return qs + query
            },
            navigate() {
                this.navigating = true
                htmlNode.classList.add('fixwin')
            },
            closeMenu() {
                if (this.drawer && this.activeMenu > 0 && this.activeMenu < 3) {
                    this.activeMenu = 0
                }
                else {
                    this.drawer = false
                    this.activeMenu = 0
                    this.navigating = false
                    htmlNode.classList.remove('fixwin')
                }
            },
            setRequest(r, isrental, bid) {
                this.market.local = (r.ml === 'true')
                this.market.open = (r.mo === 'true')
                this.setBranch(bid)

                if (r.map === 'true' || r.map === true) {
                    this.mapSearch = true
                }
            },
            setBranch(bid) {
                this.branches.guernsey = (bid == 1 || bid == 3)
                this.branches.jersey = (bid == 2 || bid == 3)
            },

            handleScroll(e) {
                this.scrolled = (window.scrollY > 75);
                if (this.scrolled && !this.wscrolled) {
                    htmlNode.classList.add('wscrolled')
                    this.wscrolled = true
                }
                else if (!this.scrolled && this.wscrolled) {
                    htmlNode.classList.remove('wscrolled')
                    this.wscrolled = false
                }
            },
        },
        created() {
            this.handleDebouncedScroll = _.debounce(this.handleScroll, 100);
            //window.addEventListener('scroll', this.handleDebouncedScroll);         
            window.addEventListener('scroll', this.handleScroll);
        },
        mounted() {
            this.branches.guernsey = (this.lvBranchId == 1)
            this.branches.jersey = (this.lvBranchId == 2)
        }
    })

    EventBus.$on('searchchange', function (request, isrental, bid) {
        headerVue.setRequest(request, isrental, bid)
    })
    EventBus.$on('uibranchchange', function (bid) {
        headerVue.setBranch(bid)
    })

    //** media query events
    function menuBreakPoint(mq) {
        if (mq.matches) {
            // >= 992px
            headerVue.mapSearch = true
            headerVue.activeMenu = 0
            headerVue.closeMenu()
        }
        else {
            // < 992px
            headerVue.mapSearch = false
            headerVue.activeMenu = 0
            headerVue.closeMenu()
        }
    }

    let mqMenu = window.matchMedia("(min-width: 992px)")
    mqMenu.addListener(menuBreakPoint)
    menuBreakPoint(mqMenu)
}