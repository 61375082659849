<template>
    <div :class="css" :style="style"></div>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            }
        },        
        computed: {
            css() {
                var a = ['spcb']
                if (this.content.bordercolor) {
                    a.push(this.content.bordercolor.classkey)
                }                
                return a
            },
            style() {
                var s = {
                    paddingTop: this.content.space + 'px'
                }
                if (this.content.bordercolor && this.content.borderwidth) {
                    s.borderTopWidth = this.content.borderwidth + 'px'
                }
                return s
            }
        },
        methods: {}
    }
</script>