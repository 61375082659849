var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.css }, [
    _vm.contenttype == 1
      ? _c(
          "div",
          { staticClass: "pods", class: { centered: _vm.content.centeritems } },
          [
            _vm._l(_vm.content.grid.griditems, function(item) {
              return _c("div", { staticClass: "pods-item" }, [
                _c("a", { attrs: { href: item.link, target: "_blank" } }, [
                  _c("div", [
                    _c("i"),
                    item.image
                      ? _c("img", {
                          attrs: {
                            src: item.image.url + "?fit=crop&h=320&w=320"
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm._f("slashbreak")(item.title)))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "meta" }, [
                    _vm._v(_vm._s(_vm._f("truncate")(item.summary, 150, "...")))
                  ])
                ])
              ])
            }),
            _vm._v(" "),
            _c("div", { staticClass: "pods-item dummy" }),
            _vm._v(" "),
            _c("div", { staticClass: "pods-item dummy" }),
            _vm._v(" "),
            _c("div", { staticClass: "pods-item dummy" })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.issuuDocs.length && _vm.contenttype == 2
      ? _c(
          "div",
          { staticClass: "pubs", class: { centered: _vm.content.centeritems } },
          [
            _vm._l(_vm.issuuDocs, function(item) {
              return _c("div", { staticClass: "pubs-item" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://issuu.com/livingroomestateagents/docs/" +
                        item.document.name,
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          "http://image.issuu.com/" +
                          item.document.documentId +
                          "/jpg/page_1_thumb_large.jpg"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm._f("slashbreak")(item.document.shortTitle)))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "meta" }, [
                  _vm._v(_vm._s(item.document.shortDesc))
                ])
              ])
            }),
            _vm._v(" "),
            _c("div", { staticClass: "pubs-item dummy" }),
            _vm._v(" "),
            _c("div", { staticClass: "pubs-item dummy" }),
            _vm._v(" "),
            _c("div", { staticClass: "pubs-item dummy" })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.contenttype == 3 || _vm.contenttype == 5
      ? _c(
          "div",
          {
            staticClass: "magvid",
            class: { centered: _vm.content.centeritems }
          },
          [
            _vm._l(_vm.content.grid.griditems, function(item) {
              return _c(
                "div",
                {
                  directives: [
                    { name: "scroll-monitor", rawName: "v-scroll-monitor" }
                  ],
                  staticClass: "magvid-item"
                },
                [
                  _c("a", { attrs: { href: item.link, target: "_blank" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "image",
                        class: { video: _vm.contenttype == 5 }
                      },
                      [
                        item.image
                          ? _c("span", {
                              style:
                                "background-image:url(" +
                                item.image.url +
                                "?mode=fit&w=500)"
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.contenttype == 5
                          ? _c("i", { staticClass: "far fa-play-circle" })
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c("h4", [
                      _vm._v(_vm._s(_vm._f("slashbreak")(item.title)))
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(item.summary))])
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "magvid-item dummy" }),
            _vm._v(" "),
            _c("div", { staticClass: "magvid-item dummy" }),
            _vm._v(" "),
            _c("div", { staticClass: "magvid-item dummy" })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.contenttype == 4
      ? _c(
          "div",
          {
            staticClass: "icgrid",
            class: { centered: _vm.content.centeritems }
          },
          [
            _vm._l(_vm.content.grid.griditems, function(item) {
              return _c(
                "div",
                {
                  directives: [
                    { name: "scroll-monitor", rawName: "v-scroll-monitor" }
                  ],
                  staticClass: "icgrid-item"
                },
                [
                  _c("a", { attrs: { href: item.link, target: "_blank" } }, [
                    _c("div", { staticClass: "image" }, [
                      item.image
                        ? _c("span", {
                            style:
                              "background-image:url(" +
                              item.image.url +
                              "?mode=fit&w=500)"
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("h3", [
                      _vm._v(_vm._s(_vm._f("slashbreak")(item.title)))
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(item.summary))])
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "icgrid-item dummy" }),
            _vm._v(" "),
            _c("div", { staticClass: "icgrid-item dummy" }),
            _vm._v(" "),
            _c("div", { staticClass: "icgrid-item dummy" })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }