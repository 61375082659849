<template>
    <transition name="sideWin">
        <div ref="sideWinEl" class="side-window" v-if="active">
            <i class="close fas fa-times" v-on:click="closeWin"></i>
            <div class="side-window-wrap" v-bind:style="computedStyle">
                <div class="side-window-header">
                    <slot name="header"></slot>
                </div>
                <div class="side-window-content">
                    <slot name="content"></slot>
                </div>
                <div class="side-window-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        props: ['active', 'viewCount'],
        computed: {
            computedStyle(){
                return {
                    position: ((this.viewCount % 2) == 0) ? 'relative' : 'static'
                }
            }
        },
        methods: {
            closeWin(){
                this.$emit('close')
            }
        }
    }
</script>