<template>
    <div :class="css">    
        <div class="people">
            <div class="people-item" v-for="item in locationBios">
                <a :href="bioUrl(item)">
                    <img :src="item.profileimage.url + '?fit=crop&w=550&h=520'" />
                </a>
                <h3><a :href="bioUrl(item)">{{item.fullname}}</a></h3>
                <p>{{item.jobtitle}}</p>
            </div>
            <div class="people-item dummy"></div>
            <div class="people-item dummy"></div>
            <div class="people-item dummy"></div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data: function () {
            return {
                bios: []
            }
        },
        computed: {
            css() {
                var a = []
                if (this.content.container) {
                    a.push(this.content.container.classkey)
                }
                else {
                    a.push('mw')
                }
                return a
            },
            filtered() {
                return (this.content.location && this.content.location.tld != 'com')
            },
            locationBios() {
                if (this.bios.length) {
                    if (this.filtered) {
                        return this.bios.filter(item => item.site.tld == this.content.location.tld)
                    }
                    else {
                        return this.bios.filter(item => item.site.tld == 'gg')
                    }
                }
                return []
            }
        },
        methods: {
            loadTeam() {
                let self = this

                self.$http.post('/bio-list/', null).then(({ data }) => {
                    if (data) {
                        self.bios = data
                    }
                }, (response) => { })
            },
            bioUrl(item) {
                return '/team-bio/' + item.id + '/' + item.fullname.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')
            }
        },        
        mounted: function () {    
            this.loadTeam()
        }
    }
</script>