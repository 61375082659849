<template>
    <div class="ciblock" :class="css">
        <div class="ciblock_media">
            <div class="image-fw">
                <div class="ifni">
                    <div class="ifni-in">
                        <img :src="content.image.url + '?fit=crop&w=700&max-h=700'" />
                    </div>
                </div>
            </div>
        </div>
        <div class="ciblock_copy">
            <div class="rte" v-html="content.text"></div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        computed: {
            css() {
                var a = []
                if (this.content.container) {
                    a.push(this.content.container.classkey)
                }
                else {
                    a.push('ms')
                }
                if (this.content.imageright) {
                    a.push('reverse')
                }
                return a
            }
        },
        methods: {}
    }
</script>