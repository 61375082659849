<template>
    <div class="property-related" v-cloak>
        <div ref="propRel" class="pr-carousel">   
            <div class="prc-item" v-for="item in houses">
                <div class="column-inner pc-item">
                    <div class="pc-item-image">
                        <a v-bind:href="getUrl(item.niceUrl)" class="piia"></a>
                        <v-lazy-image v-bind:src="item.displayImage" class="df" />          
                    </div>
                    <div class="pc-item-data">
                        <a v-bind:href="getUrl(item.niceUrl)">
                            <div class="pid-n">{{item.displayName}}, <span class="pid-l">{{item.lvBranchName}}</span></div>
                            <div class="pid-m">
                                <span class="pid-br">{{ item.locationName }}, </span>
                                <span class="pid-br" v-if="item.lvBranchId == 1">{{ item.isOpen ? "Open Market" : "Local Market" }}, </span>
                                <span class="pid-bd" v-if="item.bedrooms">{{item.bedrooms}} {{ item.bedrooms == 1 ? "bed" : "beds" }}</span>
                            </div>
                            <div class="pid-p" v-if="!isSold">
                                {{ item.displayPrice }}
                                <span class="status sole" v-if="item.isSoleAgent && !isSold">Sole Agent</span>
                                <span class="status" v-bind:class="item.coallescedStatus.toLowerCase()" v-if="item.hasStatus">{{item.coallescedStatus}}</span>
                            </div>
                            <div class="pid-p nvp" v-else>
                                <span class="status" v-bind:class="item.coallescedStatus.toLowerCase()" v-if="item.hasStatus">{{item.coallescedStatus}}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</template>
<script>
    var $ = require('jquery')
    var Flickity = require('flickity')
    require('flickity-imagesloaded')

    var fState = {
        flkty: null,
        requestId:null
    }

    export default {
        props: ['houses', 'type'],
        data() {
            return {               
                ready: false,
                current: 0
            }
        },
        computed: {
            isSold() {
                return this.type == 'sold'
            }
        },
        methods: {
            getUrl(iurl) {
                if (this.type == 'sold') { return '/success-stories' }
                return iurl
            },
            carousel() {
                var self = this

                fState.flkty = new Flickity(this.$refs.propRel, {
                    percentPosition: false,
                    cellSelector: '.prc-item',
                    cellAlign: (this.houses.length > 4) ? 'center' : 'left',
                    wrapAround: (this.houses.length > 4),
                    freeScroll: (this.houses.length > 4),
                    pageDots: false,
                    prevNextButtons: false,
                    imagesLoaded: true
                })

                if (this.houses.length > 4) {
                    fState.flkty.x = 0

                    $(this.$refs.propRel).parent()
                        .on('mouseenter touchstart', self.enter)
                        .on('mouseleave touchend', self.exit)

                    this.$nextTick(() => {
                        self.play()
                    })
                }
            },            
            play(){
                var self = this

                fState.flkty.x = fState.flkty.x - 0.5
                fState.flkty.settle(fState.flkty.x);
                fState.requestId = window.requestAnimationFrame(self.play)
            },
            pause(){
                if(fState.requestId) {
                    window.cancelAnimationFrame(fState.requestId)
                    fState.requestId = null;
                }
            },
            enter(e){
                this.pause()
            },
            exit(e){
                this.play()
            }
        },
        mounted() {
            if(this.houses) {     
                this.carousel()
                //setTimeout(this.carousel, 500)
            }
        }
    }
</script>