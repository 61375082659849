<template>
    <div class="grid insta-grid" id="instagrid">
        <div v-for="(insta,i) in feed" class="column">
            <div class="insta-grid-item">
                <a :href="insta.url" target="_blank"><i class="far fa-heart"></i><i class="far fa-comment"></i></a>
                <span :style="'background-image:url(' + insta.imageUrl + ');'"></span>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        components: {
        },
        props: {
            fkey: String,
            count: Number
        },
        data: function () {
            return {
                feed: [],
            }
        },
        methods: {
        },
        computed: {
        },
        mounted: function () {
            var vm = this
            var xhr = new XMLHttpRequest()
            xhr.open('POST', 'https://nformr.co/api/insta', true)
            xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
            xhr.onload = function () {
                var feed = JSON.parse(xhr.response)
                vm.feed = feed
            }
            xhr.send(JSON.stringify({ key: vm.fkey, count: vm.count, domain: window.location.hostname }))
        }
    }
</script>