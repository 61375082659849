<template>
    <div @mouseenter="enter" @mouseleave="leave">
        <a href="#" v-bind:class="{on:isFavourite}" v-on:click.prevent="toggle">
            <svg ref="aSvg" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 288" width="100%" height="100%">
                <polygon class="back" fill="#d6bd7b" points="144,215.8 65.5,264.9 88,175.1 17,115.6 109.4,109.2 144,23.3 178.6,109.2 271,115.6 200,175.1
	            222.5,264.9 " />
                <polygon class="front" points="144,192.8 96.1,222.8 109.8,168 66.5,131.7 122.9,127.8 144,75.4 165.1,127.8 221.5,131.7 178.2,168
	            191.9,222.8 " />
            </svg>
        </a>
        <transition name="favelbl">
            <span v-if="showLabel">{{addRemove}} {{label}}</span>
        </transition>
    </div>
</template>
<script>
    import anime from 'animejs'

    export default {
    props: ['start', 'propertyId', 'label'],
        data() {
            return {                
                busy: false,
                isFavourite: false,
                hideLabel: false
            }
        },
        computed: {
            addRemove() {
                if (this.isFavourite) {
                    return 'Remove'
                }
                return 'Add'
            },
            showLabel() {
                if (this.hideLabel) { return false }
                if (this.label === undefined) { return false }
                else if (this.label && this.label.length) { return true }
                return false
            }
        },
        methods: {
            toggle() {
                let self = this
                if(!self.busy){
                    self.busy = true
                    self.aDuring.play()

                    self.$http.post('/property/save/' + self.propertyId, '').then(({ data }) => {
                        if(!data.isAuthenticated){
                            //emit login trigger
                            self.aDuring.restart()
                            self.aDuring.pause()
                            self.busy = false

                            this.$emit('prompt', {
                                rect: this.$refs.aSvg.getBoundingClientRect(),
                                message: '<strong>Save this property:</strong> please log in or register for an account to continue.',
                                links: [
                                    '<a href="/auth/login?ReturnUrl=/property-redirect/' + self.propertyId + '">Log In</a>',
                                    '<a href="/auth/register?ReturnUrl=/property-redirect/' + self.propertyId + '">Register</a>'
                                ]
                            })
                        }
                        else {
                            self.isFavourite = data.success
                            self.aDuring.pause()
                            if(self.isFavourite) { self.aIn.restart() }
                            else { self.aOut.restart() }
                            
                            setTimeout(function(){ self.busy = false }, 1000)
                        }
                    }, (response) => {})
                }
            },
            enter() {
                this.hideLabel = false
            },
            leave() {
                this.hideLabel = true
            }
        },
        created() {
            this.isFavourite = (this.start == true || this.start == 'true') ? true : false
        },
        mounted() {
            this.aDuring = anime({
                targets: [this.$refs.aSvg],
                rotateY: 360,
                duration: 1000,
                easing: 'linear',
                loop: true,
                direction: 'normal',
                autoplay:false
            })

            this.aIn = anime({
                targets: [this.$refs.aSvg],
                rotateY: [0,720],
                duration: 1000,
                easing: 'easeOutCirc',
                elasticity: 800,
                loop: false,
                direction: 'normal',
                autoplay:false
            })            
            this.aOut = anime({
                targets: [this.$refs.aSvg],
                rotateY: [720,0],
                duration: 1000,
                easing: 'easeOutCirc',
                elasticity: 800,
                loop: false,
                direction: 'normal',
                autoplay:false
            })

            this.aDuring.restart()
            this.aDuring.pause()

            var self = this
            setTimeout(function () { self.hideLabel = true }, 8000)
        }
    }
</script>