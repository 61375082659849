<template>
    <div class="igblock" :class="css">
        <div class="igblock_item" v-for="item in content.images">
            <div v-on:click="zoom(item)"><v-lazy-image :src="item.url + '?fit=crop&w=500&h=500'" /></div>
        </div>
        <div class="igblock_zoom" v-if="zoomurl && zoomurl.length" v-on:click="close">
            <div><v-lazy-image :src="zoomurl" /></div>
            <span class="close">&times;</span>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data: function () {
            return {
                zoomurl: ''
            }
        },
        computed: {
            css() {
                if (this.content.container) {
                    return [this.content.container.classkey]
                }
                return ['ms']
            }
        },
        methods: {
            zoom(pic) {
                this.zoomurl = pic.url + '?mode=fit&w=1400'
            },
            close() {
                this.zoomurl = ''
            }
        }
    }
</script>