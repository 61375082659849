import Vue from '../config'

if (document.getElementById('contact-vm')) {
    new Vue({
        el: '#contact-vm',
        data: {
            locations: locations,
            content: pageJson,
            guernsey: '',
            jersey: ''
        },
        methods: {
            openMap(num) {
                var latlang = new google.maps.LatLng(this.locations[num].lat, this.locations[num].lon) , //new google.maps.LatLng(0, 0),
                    bounds = new google.maps.LatLngBounds(),
                    mapRef = (num == 0) ? this.$refs.ctcMap1 : this.$refs.ctcMap2

                var map = new google.maps.Map(mapRef, {
                    center: latlang,
                    zoom: 12
                })

                //var p = new google.maps.LatLng(this.locations[num].lat, this.locations[num].lon)
                //bounds.extend(p)
                new google.maps.Marker({
                    position: latlang,
                    animation: google.maps.Animation.DROP,
                    map: map
                })
                //map.fitBounds(bounds)

                google.maps.event.addListener(map, "tilesloaded", function () {
                    var mself = this
                    window.setTimeout(function () {
                        mself.setZoom(15)
                    }, 1000)
                })

                //window.setTimeout(function () {
                //    var z = map.getZoom()
                //    map.setZoom(z + 3)
                //}, 1000)
            }
        },
        mounted() {
            var self = this
            this.openMap(0)
            this.openMap(1)

            if (this.content.blocks) {
                this.content.blocks.forEach(c => {
                    if (c._modelApiKey == 'keyvalue') {
                        if (c.key == 'Guernsey') { self.guernsey = c.value }
                        if (c.key == 'Jersey') { self.jersey = c.value }
                    }
                })
            }
        }
    })
}