<template>
    <div :class="css">
        <div class="ccblock" v-html="content.text"></div>
    </div>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            },
            isNews: {
                type: Boolean,
                required: false
            }
        },
        data: function () {
            return {}
        },
        computed: {
            css() {
                var a = []
                if (this.content.container) {
                    a.push(this.content.container.classkey)
                }
                else {
                    a.push('mn')
                }
                return a
            }
        },
        methods: {}
    }
</script>