import Vue from '../config.js'

if (document.getElementById('login-vm')) {
    new Vue({
        el: '#login-vm',
        data: {
            loading: false,
            errors: [],
            request: {
                email: '',
                password: '',
                returnUrl: iPageModel.returnUrl
            }
        },
        methods: {
            submit() {
                let self = this
                self.errors = []
                this.$validator.validateAll().then(function (success) {
                    if (success) {
                        self.loading = true
                        self.$http.post('/auth/login', self.request).then(({ data }) => {
                            if (data.Redirect) {
                                window.location.href = data.Redirect
                            }
                            else {
                                self.errors = data.Errors
                                self.loading = false
                            }
                        }, (response) => {
                            self.errors = ['Unable to log you in at this time, please try again shortly.']
                            self.loading = false
                        })
                    }
                })
            },
            clearErrors(){
                this.errors = []
            }
        }
    })
}