var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel", class: _vm.css },
    [
      _c("div", { staticClass: "panel_head", class: { open: _vm.open } }, [
        _c(
          "div",
          {
            on: {
              click: function($event) {
                _vm.open = !_vm.open
              }
            }
          },
          [
            _c("h3", [_vm._v(_vm._s(_vm.content.title))]),
            _vm._v(" "),
            _vm.content.intro && _vm.content.intro.length
              ? _c("p", [_vm._v(_vm._s(_vm.content.intro))])
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "text-upper more" }, [
              _vm._v("Read More")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "inacc" } }, [
        _vm.open
          ? _c("div", { staticClass: "panel_body" }, [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.content.text) } })
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }