var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ciblock", class: _vm.css }, [
    _c("div", { staticClass: "ciblock_media" }, [
      _c("div", { staticClass: "image-fw" }, [
        _c("div", { staticClass: "ifni" }, [
          _c("div", { staticClass: "ifni-in" }, [
            _c("img", {
              attrs: {
                src: _vm.content.image.url + "?fit=crop&w=700&max-h=700"
              }
            })
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ciblock_copy" }, [
      _c("div", {
        staticClass: "rte",
        domProps: { innerHTML: _vm._s(_vm.content.text) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }