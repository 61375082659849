<template>
    <div class="hciblock" :class="css">
        <div class="hciblock_copy">
            <h1>{{content.headline}}</h1>
            <div class="rte" v-html="content.text"></div>
        </div>
        <div class="hciblock_media">
            <div class="image-fw animate" v-if="hasvideo">
                <div class="ifwi" :class="{video:hasvideo}" :style="styles" v-scroll-monitor>
                    <a href="#play" class="play hidden-xs" v-on:click.prevent="play" v-if="hasvideo && !playing">Play</a>
                    <a :href="this.content.video" target="_blank" class="visible-xs play" v-if="hasvideo">Play</a>
                </div>
                <iframe :class="{ off: !playing, on: playing }" v-if="embedtype == 1" :src="embedurl" width="560" height="315" frameborder="0" allow="autoplay" allowfullscreen></iframe>
                <iframe :class="{ off: !playing, on: playing }" v-if="embedtype == 2" :src="embedurl" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
            <div class="image-fw" v-else>
                <div class="ifni">
                    <div class="ifni-in">
                        <img :src="content.image.url + '?fit=crop&w=700&max-h=700'" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                playing: false,
                embedtype: 0,
                embedurl: '',
                loadedurl: ''
            }
        },
        computed: {
            css() {
                var a = []
                if (this.content.container) {
                    a.push(this.content.container.classkey)
                }
                else {
                    a.push('mw')
                }
                return a
            },
            styles() {
                var s = {
                    backgroundImage: 'url(' + this.content.image.url + '?fit=crop&w=800)'
                }
                return s
            },
            hasvideo() {
                return this.content.video && this.content.video.length
            }
        },
        methods: {
            load() {
                var self = this

                if (self.hasvideo) {
                    self.$http.post('/utility/embedurl/', { value: self.content.video }).then(({ data }) => {
                        if (data) {
                            self.embedtype = data.id
                            self.loadedurl = data.value
                        }
                    }, (response) => { })
                }
            },
            play() {
                var self = this
                self.playing = true

                this.$nextTick(() => {
                    self.embedurl = self.loadedurl
                })
            }
        },
        mounted() {
            this.load()
        }
    }
</script>