import Vue from '../config.js'
import recaptcha from '../vue/recaptcha.vue'

if (document.getElementById('register-vm')) {
    new Vue({
        el: '#register-vm',
        components: {
            recaptcha
        },
        data: {
            loading: false,
            errors: [],
            request: {
                title: '',
                firstName: '',
                lastName: '',
                telephone: '',
                email: '',
                password: '',
                //termsAccepted: false,
                recaptcha: '',
                returnUrl: iPageModel.returnUrl,
                //new fields from combined reg and notification
                profile: iPageModel.profile
            }
        },
        computed: {
            maskedNotifications: {
                //bitmasking to checkbox list
                get: function () {
                    var arr = []
                    if ((this.request.profile.propertyNotications & 1) > 0) { arr.push(1) }
                    if ((this.request.profile.propertyNotications & 2) > 0) { arr.push(2) }
                    if ((this.request.profile.propertyNotications & 4) > 0) { arr.push(4) }
                    return arr
                },
                set: function (val) {
                    var maskInt = 0
                    val.forEach(v => {
                        maskInt += parseInt(v)
                    })
                    this.request.profile.propertyNotications = maskInt
                }
            }
        },
        methods: {
            submit() {
                let self = this
                self.clearErrors()

                this.$validator.validateAll().then(function (success) {
                    if (success) {
                        self.loading = true
                        self.$http.post('/auth/register', self.request).then(({ data }) => {
                            if (data.Redirect) {
                                window.location.href = data.Redirect
                            } else {
                                self.errors = data.Errors
                                self.loading = false
                                self.$refs.recaptcha.reset()
                            }
                        }, (response) => {
                            self.errors = ['Unable to complete registration at this time, please try again shortly.']
                            self.loading = false
                        })
                    }
                })
            },
            branchLocations(bid) {
                return this.request.profile.locations.filter(l => { return l.lvBranchId == bid })
            },
            toggleAll(bid) {
                var barr = this.branchLocations(bid).map(x => x.id),
                    intersection = barr.filter(element => !this.request.profile.userLocations.includes(element))

                if (intersection.length == 0) {
                    var diff = this.request.profile.userLocations.filter(x => !barr.includes(x))
                    this.request.profile.userLocations = diff
                }
                else if (intersection.length < barr.length) {
                    this.request.profile.userLocations.push(...intersection)
                }
                else {
                    this.request.profile.userLocations.push(...barr)
                }
            },
            clearErrors() {
                this.errors = []
            }
        },
        mounted() {
            this.request.profile.propertyNotications = 1
        }
    })
}