<template>
    <section class="cms guttered">
        <block-builder :blocks="content.blocks"></block-builder>
    </section>
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            }
        }
    }
</script>