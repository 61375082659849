var $ = require('jquery')

var footer = (function () {
    var self = this,
        ticking,
        isMobile,
        isTouchConfirmed,
        wh, f, fb, s, c

    var build = function () {
        var fEl = $('footer')

        if (fEl.length) {
            isTouchConfirmed = false
            f = fEl[0]
            c = fEl.find('.footer-wrap')[0]
            s = $('div.site')[0]

            window.addEventListener('touchstart', function onFirstTouch() {
                onTouchConfirmed()
                window.removeEventListener('touchstart', onFirstTouch, false);
            }, false);


            let mqMob = window.matchMedia("(min-width: 768px)")
            mqMob.addListener(mediaQueryChange)
            mediaQueryChange(mqMob)

            window.addEventListener('resize', function (event) {
                requestTick(false)
            }, false)
            window.addEventListener('scroll', function (event) {
                requestTick(true)
            }, false)
            
            if (!isMobile) {
                setBounds()
                setPosition()
            }
        }
    },

    mediaQueryChange = function (mq) {
        if (!isTouchConfirmed) {
            isMobile = !mq.matches
            if (isMobile) {
                s.style.marginBottom = '0px'
                c.removeAttribute('style')
            }
        }
    },

    onTouchConfirmed = function () {
        isTouchConfirmed = true
        isMobile = true
        document.body.classList.add('touch-active')
        s.style.marginBottom = '0px'
        c.removeAttribute('style')
    },

    setBounds = function () {
        fb = f.getBoundingClientRect()
        wh = document.documentElement.clientHeight
        s.style.marginBottom = fb.height + 'px'
    },

    setPosition = function () {
        var srect = s.getBoundingClientRect(),
            px = (srect.height - wh) + srect.top

        if (px < 0) {
            var percentShown = (Math.abs(px) / fb.height),
                translate = 'translate(0, ' + -(100 - (100 * percentShown)) + '%)';
            
            c.style.transform = translate
            c.style.webkitTransform = translate
            c.style.msTransform = translate
            c.style.opacity = percentShown
        }
        else {

        }
    },

    requestTick = function (isScroll) {
        if (ticking) {
            window.cancelAnimationFrame(ticking)
        }
        if (!isMobile) {
            ticking = window.requestAnimationFrame(function () {
                if (!isScroll) { setBounds() }
                setPosition()
            })
        }
    }

    $(function () {
        build()
    })
})()

module.exports = footer