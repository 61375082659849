<template>
    <div class="column" v-bind:class="{ removed: removed }">
        <div class="column-inner pc-item">
            <div class="pc-item-image" v-bind:class="{ over: isOver || isFavourite }" v-on:mouseenter="isOver = true" v-on:mouseleave="leave">
                <a v-bind:href="item.niceUrl" class="piia" target="_blank"></a>
                <v-lazy-image v-bind:src="item.displayImage" class="df" />
                <div class="fv" v-if="!preventFavourite">
                    <div class="svg" v-bind:class="{on:isFavourite}" v-on:click="toggle">
                        <svg ref="aSvg" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 288" width="100%" height="100%">
                            <polygon class="b" points="144,215.8 65.5,264.9 88,175.1 17,115.6 109.4,109.2 144,23.3 178.6,109.2 271,115.6 200,175.1 222.5,264.9 " />
                            <polygon class="f" points="144,192.8 96.1,222.8 109.8,168 66.5,131.7 122.9,127.8 144,75.4 165.1,127.8 221.5,131.7 178.2,168 191.9,222.8 " />
                        </svg>
                    </div>
                    <transition name="fvg">
                        <span v-if="isGuest"><a href="/auth/login">Log In</a> or <a href="/auth/register">Register</a></span>
                    </transition>
                </div>
                <!--<span class="ovl" v-if="item.isLeasedByUs">Leased by Livingroom</span>
                <span class="ovl" v-if="item.isSoldByUs">Sold by Livingroom</span>-->
            </div>
            <div class="pc-item-data">
                <a v-bind:href="item.niceUrl" target="_blank">
                    <div class="pid-n">{{item.displayName}}, <span class="pid-l">{{item.lvBranchName}}</span></div>
                    <div class="pid-m">
                        <span class="pid-br">{{ item.locationName }}, </span>
                        <span class="pid-br" v-if="item.lvBranchId == 1">{{ item.isOpen ? "Open Market" : "Local Market" }}, </span>
                        <span class="pid-bd" v-if="item.bedrooms">{{item.bedrooms}} {{ item.bedrooms == 1 ? "bed" : "beds" }}</span>
                        <span class="pid-ar" v-if="item.annualReturn">Est. {{item.annualReturn}}% return</span>
                    </div>
                    <div class="pid-p">
                        <span v-if="!item.isSoldVisible && !item.isLeasedVisible">{{item.displayPrice}}</span>
                        <span class="status sole" v-if="item.isSoleAgent">Sole Agent</span>
                        <span class="status" v-bind:class="[statusClass]" v-if="item.hasStatus">{{item.coallescedStatus}}{{item.isSoldByUs || item.isLeasedByUs ? ' By Livingroom' : ''}}</span>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['index', 'item'],
        data() {
            return {
                busy: false,
                isOver: false,
                isGuest: false,
                isFavourite: false,
                wasFavourite: false
            }
        },
        computed: {            
            statusClass() {
                if (this.item.hasStatus) {
                    return this.item.coallescedStatus.toLowerCase()
                }
                return ''
            },
            preventFavourite() {
                return this.item.isLeasedByUs || this.item.isSoldByUs
            },
            removed() {
                return this.wasFavourite && !this.isFavourite
            }
        },
        methods: {
            leave() {
                this.isOver = false
                this.isGuest = false
            },
            toggle() {
                let self = this
                if (!self.busy) {
                    self.busy = true

                    self.$http.post('/property/save/' + self.item.id, '').then(({ data }) => {
                        if (!data.isAuthenticated) {
                            self.isGuest = true
                        }
                        else {
                            self.isFavourite = data.success
                            self.$emit('favourite', self.item.id, self.isFavourite)
                        }
                        self.busy = false
                    }, (response) => { })
                }
            }
        },
        created() {
            this.isFavourite = this.item.isFavourite
            this.wasFavourite = this.item.isFavourite
        },
        mounted() {
            //console.log(this.item)
        }
    }
</script>