import Vue from '../config'
var _ = require('lodash')
import { getScrollPosition, setScrollPosition } from './helpers'

if (document.getElementById('account-notifications-vm')) {
    new Vue({
        el: '#account-notifications-vm',
        data: {
            loading: false,            
            errors: [],
            success: [],
            profile: iPageModel,
            wasSubscribed: false,
            branches: branches,
            slider: {
                sliderStyle: [{ "backgroundColor": "#000000" }, { "backgroundColor": "#000000" }],
                processStyle: { "backgroundColor": "#333333" }
            }
        },
        computed: {
            maskedNotifications: {
                //bitmasking to checkbox list
                get: function () {
                    var arr = []
                    if ((this.profile.propertyNotications & 1) > 0) { arr.push(1) }
                    if ((this.profile.propertyNotications & 2) > 0) { arr.push(2) }
                    if ((this.profile.propertyNotications & 4) > 0) { arr.push(4) }
                    return arr
                },
                set: function (val) {
                    var maskInt = 0
                    val.forEach(v => {
                        maskInt += parseInt(v)
                    })
                    this.profile.propertyNotications = maskInt
                }
            }
        },
        methods: {            
            saveSettings() {
                let self = this
                let profileData = _.clone(self.profile)
                self.clearMessages()
                self.loading = true

                self.$http.post('/account/notification-settings', profileData).then(({ data }) => {
                    if (data.success) {
                        setScrollPosition(Vue, { x: 0, y: 85 })
                        self.success = ['Your preferences have been saved. Please wait while we redirect you...']
                        setTimeout(function () { window.location.href = '/account' }, 4000)
                    }
                    else {
                        setScrollPosition(Vue, { x: 0, y: 85 })
                        self.errors = data.errors
                        self.loading = false
                    }
                }, (response) => {
                    self.errors = ['Unable to complete your request at this time, please try again shortly.']
                    self.loading = false
                })
            },
            clearMessages() {
                this.errors = []
                this.success = []
            },
            branchChange(bid) {
                if (bid == 1 && !this.profile.hasGuernsey) {
                    this.profile.localMarket = false
                    this.profile.openMarket = false
                    this.removeBranchLocations(bid)
                }
                else if (bid == 2 && !this.profile.hasJersey) {
                    this.profile.jerseyStatus = []
                    this.removeBranchLocations(bid)
                }
            },
            removeBranchLocations(bid) {
                var barr = this.branchLocations(bid).map(x => x.id)
                var diff = this.profile.userLocations.filter(x => !barr.includes(x))
                this.profile.userLocations = diff
            },
            branchLocations(bid) {
                return this.profile.locations.filter(l => { return l.lvBranchId == bid })
            },
            toggleAll(bid) {
                var barr = this.branchLocations(bid).map(x => x.id),
                    intersection = barr.filter(element => !this.profile.userLocations.includes(element))

                if (intersection.length == 0) {
                    var diff = this.profile.userLocations.filter(x => !barr.includes(x))
                    this.profile.userLocations = diff
                }
                else if (intersection.length < barr.length) {
                    this.profile.userLocations.push(...intersection)
                }
                else {
                    this.profile.userLocations.push(...barr)
                }
            }
        },
        mounted() {
            this.wasSubscribed = iPageModel.newsletter
        }
    })
}