var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.css }, [
    _vm.content.showcategories
      ? _c("div", { staticClass: "list__news_control" }, [
          _c("div", { staticClass: "list__news_control_loc" }, [
            _c(
              "a",
              {
                class: { active: _vm.request.location == "gg" },
                attrs: { href: "#Guernsey" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toggleLocation("gg")
                  }
                }
              },
              [_vm._v("Guernsey")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                class: { active: _vm.request.location == "je" },
                attrs: { href: "#Jersey" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toggleLocation("je")
                  }
                }
              },
              [_vm._v("Jersey")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                class: { active: _vm.request.location == "" },
                attrs: { href: "#All" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toggleLocation("")
                  }
                }
              },
              [_vm._v("All")]
            )
          ]),
          _vm._v(" "),
          this.categories.length
            ? _c(
                "div",
                { staticClass: "list__news_control_cat" },
                [
                  _c("multiselect", {
                    attrs: {
                      "track-by": "name",
                      label: "name",
                      placeholder: "Category Filters",
                      options: _vm.categories,
                      searchable: false
                    },
                    on: { input: _vm.categoryChange },
                    model: {
                      value: _vm.selectedCategory,
                      callback: function($$v) {
                        _vm.selectedCategory = $$v
                      },
                      expression: "selectedCategory"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.checkMore,
            expression: "checkMore"
          }
        ],
        staticClass: "list__news",
        attrs: {
          "infinite-scroll-disabled": "scrollDisabled",
          "infinite-scroll-distance": "10"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "wait-throbber",
            class: { active: _vm.busy, scrolled: _vm.scrolled }
          },
          [_c("i"), _c("i"), _c("i"), _c("i")]
        ),
        _vm._v(" "),
        _vm._l(_vm.articles, function(item) {
          return _c("div", { staticClass: "list__news_item" }, [
            _c(
              "a",
              { attrs: { href: "/news/" + item.slug } },
              [
                _c("v-lazy-image", {
                  attrs: {
                    src: item.thumbnail.url + "?fit=crop&w=600&h=450",
                    "src-placeholder":
                      'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 450"%3E%3C/svg%3E'
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "category" }, [
                  _vm._v(_vm._s(item.category.name) + " "),
                  item.site.tld != "com"
                    ? _c("span", [_vm._v("• " + _vm._s(item.site.name))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("h4", [_vm._v(_vm._s(item.title))]),
                _vm._v(" "),
                _c("p", { staticClass: "desc" }, [
                  _vm._v(_vm._s(_vm._f("truncate")(item.subTitle, 100, "...")))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "meta" }, [
                  _vm._v(_vm._s(item.dateFormat))
                ])
              ],
              1
            )
          ])
        }),
        _vm._v(" "),
        _c("div", { staticClass: "list__news_item dummy" }),
        _vm._v(" "),
        _c("div", { staticClass: "list__news_item dummy" })
      ],
      2
    ),
    _vm._v(" "),
    _vm.articles.length == 0 && !_vm.busy
      ? _c("div", { staticClass: "list__news_none" }, [
          _c("p", [_vm._v("No articles were found that match your search.")])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }