import Vue from '../config'
var _ = require('lodash')
import { getScrollPosition, setScrollPosition } from './helpers'

if(document.getElementById('account-tvm-vm')) {
    new Vue({
        el: '#account-tvm-vm',
        data: {
            loading: false,            
            errors: [],
            success: [],
            notes: iPageModel,
            editor: {
                current: null,
                id: 0,
                title: '',
                content: '',
                editing: false,
                propertyId: iPageModel.propertyId
            },
            analytics: null,
            analyticsLoading: true
        },
        computed: {
            noteCount(){
                var counts = {
                    read: 0,
                    unread:0
                }

                if(this.notes && this.notes.length){
                    var nr = _.filter(this.notes, function(o){ return o.isRead })
                    counts.read = nr.length
                    counts.unread = this.notes.length - nr.length
                }

                return counts
            }
        },
        methods: {
            saveMessage(){
                let self = this
                self.clearMessages()

                this.$validator.validateAll().then(function (success) {
                    if (success) {
                        var postModel = {
                            propertyId: self.editor.propertyId,
                            replyTo: self.editor.id,
                            replyTitle: (self.editor.current) ? self.editor.current.title : '',
                            title: self.editor.title,
                            content: self.editor.content
                        }

                        self.loading = true
                        self.$http.post('/account/tvm/send', postModel).then(({ data }) => {
                            if (data.success) {
                                self.notes=data.notes
                                self.cancelEdit()
                            }
                            else {
                                self.errors = data.errors
                            }
                            self.loading = false
                        }, (response) => {
                            self.errors = ['Unable to complete your request at this time, please try again shortly.']
                            self.loading = false
                        })
                    }
                })
            },
            editNow(noteId){
                this.clearMessages()
                this.editor.current = null
                this.editor.id = 0

                if(noteId > 0) {       
                    let noteIndex = this.notes.findIndex(i => {
                        return i.id == noteId
                    })
                    
                    if(noteIndex > -1){
                        //if not read, record read
                        if(!this.notes[noteIndex].isRead){
                            var self = this
                            this.notes[noteIndex].isRead = true
                            self.$http.post('/account/tvm/read/' + noteId, '').then(({ data }) => {
                                //do nothing
                            }, (response) => {})
                        }
                        this.editor.current = this.notes[noteIndex]
                        this.editor.id = noteId
                    }
                }
                this.editor.title = ''
                this.editor.content = ''
                this.editor.editing = true
                this.$scrollTo(this.$refs.notesTop, 500, {
                    offset: -20
                })
            },
            cancelEdit(){
                this.editor.current = null
                this.editor.id = 0
                this.editor.title = ''
                this.editor.content = ''
                this.editor.editing = false
                this.$scrollTo(this.$refs.notesTop, 500, {
                    offset: -20
                })
            },
            clearMessages(){
                this.errors = []
                this.success = []
            },
            loadAnalytics() {
                var self = this
                self.analyticsLoading = true
                self.$http.post('/account/tvm/' + iPageModel.propertyId + '/analytics', {}).then(({ data }) => {
                    self.analytics = data
                    self.analyticsLoading = false
                    am4core.ready(function () {
                        am4core.useTheme(am4themes_kelly)
                        am4core.useTheme(am4themes_animated)
                        var chart = am4core.create("linechart", am4charts.XYChart)
                        chart.paddingRight = 70
                        chart.data = self.analytics.dailyVisitors
                        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd"
                        var dateAxis = chart.xAxes.push(new am4charts.DateAxis())
                        dateAxis.title.text = "DATE"
                        dateAxis.title.bold = true
                        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
                        valueAxis.title.text = "VISITORS"
                        valueAxis.title.bold = true
                        valueAxis.min = 0
                        valueAxis.maxPrecision = 0
                        var series = chart.series.push(new am4charts.LineSeries())
                        series.dataFields.valueY = "value"
                        series.dataFields.dateX = "date"
                        series.tooltipText = "{value} visitor(s)"
                        series.strokeWidth = 2
                        series.minBulletDistance = 15
                        series.strokeWidth = 2
                        series.tensionX = 0.77
                        var range = valueAxis.createSeriesRange(series)
                        range.value = 10  // what # of visitors should the line change colour?
                        range.endValue = 10000
                        range.contents.stroke = am4core.color("#FF0000")
                        range.contents.fill = range.contents.stroke
                        chart.cursor = new am4charts.XYCursor()

                        var chart = am4core.create("piechart", am4charts.PieChart)
                        chart.data = [{
                            "type": "New",
                            "percent": self.analytics.newPercentage
                        },{
                            "type": "Returning",
                            "percent": self.analytics.returningPercentage,
                        },]
                        chart.innerRadius = am4core.percent(50)
                        var pieSeries = chart.series.push(new am4charts.PieSeries())
                        pieSeries.dataFields.value = "percent"
                        pieSeries.dataFields.category = "type"
                        pieSeries.slices.template.stroke = am4core.color("#fff")
                        pieSeries.slices.template.strokeWidth = 2
                        pieSeries.slices.template.strokeOpacity = 1
                        pieSeries.colors.list = [
                            am4core.color("#e57d72"),
                            am4core.color("#e19466")
                        ];
                        pieSeries.hiddenState.properties.opacity = 1
                        pieSeries.hiddenState.properties.endAngle = -90
                        pieSeries.hiddenState.properties.startAngle = -90
                    })
                }, (response) => { })
            }
        },
        mounted() {
            this.loadAnalytics()       
        }
    })
}