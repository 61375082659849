var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "sideWin" } }, [
    _vm.active
      ? _c("div", { ref: "sideWinEl", staticClass: "side-window" }, [
          _c("i", {
            staticClass: "close fas fa-times",
            on: { click: _vm.closeWin }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "side-window-wrap", style: _vm.computedStyle },
            [
              _c(
                "div",
                { staticClass: "side-window-header" },
                [_vm._t("header")],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "side-window-content" },
                [_vm._t("content")],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "side-window-footer" },
                [_vm._t("footer")],
                2
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }