import Vue from '../config.js'

if (document.getElementById('reset-vm')) {
    new Vue({
        el: '#reset-vm',
        data: {
            loading: false,
            errors: [],
            success: [],
            request: {
                password: '',
                confirmPassword: '',
                resetKey: iPageModel.resetKey
            }
        },
        methods: {
            submit() {
                let self = this
                self.errors = []
                this.$validator.validateAll().then(function (success) {
                    if (success) {
                        self.loading = true
                        self.$http.post('/auth/reset', self.request).then(({ data }) => {
                            if (data.success) {
                                self.success = ['Your password has been reset. Please <a href="/auth/login">log in now</a> with your new password.']
                            }
                            else {
                                self.errors = data.errors
                                self.loading = false
                            }
                        }, (response) => {
                            self.errors = ['Unable to reset password at this time, please try again shortly.']
                            self.loading = false
                        })
                    }
                })
            },
            clearMessages(){
                this.errors = []
            }
        }
    })
}