var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagesblock", class: _vm.css },
    [
      _vm._l(_vm.content.pages, function(item) {
        return _c("div", { staticClass: "pagesblock_item" }, [
          _c("a", { attrs: { href: _vm.getUrl(item) } }, [
            _c("img", {
              attrs: { src: item.pageimage.url + "?fit=crop&w=600&h=400" }
            }),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(item.title))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(item.summary))]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "btn", class: { big: _vm.content.columns == 2 } },
              [_vm._v("Read More")]
            )
          ])
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "pagesblock_item dummy" }),
      _vm._v(" "),
      _c("div", { staticClass: "pagesblock_item dummy" }),
      _vm._v(" "),
      _c("div", { staticClass: "pagesblock_item dummy" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }