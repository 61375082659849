<template>
    <div class="column">
        <div class="column-inner pc-item">
            <div class="pc-item-image">
                <v-lazy-image v-bind:src="item.displayImage" class="df" />                
            </div>
        </div>
        <div class="pc-item-data">
            <div class="pid-n">{{item.displayName}}, <span class="pid-l">{{item.lvBranchName}}</span></div>
            <div class="pid-p success">
                <span class="status sold" v-if="item.isSoldByUs">Sold</span>
                <span class="status let" v-if="item.isLeasedByUs">Let</span>
                <span>by Livingroom</span>                
            </div>           
        </div>
    </div>
</template>
<script>
    export default {
        props: ['item', 'index', 'state'],
        data() {
            return {}
        },
        computed: {},
        methods: {}        
    }
</script>