var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "svg-cover-loader" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "svg",
      {
        ref: "aSvg",
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "100%",
          height: "100%",
          viewBox: "0 0 400 280",
          preserveAspectRatio: "xMidYMid slice"
        }
      },
      [
        _c("defs", [
          _c("mask", { attrs: { id: "ringMask" } }, [
            _c("polygon", {
              attrs: { fill: "white", points: "0 280,400 280,400 0,0 0" }
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "sa2",
              staticStyle: { opacity: "1" },
              attrs: {
                fill: "black",
                d:
                  "M199.6,105.9c-18.7,0-33.9,15.2-33.9,33.9c0,18.7,15.2,33.9,33.9,33.9c18.7,0,33.9-15.2,33.9-33.9\n\t                        C233.4,121,218.3,105.9,199.6,105.9 M200,167.9c-15.6,0-28.2-12.6-28.2-28.2c0-15.6,12.6-28.2,28.2-28.2c15.6,0,28.2,12.6,28.2,28.2\n\t                        C228.2,155.3,215.5,167.9,200,167.9"
              }
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "sa3",
              staticStyle: { opacity: "0.5" },
              attrs: {
                fill: "black",
                d:
                  "M199.7,117.5c-12.5,0-22.6,10.1-22.6,22.6s10.1,22.6,22.6,22.6c12.5,0,22.6-10.1,22.6-22.6\n\t                        S212.1,117.5,199.7,117.5 M199.8,156.2c-9.3,0-16.9-7.6-16.9-16.9c0-9.3,7.6-16.9,16.9-16.9c9.3,0,16.9,7.6,16.9,16.9\n\t                        C216.7,148.6,209.1,156.2,199.8,156.2"
              }
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "sa4",
              staticStyle: { opacity: "0" },
              attrs: {
                fill: "black",
                d:
                  "M200,128.5c-6.2,0-11.3,5.1-11.3,11.3c0,6.2,5.1,11.3,11.3,11.3c6.2,0,11.3-5.1,11.3-11.3\n\t                        C211.3,133.6,206.2,128.5,200,128.5"
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("polygon", {
          staticClass: "under",
          attrs: { mask: "url(#ringMask)", points: "0 280,400 280,400 0,0 0" }
        }),
        _vm._v(" "),
        _vm.preloading
          ? _c("polygon", {
              staticClass: "under",
              attrs: { points: "0 280,400 280,400 0,0 0" }
            })
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _vm.preloading
      ? _c("span", { staticClass: "ripple" }, [_c("em")])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("img", { attrs: { src: "/public/img/spacer.gif" } })])
  }
]
render._withStripped = true

export { render, staticRenderFns }