var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { on: { mouseenter: _vm.enter, mouseleave: _vm.leave } },
    [
      _c(
        "a",
        {
          class: { on: _vm.isFavourite },
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.toggle.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "svg",
            {
              ref: "aSvg",
              attrs: {
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 288 288",
                width: "100%",
                height: "100%"
              }
            },
            [
              _c("polygon", {
                staticClass: "back",
                attrs: {
                  fill: "#d6bd7b",
                  points:
                    "144,215.8 65.5,264.9 88,175.1 17,115.6 109.4,109.2 144,23.3 178.6,109.2 271,115.6 200,175.1\n\t            222.5,264.9 "
                }
              }),
              _vm._v(" "),
              _c("polygon", {
                staticClass: "front",
                attrs: {
                  points:
                    "144,192.8 96.1,222.8 109.8,168 66.5,131.7 122.9,127.8 144,75.4 165.1,127.8 221.5,131.7 178.2,168\n\t            191.9,222.8 "
                }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "favelbl" } }, [
        _vm.showLabel
          ? _c("span", [
              _vm._v(_vm._s(_vm.addRemove) + " " + _vm._s(_vm.label))
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }