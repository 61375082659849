<template>
    <div class="filter-vue" v-bind:class="[typeClass, cssClass, responsiveClass]">
        <div class="filter-vue-button" v-on:click.prevent="toggleOpen()">{{buttonText}}</div>
        <div class="filter-vue-window">
            <div class="filter-vue-content">
                <i class="fas fa-times filter-vue-close" v-on:click.prevent="toggleOpen()"></i>
                <div class="fvw-intro" v-if="!isLocation && !isFeatures">{{filter.text}}</div>
                <div class="fvw-selected" v-if="filter.type == 'price' || filter.type == 'rentprice'">{{summary}}</div>

                <vue-slider ref="priceSlider" v-if="filter.type == 'price' || filter.type == 'rentprice'"
                            v-model="filter.value"
                            v-bind:data="filter.data"
                            v-bind:marks="markers"
                            v-bind:enable-cross="false"
                            v-bind:dot-size="32"
                            v-bind:dot-style="slider.dotStyle"></vue-slider>

                <div class="fvw-locs" v-if="isLocation">
                    <div>
                        <div class="fvw-locs-hdr">
                            <label class="l__chk"><input type="checkbox" v-model="branches.guernsey" @change="checkLocations(1, $event)" /> <span class="check"></span>Guernsey</label>
                        </div>
                        <ul class="filter-vue-list" :class="{active:branches.guernsey}">
                            <li v-for="item in branchLocations(1)">
                                <label class="l__chk"><input type="checkbox" v-bind:value="item.id" v-bind:id="'location' + item.id" v-model="filter.value" /> <span class="check"></span>{{item.name}}</label>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div class="fvw-locs-hdr">
                            <label class="l__chk"><input type="checkbox" v-model="branches.jersey" @change="checkLocations(2, $event)" /> <span class="check"></span>Jersey</label>
                        </div>
                        <ul class="filter-vue-list" :class="{active:branches.jersey}">
                            <li v-for="item in branchLocations(2)">
                                <label class="l__chk"><input type="checkbox" v-bind:value="item.id" v-bind:id="'location' + item.id" v-model="filter.value" /> <span class="check"></span>{{item.name}}</label>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="fvw-locs" v-if="isFeatures">
                    <div>
                        <div class="fvw-locs-hdr">
                            <label>Features</label>
                        </div>
                        <ul class="filter-vue-list active">
                            <li v-for="item in filter.data.types">
                                <label class="l__chk"><input type="checkbox" v-bind:value="item.number" v-bind:id="'feature' + item.id" v-model="filter.value.types" /> <span class="check"></span>{{item.name}}</label>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div class="fvw-locs-hdr">
                            <label>Collections</label>
                        </div>
                        <ul class="filter-vue-list active">
                            <li v-for="item in filter.data.curations">
                                <label class="l__chk"><input type="radio" v-bind:value="item.number" v-bind:id="'curation' + item.id" v-model="filter.value.curation" /> <span class="radio"></span>{{item.name}}</label>
                            </li>
                            <transition name="basicfade">
                                <li v-if="filter.value.curation > 0">
                                    <label class="l__chk"><input type="radio" v-bind:value="0" id="curation0" v-model="filter.value.curation" /> <span class="radio"></span> No Collection</label>
                                </li>
                            </transition>
                        </ul>
                    </div>
                </div>

                <!--<ul class="filter-vue-list" v-if="filter.type == 'features'">
                    <li v-for="item in filter.data">
                        <label class="l__chk"><input type="checkbox" v-bind:value="item.number" v-bind:id="'feature' + item.id" v-model="filter.value" /> <span class="check"></span>{{item.name}}</label>
                    </li>
                </ul>-->

                <div class="fvw-flex-control" v-if="filter.type == 'market'">
                    <div class="left">
                        <strong>Local Market</strong>
                        Available to buyers with a local housing license.
                    </div>
                    <div class="right">
                        <toggle-button v-model="filter.value.local" v-bind:labels="{checked: 'On', unchecked: ''}" v-bind:sync="true"
                                       v-bind:width="60" v-bind:height="30" v-bind:color="{checked: '#d6bd7b', unchecked: '#D9DFE1'}" />
                    </div>
                    <div class="left">
                        <strong>Open Market</strong>
                        Available to all buyers.
                    </div>
                    <div class="right">
                        <toggle-button v-model="filter.value.open" v-bind:labels="{checked: 'On', unchecked: ''}" v-bind:sync="true"
                                       v-bind:width="60" v-bind:height="30" v-bind:color="{checked: '#d6bd7b', unchecked: '#D9DFE1'}" />
                    </div>
                </div>

                <div class="fvw-flex-control wide" v-if="filter.type == 'rooms'">
                    <div class="left">
                        <strong>Bedrooms</strong>
                        {{filter.value[0]}} or more bedrooms.
                    </div>
                    <div class="right">
                        <numbers-vue v-model="filter.value[0]" v-bind:min="0" v-bind:max="6" v-bind:step="1"
                                     input-class="vue-numbers-input" button-class="vue-numbers-button" v-bind:integer-only="true" />
                    </div>
                    <div class="left">
                        <strong>Bathrooms</strong>
                        {{filter.value[1]}} or more bathrooms.
                    </div>
                    <div class="right">
                        <numbers-vue v-model="filter.value[1]" v-bind:min="0" v-bind:max="6" v-bind:step="1"
                                     input-class="vue-numbers-input" button-class="vue-numbers-button" v-bind:integer-only="true" />
                    </div>
                </div>

            </div>
            <div class="fvw-controls">
                <button type="button" class="cancel" v-on:click.prevent="clear()">Clear</button>
                <button type="button" v-on:click.prevent="close()">Apply</button>
            </div>
        </div>
    </div>
</template>
<script>
    var _ = require('lodash')

    export default {
        props: ['filter', 'active', 'hideAt'],
        data() {
            return {
                loading: false,
                isActive: false,
                isLocation: (this.filter.type == 'location'),
                isFeatures: (this.filter.type == 'features'),
                typeClass: '',
                buttonText: '',
                startValue: null,
                lastValue: null,
                branches: {
                    guernsey: false,
                    jersey: false
                },
                startBranch: 0,
                slider: {
                    dotStyle: { "backgroundColor": "#FBF8F1" }
                }
            }
        },
        computed: {
            isOpen() {
                var opened = (this._uid == this.active.current)

                if (!opened && (this.active.last == this._uid)) {
                    this.applyChanges()
                }

                return opened
            },
            hasLocation() {
                return (this.isLocation && (this.branches.guernsey || this.branches.jersey))
            },
            markers() {
                if (this.filter.type == 'price') {
                    return {
                        '200K': '200K',
                        '400K': '400K',
                        '600K': '600K',
                        '800K': '800K',
                        '1M': '1M',
                        '3M': '3M'
                    }
                }
                else if (this.filter.type == 'rentprice') {
                    return {
                        '1200': '1.2K',
                        '2K': '2K',
                        '4K': '4K',
                        '6K': '6K',
                        '8K': '8K',
                        '10K': '10K'
                    }
                }
            },
            responsiveClass() {
                if (this.hideAt == 768) {
                    return 'hidden-xs'
                }
                else if (this.hideAt == 480) {
                    return 'hidden-xxs'
                }

                return ''
            },
            cssClass() {
                return {
                    'open': this.isOpen,
                    'active': (this.isOpen || this.isActive || this.hasLocation)
                }
            },
            summary() {
                if (this.filter.type == 'price' || this.filter.type == 'rentprice') {
                    var low = this.filter.value[0],
                        high = this.filter.value[1],
                        //low = this.filter.value[0].split('|')[0],
                        //high = this.filter.value[1].split('|')[0],
                        nLow = low, nHigh = high

                    if (low == 'No Minimum') { nLow = '£0' }
                    else { nLow = '£' + low }

                    if (high == 'No Maximum') { nHigh = 'Max' }
                    else { nHigh = '£' + high }

                    return (nLow + ' - ' + nHigh)
                } else if (this.filter.type == 'location') {
                    if (this.branches.guernsey && !this.branches.jersey) { return 'Guernsey' + (this.filter.value.length > 0 ? ' • ' + this.filter.value.length : '') }
                    else if (this.branches.jersey && !this.branches.guernsey) { return 'Jersey' + (this.filter.value.length > 0 ? ' • ' + this.filter.value.length : '') }
                    else if (this.branches.jersey && this.branches.guernsey) { return this.filter.title + (this.filter.value.length > 0 ? ' • ' + this.filter.value.length : '') }
                    return this.filter.title + ' • ' + this.filter.value.length
                } else if (this.filter.type == 'features') {
                    return this.filter.title + ' • ' + (this.filter.value.types.length + (this.filter.value.curation > 0 ? 1 : 0))
                } else if (this.filter.type == 'market') {
                    if (this.filter.value.local == this.filter.value.open) return 'Market • 2'
                    else if (this.filter.value.local) return 'Local'
                    else if (this.filter.value.open) return 'Open'
                } else if (this.filter.type == 'rooms') {
                    if (this.filter.value[0] && this.filter.value[1]) return this.filter.value[0] + ' Bed • ' + this.filter.value[1] + ' Bath '
                    else if (this.filter.value[0]) return this.filter.value[0] + ' Bed'
                    else if (this.filter.value[1]) return this.filter.value[1] + ' Bath'
                }
                return ''
            },
            branchId() {
                if (this.branches.guernsey && this.branches.jersey) { return 3 }
                else if (this.branches.guernsey && !this.branches.jersey) { return 1 }
                else if (!this.branches.guernsey && this.branches.jersey) { return 2 }
                return 0
            }
        },
        methods: {
            toggleOpen() {
                if (this.isOpen) { this.close() }
                else {
                    this.$emit('toggled', this._uid)

                    if (this.isLocation) {
                        this.presetLocations()
                    }
                }
            },
            clear() {
                this.filter.value = _.clone(this.startValue)
                this.$nextTick(() => this.close())
            },
            close() {
                this.$emit('toggled', null)
            },
            applyChanges() {                
                this.isActive = !(JSON.stringify(this.filter.value) == JSON.stringify(this.startValue))
                this.ensureButton()
                //only emit if changed to prevent infinite loop with page.js
                var strFV = JSON.stringify(this.filter.value)
                var branchChanged = (this.isLocation) ? !(this.startBranch == this.branchId) : false                
                
                if (this.lastValue != strFV || branchChanged) {
                    this.lastValue = strFV
                    this.startBranch = this.branchId
                    this.$emit('changed', this.filter.type, this.branchId)
                }
            },
            ensureValues() {                
                this.isActive = !(JSON.stringify(this.filter.value) == JSON.stringify(this.startValue))
                this.ensureButton()
                this.lastValue = JSON.stringify(this.filter.value)
            },
            ensureButton() {
                this.buttonText = this.isActive ? this.summary : this.filter.title
                if (this.isLocation && !this.isActive && this.hasLocation) {
                    this.buttonText = this.summary
                }
            },
            ensureBranch(bid) {
                this.branches.guernsey = (bid == 1 || bid == 3)
                this.branches.jersey = (bid == 2 || bid == 3)
                this.ensureButton()
                this.startBranch = bid           
            },
            clearAndApply() {
                this.filter.value = _.clone(this.startValue)
                this.applyChanges()
            },
            branchLocations(bid) {
                return this.filter.data.filter(l => l.lvBranchId == bid)
            },
            checkLocations(bid, e) {
                var bidarr = this.branchLocations(bid).map(l => l.id)
                if (!e.target.checked) {
                    //clear all parishes for branch
                    var valarr = this.filter.value.filter(function (val) {
                        return (bidarr.indexOf(val) == -1 ? true : false)
                    })
                    this.filter.value = valarr
                } else {
                    //add all parishes for branch
                    var valarr = this.filter.value.concat(bidarr)
                    this.filter.value = valarr
                }
            },
            presetLocations() {
                if (this.filter.value.length == 0) {
                    if (this.branches.guernsey) {
                        this.checkLocations(1, { target: { checked: true } })
                    }
                    if (this.branches.jersey) {
                        this.checkLocations(2, { target: { checked: true } })
                    }
                }               
            }
        },
        created () {
            this.typeClass = this.filter.type
            this.buttonText = this.filter.title
            this.lastValue = JSON.stringify(this.filter.value)
            this.startValue = _.clone(this.filter.value)
        },
        mounted() {}
    }
</script>