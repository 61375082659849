<template>
    <div class="ddl-vue" v-bind:class="{ open: isOpen }">
        <span class="ddl-vue-selected" v-bind:class="{ hasicon:icon }" v-on:click="toggle()"><i v-if="icon" v-bind:class="icon" /> {{selectedText}}</span>
        <ul>
            <li v-for="item in items" v-on:click="select(item.id)">{{item.name}}</li>
        </ul>
    </div>
</template>
<script>
    export default {
        props: ['items', 'active', 'selected', 'defaultName', 'overrideName', 'icon'],
        data() {
            return {
                defaultTitle: 'Select an Option',
                hasChanged: false
            }
        },
        computed: {
            isOpen() {
                return (this._uid == this.active.current)
            },
            selectedText() {
                let self = this
                let selIndex = this.items.findIndex(i => {
                    return i.id == self.selected
                })

                if (selIndex == 0 && this.overrideName && !this.hasChanged) {
                    return this.overrideName
                }

                if(selIndex > -1){
                    return this.items[selIndex].name
                }

                return this.defaultTitle
            }
        },
        methods: {
            select(itemId) {
                this.hasChanged = true
                this.$emit('change', itemId)
                this.toggle()
            },
            toggle(){
                if(this.isOpen) { this.$emit('toggled', null) }
                else { this.$emit('toggled', this._uid) }                
            }  
        },
        created(){
            if(this.defaultName){
                this.defaultTitle = this.defaultName
            }
        }
    }
</script>