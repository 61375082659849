var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hciblock", class: _vm.css }, [
    _c("div", { staticClass: "hciblock_copy" }, [
      _c("h1", [_vm._v(_vm._s(_vm.content.headline))]),
      _vm._v(" "),
      _c("div", {
        staticClass: "rte",
        domProps: { innerHTML: _vm._s(_vm.content.text) }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "hciblock_media" }, [
      _vm.hasvideo
        ? _c("div", { staticClass: "image-fw animate" }, [
            _c(
              "div",
              {
                directives: [
                  { name: "scroll-monitor", rawName: "v-scroll-monitor" }
                ],
                staticClass: "ifwi",
                class: { video: _vm.hasvideo },
                style: _vm.styles
              },
              [
                _vm.hasvideo && !_vm.playing
                  ? _c(
                      "a",
                      {
                        staticClass: "play hidden-xs",
                        attrs: { href: "#play" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.play.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Play")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasvideo
                  ? _c(
                      "a",
                      {
                        staticClass: "visible-xs play",
                        attrs: { href: this.content.video, target: "_blank" }
                      },
                      [_vm._v("Play")]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _vm.embedtype == 1
              ? _c("iframe", {
                  class: { off: !_vm.playing, on: _vm.playing },
                  attrs: {
                    src: _vm.embedurl,
                    width: "560",
                    height: "315",
                    frameborder: "0",
                    allow: "autoplay",
                    allowfullscreen: ""
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.embedtype == 2
              ? _c("iframe", {
                  class: { off: !_vm.playing, on: _vm.playing },
                  attrs: {
                    src: _vm.embedurl,
                    width: "640",
                    height: "360",
                    frameborder: "0",
                    webkitallowfullscreen: "",
                    mozallowfullscreen: "",
                    allowfullscreen: ""
                  }
                })
              : _vm._e()
          ])
        : _c("div", { staticClass: "image-fw" }, [
            _c("div", { staticClass: "ifni" }, [
              _c("div", { staticClass: "ifni-in" }, [
                _c("img", {
                  attrs: {
                    src: _vm.content.image.url + "?fit=crop&w=700&max-h=700"
                  }
                })
              ])
            ])
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }