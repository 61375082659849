import Vue from '../config.js'

if (document.getElementById('forgot-vm')) {
    new Vue({
        el: '#forgot-vm',
        data: {
            loading: false,
            errors: [],
            success: [],
            request: {
                email: ''
            }
        },
        methods: {
            submit() {
                let self = this
                self.errors = []
                this.$validator.validateAll().then(function (success) {
                    if (success) {
                        self.loading = true
                        self.$http.post('/auth/forgot', self.request).then(({ data }) => {
                            if (data.success) {
                                self.success = ['We have sent you a password reset email. Please check your email in a few moments and use the link supplied.']
                            }
                            else {
                                self.errors = data.errors
                                self.loading = false
                            }
                        }, (response) => {
                            self.errors = ['Unable to create password reset at this time, please try again.']
                            self.loading = false
                        })
                    }
                })
            },
            clearMessages(){
                this.errors = []
            }
        }
    })
}