import Vue from '../config'
var _ = require('lodash')

import { getScrollPosition, setScrollPosition } from './helpers'

if (document.getElementById('success-stories-vm')) {
    new Vue({
        el: '#success-stories-vm',
        data: {
            ready: false,                  
            properties: [],
            lvBranch: 0
        },
        computed: {
            showWait() {
                return !this.ready
            },
            noResults() {
                return this.ready && !this.properties.length
            },
            filteredProperties() {
                var vm = this
                return vm.lvBranch == 0 ? vm.properties : vm.properties.filter(p => p.lvBranchId == vm.lvBranch)
            }
        },
        methods: {
            load() {
                if (!this.ready) {
                    let self = this
                    let posY = getScrollPosition().y

                    if (posY > 180) {
                        setScrollPosition(Vue, { x: 0, y: 180 })
                    }

                    self.$http.post('/success-stories/', null).then(({ data }) => {
                        if (data && data.length) {
                            self.properties = data
                        }
                        self.$nextTick(() => {
                            setTimeout(function () { self.ready = true }, 500)
                        })
                    }, (response) => {
                        self.ready = true
                    })
                }
            },
            setBranch(bid) {
                let self = this
                self.ready = false
                self.lvBranch = bid
                setTimeout(function () { self.ready = true }, 500)
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.load()
            })
        }
    })
}