<template>
    <a ref="aSbtn" class="sbutton" v-bind:class="{over:hover}" href="#" v-on:click.prevent="clicked" v-scroll-monitor.emit>
        <svg viewBox="0 0 200 50" preserveAspectRatio="none">
            <path class="afill" d="M200,50H0V0h200V50z" />
            <path class="astroke" d="M200,50H0V0h200V50z" />
            <path class="astroke a1" d="M200,50H0V0h200V50z" />
        </svg>        
        <span><slot></slot></span>
    </a>
</template>
<script>    
    //SPA: handle ondestroy, teardown bindings, delete anims?
    var $ = require('jquery')
    import anime from 'animejs'

    export default {
        props: ['url', 'emit'],
        data() {
            return {
                revealed:false,
                loadReveal: false,
                hover:false
            }
        },
        computed: {},
        methods: {
            clicked(e){
                if(this.emit) {
                    this.$emit('go', e)
                }
                else {
                    window.location.href = this.url
                }
            },
            reveal(e){
                if(!this.revealed){
                    this.revealed = true
                    this.animReveal.play()
                }
            },
            over(e){
                this.hover = true
            },
            out(e){
                this.hover = false
            }
        },
        created() {        
            var self = this

            this.$on('scrolled', function(e){
                if(!self.loadReveal){   
                    self.loadReveal = (e.isFullyInViewport || e.isInViewport)
                }
                else if(!self.revealed && (e.isFullyInViewport || e.isInViewport)){
                    self.reveal()
                }
            })
        },
        mounted(){
            var a1 = this.$refs.aSbtn.getElementsByClassName('a1')

            this.anim2El = this.$refs.aSbtn.getElementsByClassName('a2')[0]

            this.animReveal = anime({ 
                targets: a1,
                strokeDasharray: {
                    value: '250 0',
                    easing: 'easeInOutExpo',   
                    duration: 1600     
                },
                stroke: {
                    value: '#d6bd7b', 
                    easing: 'linear',
                    duration: 1200,   
                },
                autoplay:false,
                loop: false
            })


            //this.animReveal = anime.timeline({
            //    autoplay:false,
            //    loop: false
            //})

            //this.animReveal.add({
            //    targets: a1,
            //    strokeDasharray: {
            //        value: '250 0',
            //        easing: 'easeOutExpo',   
            //        duration: 1500     
            //    },   
            //    stroke: {
            //        value: '#d6bd7b', 
            //        easing: 'linear',
            //        duration: 900,   
            //        delay:100
            //    }
            //})
            //.add({
            //    targets: a2,
            //    fill: '#d6bd7b', 
            //    easing: 'linear',
            //    duration:1500,
            //    offset:1000
            //})       
            
            if(this.loadReveal){
                this.reveal()
            }

            this.jEl = $(this.$refs.aSbtn)
            this.jEl.on('mouseenter touchstart', this.over)
                    .on('mouseleave touchend', this.out)
        }
    }
</script>