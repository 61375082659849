<template>
    <div class="pagesblock" :class="css">
        <div class="pagesblock_item" v-for="item in content.pages">
            <a :href="getUrl(item)">
                <img :src="item.pageimage.url + '?fit=crop&w=600&h=400'" />
                <h3>{{ item.title }}</h3>
                <p>{{ item.summary }}</p>
                <span class="btn" :class="{ big : content.columns == 2 }">Read More</span>
            </a>
        </div>
        <div class="pagesblock_item dummy"></div>
        <div class="pagesblock_item dummy"></div>
        <div class="pagesblock_item dummy"></div>        
    </div>
</template>
<script>
    export default {
        props: ['content'],
        data: function () {
            return {
                loading: false,
            }
        },
        computed: {
            css() {
                var a = []

                if (this.content.container) {
                    a.push(this.content.container.classkey)
                }
                else {
                    a.push('mw')
                }

                if (this.content.columns) {
                    a.push('col' + this.content.columns)
                }

                return a
            }
        },
        methods: {
            getUrl(item) {
                var url = ''
                
                if (item.section) {
                    url = '/' + item.section
                }
                if (item.slug) {
                    url = url + '/' + item.slug
                }

                return url.toLowerCase()
            }
        },
        mounted: function () { }
    }
</script>