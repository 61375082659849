var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "property-related" }, [
    _c(
      "div",
      { ref: "propRel", staticClass: "pr-carousel" },
      _vm._l(_vm.houses, function(item) {
        return _c("div", { staticClass: "prc-item" }, [
          _c("div", { staticClass: "column-inner pc-item" }, [
            _c(
              "div",
              { staticClass: "pc-item-image" },
              [
                _c("a", {
                  staticClass: "piia",
                  attrs: { href: _vm.getUrl(item.niceUrl) }
                }),
                _vm._v(" "),
                _c("v-lazy-image", {
                  staticClass: "df",
                  attrs: { src: item.displayImage }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "pc-item-data" }, [
              _c("a", { attrs: { href: _vm.getUrl(item.niceUrl) } }, [
                _c("div", { staticClass: "pid-n" }, [
                  _vm._v(_vm._s(item.displayName) + ", "),
                  _c("span", { staticClass: "pid-l" }, [
                    _vm._v(_vm._s(item.lvBranchName))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pid-m" }, [
                  _c("span", { staticClass: "pid-br" }, [
                    _vm._v(_vm._s(item.locationName) + ", ")
                  ]),
                  _vm._v(" "),
                  item.lvBranchId == 1
                    ? _c("span", { staticClass: "pid-br" }, [
                        _vm._v(
                          _vm._s(item.isOpen ? "Open Market" : "Local Market") +
                            ", "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.bedrooms
                    ? _c("span", { staticClass: "pid-bd" }, [
                        _vm._v(
                          _vm._s(item.bedrooms) +
                            " " +
                            _vm._s(item.bedrooms == 1 ? "bed" : "beds")
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                !_vm.isSold
                  ? _c("div", { staticClass: "pid-p" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(item.displayPrice) +
                          "\n                            "
                      ),
                      item.isSoleAgent && !_vm.isSold
                        ? _c("span", { staticClass: "status sole" }, [
                            _vm._v("Sole Agent")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.hasStatus
                        ? _c(
                            "span",
                            {
                              staticClass: "status",
                              class: item.coallescedStatus.toLowerCase()
                            },
                            [_vm._v(_vm._s(item.coallescedStatus))]
                          )
                        : _vm._e()
                    ])
                  : _c("div", { staticClass: "pid-p nvp" }, [
                      item.hasStatus
                        ? _c(
                            "span",
                            {
                              staticClass: "status",
                              class: item.coallescedStatus.toLowerCase()
                            },
                            [_vm._v(_vm._s(item.coallescedStatus))]
                          )
                        : _vm._e()
                    ])
              ])
            ])
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }