<template>
    <div class="swc-panels" v-bind:class="{success:step == 5}">
        <form class="lvrf windowed" v-on:submit.prevent="submit">
            <transition name="swcp" v-on:leave="afterAnim">
                <div class="swcp" v-if="step == 1">
                    <p>Please wait a moment while we check the status of your account.</p>
                    <div class="await"><i></i></div>
                </div>
            </transition>
            <transition name="swcp" v-on:leave="afterAnim">
                <div class="swcp" v-if="step == 2">
                    <p>Please log in to your Livingroom account to continue. If you do not have an account, <a v-bind:href="'/auth/register?ReturnUrl=/property-redirect/' + request.propertyId">register here</a>.</p>
                    <div class="notify danger" v-if="errors && errors.length > 0">
                        <ul>
                            <li v-for="error in errors">{{ error }}</li>
                        </ul>
                        <i class="fas fa-times-circle" v-on:click="clearErrors"></i>
                    </div>
                    <div class="field">
                        <label>Email Address <span class="inlerr" v-show="verrors.has('tmmscp.emailAddress')">Invalid Email</span></label>
                        <div class="control">
                            <input type="text" name="emailAddress" maxlength="250" v-model="auth.email" data-vv-scope="tmmscp"
                                   v-bind:class="{'error': verrors.has('tmmscp.emailAddress')}" v-validate="'required|email'" />
                        </div>
                    </div>
                    <div class="field">
                        <label>Password <span class="inlerr" v-show="verrors.has('tmmscp.password')">Required</span></label>
                        <div class="control">
                            <input type="password" name="password" maxlength="50" v-model="auth.password" data-vv-scope="tmmscp"
                                   v-bind:class="{'error': verrors.has('tmmscp.password')}" v-validate="'required'" />
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="swcp" v-on:leave="afterAnim">
                <div class="swcp" v-if="step == 4">
                    <p><strong>Hello {{formalName}}</strong><br />Thank you for your interest. To receive further information on this property, please click "Submit Request" below. You will be notified by email when the property details have been added to your account</p>
                    <div class="notify danger" v-if="errors && errors.length > 0">
                        <ul>
                            <li v-for="error in errors">{{ error }}</li>
                        </ul>
                        <i class="fas fa-times-circle" v-on:click="clearErrors"></i>
                    </div>
                    <div class="field">
                        <label>Additional Information <span class="tt-none">(Optional)</span></label>
                        <span class="help">Let us know if you have any other requests or comments.</span>
                        <div class="control">
                            <textarea name="message" rows="3" v-model="request.message" />
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="swcp" v-on:leave="afterAnim">
                <div class="swcp" v-if="step == 5">
                    <div class="step-success">
                        <h4>Thank you!</h4>
                        <p>You will receive a notification by email when this property has been added to your account.</p>
                    </div>
                </div>
            </transition>
        </form>
    </div>
</template>
<script>
    export default {
        props: ['active', 'pid'],
        data() {
            return {
                loading:false,
                errors:[],
                step: 1,
                formalName: '',
                request: {
                    message: '',
                    propertyId: 0,
                    userId: 0
                },
                auth: {
                    email: '',
                    password: ''
                }
            }
        },
        methods: {
            getaccount(){
                let self = this
                this.loading = true

                self.$http.post('/auth/currentuser/', '').then(({ data }) => {
                    if (data.IsAuthenticated) {
                        self.request.userId = data.UserId
                        self.formalName = data.FormalName
                        self.setstep(4)
                    }
                    else {
                        self.setstep(2)
                    }
                    self.loading = false
                }, (response) => {
                    self.loading = false
                })
            },
            submit(){
                let self = this

                if(!self.loading){
                    self.clearErrors()

                    this.$validator.validateAll('tmmscp').then(function (success) {
                        if (success) {
                            if(self.step == 2){ 
                                //login
                                self.$emit('sending', true)
                                self.loading = true
                                self.$http.post('/auth/login', self.auth).then(({ data }) => {
                                    if (data.IsAuthenticated) {
                                        self.request.userId = data.UserId
                                        self.formalName = data.FormalName
                                        self.setstep(4)
                                    }
                                    else {
                                        self.errors = data.Errors
                                    }
                                    self.$emit('sending', false)
                                    self.loading = false
                                }, (response) => {
                                    self.errors = ['Unable to log you in at this time, please try again shortly.']
                                    self.$emit('sending', false)
                                    self.loading = false
                                })
                            }
                            else {
                                //send comments
                                self.$emit('sending', true)
                                self.loading = true
                                self.$http.post('/property/tell/', self.request).then(({ data }) => {
                                    if (data.success) {
                                        self.setstep(5)
                                        setTimeout(self.complete, 5000)
                                    }
                                    else {
                                        self.errors = data.errors
                                    }
                                    self.$emit('sending', false)
                                    self.loading = false
                                }, (response) => {
                                    self.errors = ['A server error occurred sending your request.']
                                    self.$emit('sending', false)
                                    self.loading = false
                                })
                            }
                        }
                    })
                }
            },
            setstep(num){
                this.step = num;
            },
            afterAnim(el, done){
                this.$emit('changed', this.step)
                done()
            },
            complete(){
                this.$emit('complete')
            },
            clearErrors(){
                this.errors = []
            }
        },
        mounted(){
            this.request.propertyId = this.pid
            this.$emit('changed', this.step)
            this.getaccount()
        }
    }
</script>