import Vue from '../config'
import { hcache } from './history-cache'
import { EventBus } from '../app/event-bus'
var page = require('page')
var _ = require('lodash')
var qs = require('qs')

import { gmapApi } from 'gmap-vue'

import { getScrollPosition, setScrollPosition, cleanHistoryList, getFilters, getFilterValue, findFilterValue, serializeRequest } from './helpers'

if (document.getElementById('property-search-vm')) {
    history.scrollRestoration = 'manual'

    const markerImages = {
        red: '/public/img/marker_red.png',
        black: '/public/img/marker_black.png'
    }

    var historyList = [],
        cachedSearchData = null

    var propertyApp = new Vue({
        el: '#property-search-vm',
        data: {
            ready: false,
            busy: false,
            mapBusy: false,
            statePath: '',
            filters: getFilters(iMenuModel.jurisdiction, iPageModel.locations, { types: iPageModel.features, curations: iPageModel.curations }, iMenuModel.saleRange, iMenuModel.rentRange),
            filtersOpen: false,
            lastQuery: null,
            typedKeywords: '',
            properties: [],
            listStates: {
                LOADING: 1,
                LOADED: 2,
                EMPTY: 4
            },
            listState: 1,
            request: {
                keyword: '',
                pubstatus: 1,
                price: { min: 'Min', max: 'Max' },
                rentprice: { min: 'Min', max: 'Max' },
                location: [],
                market: { local: false, open: false },
                rooms: { beds: 0, baths: 0 },
                features: {
                    types: [],
                    curation: 0
                }, 
                rental: iPageModel.isRent,
                includeOffer: true,
                includeSold: true,
                includeLeased: true,
                showMap: false,
                scrollPosition: 0,
                countId: 0,
                minLat: 0,
                maxLat: 0,
                minLong: 0,
                maxLong: 0,
                branchId: 0
            },
            moreSelected: false,
            selectedSort: 1,
            selectedView: 1,
            sorts: [{ id: 1, name: 'High to Low' }, { id: 2, name: 'Low to High' }, { id: 3, name: 'Most Recent' }],
            views: iPageModel.tags,
            displayLength: 0,
            mapOptions: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                minZoom: 10,
                maxZoom: 16,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: true,
                zoomControlOptions: { style: 1, position: 4 },  // I can't use the enums since this instantiates before lib is loaded so I've just used the actual values here
                styles: [
                    {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    }
                ]
            },
            mapMarkers: [],
            mapSelected: null,
            mapState: {
                lat: 0,
                lng: 0,
                zoom: 15,
                selectedIndex: -1,
                last: {
                    coord: null,
                    zoom: 15,
                    branchId: 0,
                    windowWidth: window.innerWidth
                }
            },
            toggleIsBelow: false
        },
        computed: {
            investorSearch() {
                if (this.request.features.types.length) {
                    var buyToLetItem = this.request.features.types.find(f => f == 6)
                    if (buyToLetItem) { return true }
                }

                return false
            },
            showWait() {
                return this.busy && !this.properties.length
            },
            noResults() {
                return this.properties.length == 0 && this.ready
            },
            google() {
                return gmapApi()
            },
            toggleLabel() {
                if (this.request.showMap) {
                    return 'List View'
                }
                else {
                    return 'Map View'
                }
            },
            filterCount() {
                var fc = 0
                
                if (this.request.keyword != '') { fc++ }
                if (this.request.price.min != 'Min' || this.request.price.max != 'Max') { fc++ }
                if (this.request.rentprice.min != 'Min' || this.request.rentprice.max != 'Max') { fc++ }
                if (this.request.location.length > 0) { fc++ }
                if (this.request.market.local || this.request.market.open) { fc++ }
                if (this.request.rooms.beds > 0 || this.request.rooms.baths > 0) { fc++ }
                if (this.request.features.types.length > 0) { fc++ }

                return fc
            },
            searchBranch() {
                if (this.request.branchId == 1) {
                    return 'Guernsey'
                }
                else if (this.request.branchId == 2) {
                    return 'Jersey'
                }
                return 'All Locations'
            }
        },
        methods: {
            changeFilter(ftype, bid) {
                this.request[ftype] = getFilterValue(ftype, this.filters[ftype].value)
                if (ftype == 'location') { this.request.branchId = bid }
                this.updateOnChange()
            },
            updateOnChange: _.debounce(function () {
                if (this.request.showMap) {
                    this.mapFindProperties(true)
                }
                else {
                    var route = serializeRequest(this.request, this.request.branchId, iMenuModel.jurisdiction, this.request.showMap, this.mapState.zoom, this.mapState.lat, this.mapState.lng, this.selectedSort)
                    if (route != this.lastQuery) {
                        page(route)
                    }
                }
            }, 250),            
            //manipulation of filters
            keywordFilter() {
                if (this.typedKeywords !== this.request.keyword) {
                    this.request.keyword = this.typedKeywords
                    this.updateOnChange()
                }
            },
            clearKeywordFilter() {
                this.request.keyword = ''
                this.typedKeywords = ''
                this.updateOnChange()
            },
            changeExclusion() {
                this.updateOnChange()
            },
            toggleFilter(fuid) {
                this.filters.active.last = this.filters.active.current
                this.filters.active.current = fuid
            },
            closeFilters() {
                this.filters.active.last = this.filters.active.current
                this.filters.active.current = null
                this.filtersOpen = false
            },
            closeAndApplyFilters() {
                this.closeFilters()

                if (this.typedKeywords !== this.request.keyword) {
                    this.request.keyword = this.typedKeywords
                    this.updateOnChange()
                }
            },
            clearAllFilters() {
                this.request.keyword = ''
                this.typedKeywords = ''

                if (!this.request.rental) this.$refs.filterPrice.clearAndApply()
                else this.$refs.filterRentPrice.clearAndApply()

                if (this.$refs.filterMarkets) this.$refs.filterMarkets.clearAndApply()

                this.$refs.filterRooms.clearAndApply()
                this.$refs.filterLocation.clearAndApply()
                this.$refs.filterFeatures.clearAndApply()
            },
            //swap image view in grid
            changeView(viewId) {
                if (!this.busy && (viewId != this.selectedView)) {
                    let self = this
                    self.selectedView = viewId
                    this.updateOnChange()
                }
            },
            changeSort(sortId) {
                if (!this.busy && (sortId != this.selectedSort)) {
                    this.selectedSort = sortId
                    this.updateOnChange()
                }
            },
            hideSkeleton() {
                this.$refs.skeleton.classList.add('hidden')
            },
            //query for properties
            findProperties(historyY) {
                this.busy = true
                this.ready = false

                let self = this
                let posY = getScrollPosition().y

                if (posY > self.resetScrollTop) {
                    setScrollPosition(Vue, { x: 0, y: self.resetScrollTop })
                }

                this.listState = this.listStates.LOADING

                self.request.countId++
                if (historyY && historyY > self.resetScrollTop) { self.request.scrollPosition = historyY }
                else { self.request.scrollPosition = 0 }

                var requestModel = _.clone(self.request)
                requestModel.selectedTagId = self.selectedView
                requestModel.selectedSort = self.selectedSort
                self.setLvBranch(requestModel.branchId)
                //'3' represents both from a UI POV so we actually want to find 0
                if (requestModel.branchId == 3 || requestModel.showMap) { requestModel.branchId = 0 }
                self.typedKeywords = self.request.keyword

                self.$http.post('/search', requestModel).then(({ data }) => {
                    if (self.request.countId == data.countId) {
                        if (data.properties && data.properties.length) {
                            cachedSearchData = data

                            self.showResults()
                            self.displayLength = data.properties.length
                        } else {
                            self.busy = false
                            self.mapBusy = false
                            self.ready = true
                            self.displayLength = 0
                            self.listState = self.listStates.EMPTY
                        }
                    }
                }, (response) => {
                    self.busy = false
                    self.mapBusy = false
                    self.ready = true
                    self.displayLength = 0
                })
            },
            showResults() {
                if (this.listState == this.listStates.LOADING && cachedSearchData) {
                    let self = this
                    let historyY = cachedSearchData.scrollPosition
                    this.properties = cachedSearchData.properties

                    if (this.request.showMap) {
                        this.mapRefreshMarkers()
                    }
                    self.listState = self.listStates.LOADED

                    this.$nextTick(() => {
                        cachedSearchData = null
                        setTimeout(function () {
                            if (historyY > 0) {
                                setScrollPosition(Vue, { x: 0, y: historyY })
                            }
                        }, 100)
                        this.ready = true
                        this.busy = false
                        this.mapBusy = false
                    })
                } else {
                    this.mapBusy = false
                }
            },
            //navigation method(s)
            go(r, qry, cpath) {
                let cacheY = null
                this.lastQuery = '?' + qry
                //set up request object
                if (r.k) { this.request.keyword = r.k }
                else { this.request.keyword = '' }

                if (iPageModel.isRent) {
                    if (r.min) { this.request.rentprice.min = r.min }
                    else { this.request.rentprice.min = 'Min' }
                    if (r.max) { this.request.rentprice.max = r.max }
                    else { this.request.rentprice.max = 'Max' }
                } else {
                    if (r.min) { this.request.price.min = r.min }
                    else { this.request.price.min = 'Min' }
                    if (r.max) { this.request.price.max = r.max }
                    else { this.request.price.max = 'Max' }
                }

                if (r.bi) { this.request.branchId = r.bi }

                if (r.l) { this.request.location = _.split(r.l, ',') }
                else { this.request.location = [] }

                if (r.ml && (this.request.branchId == 1)) { this.request.market.local = (r.ml == 'true') }
                else { this.request.market.local = false }

                if (r.mo && (this.request.branchId == 1)) { this.request.market.open = (r.mo == 'true') }
                else { this.request.market.open = false }

                if (r.b) { this.request.rooms.beds = parseInt(r.b) || 0 }
                else { this.request.rooms.beds = 0 }

                if (r.ba) { this.request.rooms.baths = parseInt(r.ba) || 0 }
                else { this.request.rooms.baths = 0 }



                if (r.eo) { this.request.includeOffer = (r.eo == 'true') }
                else if (r.eo === undefined) { this.request.includeOffer = true }
                else { this.request.includeOffer = false }

                if (r.es) { this.request.includeSold = (r.es == 'true') }
                else if (r.es === undefined) { this.request.includeSold = true }
                else { this.request.includeSold = false }

                if (r.el) { this.request.includeLeased = (r.el == 'true') }
                else if (r.el === undefined) { this.request.includeLeased = true }
                else { this.request.includeLeased = false }

                if (r.map) {
                    this.request.showMap = (r.map == 'true')
                } else if (r.map === undefined) { this.request.showMap = false }
                else { this.request.showMap = false }

                if (r.f) { this.request.features.types = _.split(r.f, ',') }
                else { this.request.features.types = [] }

                if (r.cr) { this.request.features.curation = parseInt(r.cr) || 0 }
                else { this.request.features.curation = 0 }

                if (r.o) { this.selectedSort = parseInt(r.o) || 1 }
                else { this.selectedSort = 1 }

                if (r.ts && r.ts.length > 1) {
                    cacheY = hcache.find(r.ts)
                } else {
                    //find any previous scroll position for this path
                    let savedPath = historyList.find(e => {
                        return e.path === cpath
                    })
                    if (savedPath && savedPath.position) {
                        cacheY = savedPath.position.y
                    }
                }
                // hope this makes sense
                if (this.request.showMap) {
                    if (isNaN(parseFloat(r.mapl) && isNaN(parseFloat(r.maplg)))) {
                        // handle gibberish in query strings
                        this.mapBusy = false
                    } else {
                        this.$refs.map.$mapPromise.then((map) => {
                            this.busy = true
                            // setting the center changes the bounds which triggers the map search event
                            //we need a flag to tell it to ignore the event so just re - using this one
                            setTimeout(() => {
                                map.setCenter({ lat: parseFloat(r.mapl), lng: parseFloat(r.maplg) })
                                map.setZoom(parseFloat(r.mapz))
                                this.mapCalculateBounds()
                                this.busy = false

                                // map centered and zoomed and bounds calculated...now can do normal stuff
                                this.$nextTick(() => {
                                    this.goApply()
                                    if (cacheY) { this.findProperties(cacheY) }
                                    else { this.findProperties() }
                                    this.hideSkeleton()
                                })
                            }, 250)  // HACK bounds need time to be initialised, the promise isn't always sufficient...
                        })
                    }
                } else {
                    this.$nextTick(() => {
                        this.goApply()
                        if (cacheY) { this.findProperties(cacheY) }
                        else { this.findProperties() }
                        this.hideSkeleton()
                    })
                }
            },
            goApply() {
                //ensure search UI matches
                this.typedKeywords = this.request.keyword

                if (iPageModel.isRent) {
                    this.filters.rentprice.value = [
                        findFilterValue(this.request.rentprice.min, iMenuModel.rentRange),
                        findFilterValue(this.request.rentprice.max, iMenuModel.rentRange)
                    ]
                    this.$refs.filterRentPrice.ensureValues()
                } else {
                    this.filters.price.value = [
                        findFilterValue(this.request.price.min, iMenuModel.saleRange),
                        findFilterValue(this.request.price.max, iMenuModel.saleRange)
                    ]
                    this.$refs.filterPrice.ensureValues()
                }

                if (this.request.branchId == 1) {
                    this.filters.market.value.open = this.request.market.open
                    this.filters.market.value.local = this.request.market.local
                    this.$refs.filterMarkets.ensureValues()
                }

                this.filters.rooms.value = [Number(this.request.rooms.beds), Number(this.request.rooms.baths)]
                this.$refs.filterRooms.ensureValues()

                this.filters.location.value = this.request.location.map(x => parseInt(x))
                this.$refs.filterLocation.ensureValues()
                this.$refs.filterLocation.ensureBranch(this.request.branchId)

                this.filters.features.value = this.request.features
                this.$refs.filterFeatures.ensureValues()
            },
            mapRefreshMarkers() {
                this.mapMarkers = []
                this.properties.forEach((p) => {
                    if (p.latitude && p.longitude && !p.isPrivate) {
                        var pt = new google.maps.LatLng(parseFloat(p.latitude), parseFloat(p.longitude))
                        this.mapMarkers.push({
                            property: p,
                            position: pt,
                            icon: markerImages.red
                        })
                    }
                })
            },
            mapPaddedBounds(north, south, east, west) {  // calculate padding bounds of the map like AirBnB otherwise markers go right to the edge
                var bounds = this.$refs.map.$mapObject.getBounds()
                var ne = bounds.getNorthEast()
                var sw = bounds.getSouthWest()
                var topRight = this.$refs.map.$mapObject.getProjection().fromLatLngToPoint(ne)
                var bottomLeft = this.$refs.map.$mapObject.getProjection().fromLatLngToPoint(sw)
                var scale = Math.pow(2, this.$refs.map.$mapObject.getZoom())

                var swToPoint = this.$refs.map.$mapObject.getProjection().fromLatLngToPoint(sw)
                var swPoint = new this.google.maps.Point(((swToPoint.x - bottomLeft.x) * scale) + west, ((swToPoint.y - topRight.y) * scale) - south)
                var swWorld = new this.google.maps.Point(swPoint.x / scale + bottomLeft.x, swPoint.y / scale + topRight.y)
                var pt1 = this.$refs.map.$mapObject.getProjection().fromPointToLatLng(swWorld)

                var neToPoint = this.$refs.map.$mapObject.getProjection().fromLatLngToPoint(ne)
                var nePoint = new this.google.maps.Point(((neToPoint.x - bottomLeft.x) * scale) - east, ((neToPoint.y - topRight.y) * scale) + north)
                var neWorld = new this.google.maps.Point(nePoint.x / scale + bottomLeft.x, nePoint.y / scale + topRight.y)
                var pt2 = this.$refs.map.$mapObject.getProjection().fromPointToLatLng(neWorld)

                return new this.google.maps.LatLngBounds(pt1, pt2)
            },
            mapCalculateBounds() {
                //var bounds = this.mapPaddedBounds(50, 50, 50, 75)  // n, s, e, w
                var bounds = this.mapPaddedBounds(40, 20, 14, 14)  // n, s, e, w
                var ne = bounds.getNorthEast()
                var sw = bounds.getSouthWest()
                this.request.minLat = sw.lat()
                this.request.maxLat = ne.lat()
                this.request.minLong = sw.lng()
                this.request.maxLong = ne.lng()
            },
            mapFindProperties(isParameterChange) {
                if (!this.busy && this.request.showMap) {  // stop endless loops when calling page() and centering 
                    var center = this.$refs.map.$mapObject.getCenter(),
                        zoom = this.$refs.map.$mapObject.getZoom(),
                        windowWidth = window.innerWidth,
                        blockSearch = false

                    if (this.mapState.last.coord != null) {
                        var distanceMoved = google.maps.geometry.spherical.computeDistanceBetween(center, this.mapState.last.coord),
                            minDistance = (zoom > 14) ? ((20 - zoom) * 15) : ((20 - zoom) * 20)

                        blockSearch = (distanceMoved < minDistance);
                    }

                    if (isParameterChange || this.mapState.last.zoom != zoom || this.mapState.last.windowWidth != windowWidth) {
                        blockSearch = false
                    }

                    if (!blockSearch) {
                        this.mapSaveState(center, zoom, windowWidth, this.request.branchId)

                        var route = serializeRequest(this.request, this.request.branchId, iMenuModel.jurisdiction, this.request.showMap, this.mapState.zoom, this.mapState.lat, this.mapState.lng, this.selectedSort)
                        page(route)
                    }
                }
            },
            mapSaveState(c, z, w, bid) {
                var center = (!c) ? this.$refs.map.$mapObject.getCenter() : c,
                    zoom = (!z) ? this.$refs.map.$mapObject.getZoom() : z,
                    windowWidth = (!w) ? window.innerWidth : w

                this.mapBusy = true
                this.mapCalculateBounds()
                this.mapDeselectMarker()  // clear selected property on drag/zoom

                this.mapState.lat = center.lat()
                this.mapState.lng = center.lng()
                this.mapState.zoom = zoom
                this.mapState.last.coord = center
                this.mapState.last.zoom = zoom
                this.mapState.last.branchId = bid
                this.mapState.last.windowWidth = windowWidth //todo: if the only change is a window resize, need to bypass putting that into URL state
            },
            mapSelectMarker(m, index) {
                this.mapSelected = m.property
                if (this.mapState.selectedIndex > -1) { //deselect any current markers
                    this.mapMarkers[this.mapState.selectedIndex].icon = markerImages.red
                }
                this.mapState.selectedIndex = index
                this.mapMarkers[index].icon = markerImages.black
            },
            mapDeselectMarker() {
                this.mapSelected = null
                if (this.mapState.selectedIndex > -1) {
                    this.mapMarkers[this.mapState.selectedIndex].icon = markerImages.red
                }
                this.mapState.selectedIndex = -1
            },
            onToggleMap(toggleEvent) {
                this.toggleMap(toggleEvent.value)
            },
            toggleMap(showMap) {
                let self = this

                if ((showMap && !self.request.showMap) && (this.mapState.last.branchId != this.request.branchId)) {                    
                    var mapdefault = iPageModel.mapDefaults.both
                    if (this.request.branchId == 1) { mapdefault = iPageModel.mapDefaults.guernsey }
                    else if (this.request.branchId == 2) { mapdefault = iPageModel.mapDefaults.jersey }

                    this.$refs.map.$mapObject.setCenter({ lat: mapdefault.latitude, lng: mapdefault.longitude })
                    this.$refs.map.$mapObject.setZoom(mapdefault.zoom)
                    this.mapCalculateBounds()
                }

                self.request.showMap = showMap

                if (!showMap) {
                    self.mapDeselectMarker()
                    self.mapState.last.windowWidth = 0 //forces an update of the map if it's reopened later

                    this.$nextTick(() => {
                        self.updateOnChange()
                    })
                }
                
            },
            setHeightUnits() {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            },
            handleResize: _.debounce(function () {
                this.setHeightUnits()
            }, 250),
            observePrefoot() {
                let prefoot = document.getElementById('pre__foot'),
                    self = this

                let observer = new IntersectionObserver(entries => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            if (entry.intersectionRatio >= 0.5) {
                                self.toggleIsBelow = true
                            }
                            else {
                                self.toggleIsBelow = false
                            }
                        }
                    })
                }, { threshold: [0, 0.5] })

                observer.observe(prefoot)
            }
        },
        created() {
            let self = this
            self.resetScrollTop = 80
            self.setHeightUnits()
            window.addEventListener('resize', this.handleResize);
        },
        mounted() {
            this.request.branchId = this.lvBranchId
            this.observePrefoot()
        }
    })

    //** localStorage on navigation away or refresh    
    window.addEventListener('unload', function (e) {
        let position = getScrollPosition()
        let r = qs.parse(location.search.slice(1))

        if (r.ts && r.ts.length > 1) {
            hcache.add(r.ts, position.y)
        }
    }, false)

    //** client-side paging
    function exitPage(context, next) {
        let _historyList = historyList
        let position = getScrollPosition()
        let r = qs.parse(context.querystring)

        let currentPathIndex = _historyList.findIndex(e => {
            return e.path === context.path
        })

        if (_historyList.length >= 50) {
            cleanHistoryList(_historyList)
        }

        if (r.ts && r.ts.length > 1) {
            hcache.add(r.ts, position.y)
        }

        if (currentPathIndex !== -1) {
            _historyList[currentPathIndex].position = position
        } else {
            _historyList.push({
                path: context.path,
                position: position
            })
        }
        next()
    }

    function navigateRoot(context) {
        var r = qs.parse(context.querystring)
        EventBus.$emit('searchchange', r, propertyApp.request.rental, propertyApp.request.branchId)
        propertyApp.go(r, context.querystring, context.path)
    }

    if (iPageModel.isRent) {
        page.base('/rent')
    }
    else {
        page.base('/buy')
    }

    page('/', navigateRoot)
    page.exit('/', exitPage)
    page()

    //** media query events
    function tabletBreakPoint(mq) {
        if (mq.matches) {
            //tablet portrait
            propertyApp.resetScrollTop = 80
        }
        else {
            //mobile
            propertyApp.resetScrollTop = 0
        }
    }

    let mqTablet = window.matchMedia("(min-width: 992px)")
    mqTablet.addListener(tabletBreakPoint)
    tabletBreakPoint(mqTablet)
}