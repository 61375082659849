<template>
    <div class="tsblock">
        <h2 class="text-center">Testimonials</h2>
        <div class="tsblock_carousel" ref="tSlider">
            <div class="tsblock_carousel_item" v-for="item in content.testimonials">
                <p>{{item.quote}}</p>
                <strong class="text-upper">{{item.author}}</strong>
            </div>
        </div>
    </div>
</template>
<script>
    var Flickity = require('flickity'),
        tflick = null

    export default {
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data: function () {
            return {
                loading: false
            }
        },
        computed: {},
        methods: {},
        mounted() {
            tflick = new Flickity(this.$refs.tSlider, {
                cellSelector: '.tsblock_carousel_item',
                cellAlign: 'center',
                initialIndex: 1,
                pageDots: true,
                prevNextButtons: false
            })
        }
    }
</script>