<template>
    <div ref="propMedia" class="property-images" v-bind:class="computedClass" v-cloak>        
        <transition name="pis">
            <div class="property-images-switch" v-if="ready && !isAlbum" v-on:click="changeView()" v-cloak><img src="/public/img/grid.png" title="All Images" /></div>
        </transition>
        <transition name="pis">
            <div class="property-images-switch" v-if="ready && isAlbum" v-on:click="changeView()" v-cloak><img src="/public/img/slides.png" title="Image Slides" /></div>
        </transition>
        <section ref="imgAlbum" class="album" v-cloak>
            <div class="album-item" v-for="(img, index) in throttledImages" v-if="!img.broken">
                <img-progress v-bind:src="img.src" v-bind:id="img.id" v-bind:index="index"
                              v-on:done="imageDone" v-on:error="imageError" v-on:iclick="imageClick"></img-progress>
            </div>
        </section>
        <transition name="psdl" v-on:leave="loadAnimationLeave">
            <div class="psdo-loader" v-show="loading">
                <div class="faux"><img src="/public/img/spacer.gif" /></div>
            </div>
        </transition>
        <transition name="carousel">
            <flkty-carousel ref="flickity" v-show="state == states.GALLERY" :soldvisible="soldvisible" :leased="leased" :open="open" :jersey="jersey" v-on:click="imageClick"></flkty-carousel>
        </transition>
        <!--<svg-cover ref="propLoader" v-if="loading" v-on:complete="loadAnimationComplete"></svg-cover>-->
        <div class="property-images-fpl">
            <img-progress v-if="plan" v-bind:src="plan.path + '?mode=max&width=1800&height=1800'" v-bind:id="plan.id" v-bind:index="999" v-on:done="floorplanDone"></img-progress>
        </div>
    </div>
</template>
<script>
    import PhotoSwipe from 'photoswipe'
    import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

    export default {
        props: ['idata', 'floorplan', 'soldvisible', 'leased', 'open', 'jersey'],
        data() {
            return {
                loading: true,
                ready: false,
                states: {
                    GALLERY: 0,
                    THUMBS: 1
                },
                state: 0,
                count: {
                    initial:    0,
                    current:    0,
                    total:      0
                },
                planAdded: false,
                images: [], 
                carousel: [], 
                plan:null
            }
        },
        computed: {  
            throttledImages(){
                if(this.count.current < this.count.initial || !this.ready) {
                    return this.images.slice(0, this.count.initial)
                }
                else {
                    return this.images
                }
            },
            isAlbum(){
                return this.state == this.states.THUMBS
            },
            computedClass(){
                return {
                    ready: this.ready, 
                    isa: this.isAlbum
                }
            }
        },
        methods: {
            imageDone(id, index, loadedSrc){
                this.images[index].loaded = true        
                this.$refs.flickity.add(this.images[index], index)
                
                this.imgState.slides.push({
                    src: loadedSrc,
                    msrc: loadedSrc,
                    w: Math.floor(this.images[index].width * (this.imgState.baseHeight / this.images[index].height)),
                    h: this.imgState.baseHeight,
                    sortKey: index
                })

                this.imageTrack()
            },            
            imageError(id, index){
                this.images[index].broken = true
                this.imageTrack()
            },
            imageTrack(){
                this.count.current++
                if(this.count.current == this.count.initial){
                    var self = this
                    //initial load done - ready to show remainder
                    this.$nextTick(() => {
                        self.$refs.flickity.resize()
                        self.ready = true 

                        setTimeout(function(){ 
                            self.loadAnimationComplete()
                        }, 300)
                    })
                }
                
                if((this.count.current >= this.count.total -1) && !this.plan){
                    this.plan = this.floorplan
                }
            },
            changeView(){        
                if(this.state == this.states.GALLERY){ 
                    this.state = this.states.THUMBS 
                    this.$refs.flickity.hiding()
                    this.adjustIntoView(true)
                }
                else if(this.state == this.states.THUMBS) { 
                    this.adjustIntoView(false)
                    this.state = this.states.GALLERY

                    this.$nextTick(() => {
                        this.$refs.flickity.resize()
                    })
                }
            },
            adjustIntoView(changeViewHeight){
                var self = this, 
                    rect = this.$refs.propMedia.getBoundingClientRect()                

                if(changeViewHeight) {
                    this.$refs.propMedia.style.height = rect.height + 'px'

                    this.$nextTick(() => {
                        var toRect = this.$refs.imgAlbum.getBoundingClientRect()
                        this.$refs.propMedia.style.height = toRect.height + 'px'

                        setTimeout(function(){ self.$refs.propMedia.style.height = '' }, 1500)
                    })
                }
                else {
                    this.$refs.propMedia.style.height = ''
                }

                if(rect.top < 0) {
                    this.$scrollTo(this.$refs.imgAlbum, 500, {
                        offset: -20
                    })
                }
            },
            floorplanDone(id, index, loadedSrc){
                var ratio = (this.plan.width > this.plan.height) ?  (1800 / this.plan.width) : (1800 / this.plan.height)
                this.plan.loaded = true        
                this.plan.src = loadedSrc
                this.imgState.slides.push({
                    src: loadedSrc,
                    msrc: loadedSrc,
                    w: Math.round(this.plan.width * ratio),
                    h: Math.round(this.plan.height * ratio)
                })
            },
            showPlan(imageRect){
                if(this.plan.loaded) {
                    this.imageClick(this.plan.src, imageRect)
                }
            },
            imageClick(imageSource, imageRect){
                let srcIndex = this.imgState.slides.findIndex(e => {
                    return e.src === imageSource
                })
                
                if(srcIndex !== -1){
                    var options = {
                        index: srcIndex,

                        zoomEl: false,
                        fullscreenEl: false,
                        shareEl: false,
                        history:false,

                        showHideOpacity:true,
                        showAnimationDuration: 1000                
                    }

                    if(imageRect){                        
                        options.getThumbBoundsFn = function(index){
                            return imageRect
                        } 
                    }

                    this.imgState.slides.sort((a, b) => {
                        return a.sortKey - b.sortKey
                    })
                    // this.imgState.slides.forEach(a => console.log(a.sortKey))

                    var gallery = new PhotoSwipe(this.pswpElement, PhotoSwipeUI_Default, this.imgState.slides, options)
                    gallery.init()
                }
            },
            loadAnimationLeave(el, done){
                this.$refs.flickity.show()
                //done()
            },
            loadAnimationComplete(){
                this.loading = false
            }
        },
        created() {
            //SPA: reset state on-unload
            this.imgState = {
                slides: [],
                baseHeight: 1200
            }
        },
        mounted() {
            if(this.idata && this.idata.length)
            {
                this.count.total = this.idata.length
                this.count.initial = (this.idata.length > 2) ? 3 : this.idata.length
                this.images = this.idata
            }

            //SPA: destroy PhotoSwipe on-unload
            this.pswpElement = document.getElementById('pswp-modal')
        }
    }
</script>