import Vue from '../config'

if (document.getElementById('generic-content-vm')) {
    new Vue({
        el: '#generic-content-vm',
        data: {
        },
        methods: {
        },
        mounted: function () {
        },
    })
}