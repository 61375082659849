<template>
    <div :class="css">
        <div class="image-fw" v-bind:class="{ animate: content.animate }">
            <div class="ifwi video" v-bind:style="'background-image:url(' + content.image.url + '?mode=fit&w=1300)'" v-scroll-monitor>
                <a v-if="!playing" v-on:click.prevent="play" href="#play" class="play">Play</a>
                <!--<a v-bind:href="content.videourl" target="_blank" class="visible-xs play">Play</a>-->
            </div>
            <iframe :class="{ off: !playing, on: playing }" v-if="embedtype == 1" :src="embedurl" width="560" height="315" frameborder="0" allow="autoplay" allowfullscreen></iframe>
            <iframe :class="{ off: !playing, on: playing }" v-if="embedtype == 2" :src="embedurl" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
    </div>    
</template>
<script>
    export default {
        props: {
            content: {
                type: Object,
                required: true
            },
            isNews: {
                type: Boolean,
                required: false
            }
        },  
        data() {
            return {
                playing: false,
                embedtype: 0,
                embedurl: '',
                loadedurl: ''
            }
        },
        computed: {
            css() {
                if (this.content.container) {
                    return [this.content.container.classkey]
                }
                else {
                    if (this.isNews) {
                        return ['ms']
                    }
                    return ['mn']
                }
            }
        },
        methods: {
            load(){
                var self = this

                self.$http.post('/utility/embedurl/', { value: self.content.videourl }).then(({ data }) => {
                    if (data && data.value) {
                        self.embedtype = data.id
                        self.loadedurl = data.value
                    }
                }, (response) => {})
            },
            play() {
                var self = this
                self.playing = true

                this.$nextTick(() => {
                    self.embedurl = self.loadedurl
                })
            }
        },
        mounted() {
            this.load()
        }
    }
</script>