import Vue from '../config'
import nformrInsta from '../vue/nformr-insta.vue'


if (document.getElementById('bio-page-vm')) {
    new Vue({
        el: '#bio-page-vm',
        components: {
            nformrInsta
        },
        data: {
            bioId: pageJson.bioId,
            bios: []
        },
        computed: {
            bio() {
                if (this.bios.length) {
                    var ib = this.bios.find(b => b.id == this.bioId)                    
                    return ib
                }
                return null
            }
        },
        methods: {
            loadTeam() {
                let self = this
                self.$http.post('/bio-list/', null).then(({ data }) => {
                    if (data) {
                        self.bios = data
                    }
                }, (response) => { })
            },
        },
        mounted: function () {
            this.loadTeam()
        }
    })
}