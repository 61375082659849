var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "swc-panels", class: { success: _vm.step == 5 } },
    [
      _c(
        "form",
        {
          staticClass: "lvrf windowed",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "transition",
            { attrs: { name: "swcp" }, on: { leave: _vm.afterAnim } },
            [
              _vm.step == 1
                ? _c("div", { staticClass: "swcp" }, [
                    _c("p", [
                      _vm._v(
                        "Please wait a moment while we check your account."
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "await" }, [_c("i")])
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "swcp" }, on: { leave: _vm.afterAnim } },
            [
              _vm.step == 2
                ? _c("div", { staticClass: "swcp" }, [
                    _c("p", [
                      _vm._v(
                        "Please log in to your Livingroom account to continue. If you do not have an account, "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/auth/register?ReturnUrl=/property-redirect/" +
                              _vm.request.propertyId
                          }
                        },
                        [_vm._v("register here")]
                      ),
                      _vm._v(".")
                    ]),
                    _vm._v(" "),
                    _vm.errors && _vm.errors.length > 0
                      ? _c("div", { staticClass: "notify danger" }, [
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function(error) {
                              return _c("li", [_vm._v(_vm._s(error))])
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fas fa-times-circle",
                            on: { click: _vm.clearErrors }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", [
                        _vm._v("Email Address "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.verrors.has("bkscp.emailAddress"),
                                expression: "verrors.has('bkscp.emailAddress')"
                              }
                            ],
                            staticClass: "inlerr"
                          },
                          [_vm._v("Invalid Email")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.auth.email,
                              expression: "auth.email"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'"
                            }
                          ],
                          class: {
                            error: _vm.verrors.has("bkscp.emailAddress")
                          },
                          attrs: {
                            type: "text",
                            name: "emailAddress",
                            maxlength: "250",
                            "data-vv-scope": "tmmscp"
                          },
                          domProps: { value: _vm.auth.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.auth, "email", $event.target.value)
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", [
                        _vm._v("Password "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.verrors.has("bkscp.password"),
                                expression: "verrors.has('bkscp.password')"
                              }
                            ],
                            staticClass: "inlerr"
                          },
                          [_vm._v("Required")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.auth.password,
                              expression: "auth.password"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          class: { error: _vm.verrors.has("bkscp.password") },
                          attrs: {
                            type: "password",
                            name: "password",
                            maxlength: "50",
                            "data-vv-scope": "tmmscp"
                          },
                          domProps: { value: _vm.auth.password },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.auth,
                                "password",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "swcp" }, on: { leave: _vm.afterAnim } },
            [
              _vm.step == 3
                ? _c("div", { staticClass: "swcp" }, [
                    _c("div", { staticClass: "field" }, [
                      _c("label", [
                        _vm._v("Your Name "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.verrors.has("bkscp.personName"),
                                expression: "verrors.has('bkscp.personName')"
                              }
                            ],
                            staticClass: "inlerr"
                          },
                          [_vm._v("Required")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.request.name,
                              expression: "request.name"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          class: { error: _vm.verrors.has("bkscp.personName") },
                          attrs: {
                            type: "text",
                            name: "personName",
                            maxlength: "150",
                            "data-vv-scope": "bkscp"
                          },
                          domProps: { value: _vm.request.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.request, "name", $event.target.value)
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", [
                        _vm._v("Email Address "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.verrors.has("bkscp.emailAddress"),
                                expression: "verrors.has('bkscp.emailAddress')"
                              }
                            ],
                            staticClass: "inlerr"
                          },
                          [_vm._v("Invalid Email")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.request.email,
                              expression: "request.email"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'"
                            }
                          ],
                          class: {
                            error: _vm.verrors.has("bkscp.emailAddress")
                          },
                          attrs: {
                            type: "text",
                            name: "emailAddress",
                            maxlength: "250",
                            "data-vv-scope": "bkscp"
                          },
                          domProps: { value: _vm.request.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.request,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", [_vm._v("Telephone")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.request.telephone,
                              expression: "request.telephone"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "telephone",
                            maxlength: "50"
                          },
                          domProps: { value: _vm.request.telephone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.request,
                                "telephone",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "swcp" }, on: { leave: _vm.afterAnim } },
            [
              _vm.step == 4
                ? _c("div", { staticClass: "swcp" }, [
                    _vm.errors && _vm.errors.length > 0
                      ? _c("div", { staticClass: "notify danger" }, [
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function(error) {
                              return _c("li", [_vm._v(_vm._s(error))])
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fas fa-times-circle",
                            on: { click: _vm.clearErrors }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", [
                        _vm._v("Date & Time "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.verrors.has("bkscp.message"),
                                expression: "verrors.has('bkscp.message')"
                              }
                            ],
                            staticClass: "inlerr"
                          },
                          [_vm._v("Required")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "help" }, [
                        _vm._v(
                          "Please suggest at least one convenient date & time."
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.request.message,
                              expression: "request.message"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          class: { error: _vm.verrors.has("bkscp.message") },
                          attrs: {
                            name: "message",
                            rows: "5",
                            "data-vv-scope": "bkscp"
                          },
                          domProps: { value: _vm.request.message },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.request,
                                "message",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "swcp" }, on: { leave: _vm.afterAnim } },
            [
              _vm.step == 5
                ? _c("div", { staticClass: "swcp" }, [
                    _c("div", { staticClass: "step-success" }, [
                      _c("h4", [_vm._v("Thank you for your interest.")]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("A member of the "),
                        _c(
                          "a",
                          { attrs: { href: "/about/team", target: "_blank" } },
                          [_vm._v("Livingroom Team")]
                        ),
                        _vm._v(
                          " will contact you shortly to arrange a viewing."
                        )
                      ])
                    ])
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }